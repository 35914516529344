<ng-container *ngIf="displayInformation$ | async">
  <div class="banner-information">
    <a [routerLink]="['/installation/device-summary']">
      <app-icon class="is-size-4 has-spacing-right-xs" name="error-circle-white"></app-icon>
      <p>{{ 'iaq-app.__IAQ_BANNER_SENSORS_TO_CONFIGURE' | AsyncLang | async }}</p>
    </a>
    <p (click)="dismiss()" class="is-align-end is-cursor-pointer">{{ 'common-app.__DISMISS' | AsyncLang | async }}</p>
  </div>

</ng-container>
