import { createReducer, on } from '@ngrx/store';
import { CreateBuildingDeviceSuccess, CreateBuildingSuccess, DeleteBuildingSuccess, DeleteUserSuccess, GetBuildingDevicesFailure, GetBuildingsSuccess, ResetThresholdsSuccess, SelectBuilding, UpdateBuildingSuccess, GetBuildingSuccess} from './buildings.actions';
import { Building, BuildingsState } from './buildings.interface';

export const initialBuildingState: BuildingsState = {
  buildings: null,
  currentBuildingId: null
};

export const buildingsReducers = createReducer(
  initialBuildingState,

  on(GetBuildingsSuccess, (state, {buildings, user}) => {
    let buildingsMap: Map<number, Building>
    if (state.buildings && state.buildings.length > 0) {

      buildingsMap = new Map(state.buildings.map(building => {
        return [building.id, building];
      }));
    }
    else {
      buildingsMap = new Map([]);
    }
    buildings.forEach(building =>  {
      if (buildingsMap.has(building.id)) {
        const buildingUpdated: Building = {
          ...buildingsMap.get(building.id),
          ...building,
          devices: building.devices ? building.devices.map(dev => dev.id) : [],
          groupIds: building.groups ? building.groups.map(group => group.id) : []
        };

        buildingsMap.set(building.id, buildingUpdated)

      }
      else {
        const buildingUpdated: Building = {
          ...building,
          devices: building.devices ? building.devices.map(dev => dev.id) : [],
          groupIds: building.groups ? building.groups.map(group => group.id) : []
        };
        buildingsMap.set(building.id, buildingUpdated)
      }
    })
    return {
      ...state,
      buildings: [...buildingsMap.values()]
    }
  }),


  on(CreateBuildingSuccess, (state, {id, name}) => {
    const buildings = [...state.buildings]
    buildings.push({id, name})

    return {
      ...state,
      buildings,
      currentBuildingId: id,
    };
  }),

  on(SelectBuilding, (state, {id}) => {
    return {
      ...state,
      currentBuildingId: id
    };
  }),

  on(GetBuildingSuccess,(state, { buildings }) => {
    // If buildings list is empty, building not found
    if (buildings.length === 0) {
      return state
    }

    // Take first building, we receive only one building on GetBuildingSuccess
    const buildingsUpdated = state.buildings.map(building => {
      if(building.id === buildings[0].id) {
        building = {
          ...building,
          users: buildings[0].users,
        };

      }
      return building
    })

    return {
      ...state,
      buildings: buildingsUpdated
    }
  }),

  on(GetBuildingDevicesFailure, (state, {error, buildingId}) => {
    const buildings = state.buildings.map(building => {
      if (building.id === buildingId && error) {
        building = {...building}
        building.error = {status: error.status, message: error.message, name: error.name }
      }
      return building;
    });
    return {
      ...state,
      buildings,
      currentBuildingId: buildingId
    }
  }),

  on(UpdateBuildingSuccess, (state, {building}) => {
    const buildings = state.buildings.map(b => {
      if(building.id === b.id) {
        return {
          ...b,
          ...building
        };
      } else {
        return b;
      }
    });

    return {
      ...state,
      buildings,
    };
  }),

  on(CreateBuildingDeviceSuccess, (state, action) => {
    return {
      ...state,
      buildings: state.buildings.map(building => {
        if (building.id === action.buildingId) {
          building = {
            ...building,
            devices: [...building.devices, action.device.id],
          };
        }

        return building;
      }),
    }
  }),

  on(DeleteUserSuccess, (state, action) => {
    return {
      ...state,
      buildings: state.buildings.map(building => {
        if (building.id === action.buildingId) {
          building = {
            ...building,
            users: building.users.filter(u => u.id !== action.userId),
          };
        }

        return building;
      })
    };
  }),

  on(DeleteBuildingSuccess, (state, action) => {
    return {
      ...state,
      buildings: state.buildings.filter(building => building.id !== action.buildingId),
    };
  }),

  on(ResetThresholdsSuccess, (state, action) => {
    return {
      ...state,
      buildings: state.buildings.map(b => {
        if (b.id === action.buildingId) {
          b = {
            ...b,
            thresholds: action.thresholds,
          };
        }

        return b;
      }),
    };
  })
);

