import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { catchError, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  CreateGroup, CreateGroupFailure, CreateGroupSuccess, DeleteGroup,
  DeleteGroupFailure, DeleteGroupSuccess, UpdateGroup, UpdateGroupFailure, UpdateGroupSuccess
} from './groups.actions';
import { GroupsService } from './groups.service';

@Injectable()
export class GroupsEffects {

  constructor(
    private actions$: Actions,
    private groupService: GroupsService,
    private buildingFacde: BuildingsFacade,
  ) {}

  createGroup = createEffect(() => this.actions$.pipe(
    ofType(CreateGroup),
    withLatestFrom(this.buildingFacde.currentBuilding$),
    switchMap(([action, building]) => {
      return this.groupService.createGroup({name: action.name, buildingId: building.id}).pipe(
        switchMap((res) => [
          CreateGroupSuccess(
            {id: res.id, name: res.name, buildingId: building.id}),
        ]),
        catchError(({error}) => of(CreateGroupFailure({error, ...action})))
      );
    })
  ));

  updateGroup = createEffect(() => this.actions$.pipe(
    ofType(UpdateGroup),
    mergeMap((action) => {
      return this.groupService.updateGroup(action.group).pipe(
        switchMap((group) => [
          UpdateGroupSuccess({group}),
        ]),
        catchError(({error}) => of(UpdateGroupFailure({error, ...action})))
      );
    })
  ));

  deleteGroup = createEffect(() => this.actions$.pipe(
    ofType(DeleteGroup),
    mergeMap((action) => {
      return this.groupService.deleteGroup(action.id).pipe(
        switchMap((res) => [
          DeleteGroupSuccess(res),
        ]),
        catchError(({error}) => of(DeleteGroupFailure({error, ...action})))
      );
    })
  ));

}
