import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EnumLangActions, LoadLangsSuccess } from '@library/store/lang/lang.actions';
import { LangFacade } from '@library/store/lang/lang.facade';
import { Actions, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { DefaultLanguage, getCrowdinCode } from './supportedLangs';

@Injectable({providedIn: 'root'})
export class BuildingLangsResolver implements Resolve<any> {

  constructor(
    private action$: Actions,
    private langFacade: LangFacade,
    private cookieService: CookieService,
  ) { }

  resolve() {
    this.loadLangsFromCache();
    this.fetchLangs();
    this.cacheLangs();
    return true;
  }

  loadLangsFromCache() {
    const cachedLangs = localStorage.getItem('langs');
    if (cachedLangs !== null) {
      try {
        const langs = JSON.parse(cachedLangs);
        if (typeof langs === 'object' && langs !== null) {
          this.langFacade.setLangs(langs);
        } else {
          console.log('err', langs);
          throw new Error('Langs from localstorage is not an object');
        }
      } catch (error) {
        console.warn('Cannot parse langs from localstorage');
        console.warn(error);
      }
    }
  }

  fetchLangs() {

    const code = this.cookieService.get('netatmocomlocale');
    const locale = getCrowdinCode(code) || DefaultLanguage;

    this.langFacade.loadLangs([
      { name: 'common-app', locale },
      { name: 'common-settings', locale },
      { name: 'station-app', locale },
      { name: 'hc-app', locale },
      { name: 'iaq-app', locale }


    ]);  }

  cacheLangs() {
    this.action$.pipe(
      ofType<LoadLangsSuccess>(EnumLangActions.LoadLangsSuccess),
      take(1)
    ).subscribe(action => {
      localStorage.setItem('langs', JSON.stringify(action.payload));
    });
  }
}
