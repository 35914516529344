import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Router } from '@angular/router'
import { MainPageModule } from '@pages/main-page/main-page.module';
import { QrCodeViewModule } from '@pages/qr-code-view/qr-code-view.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { UserFacade } from '@library/store/user/user.facade'
import { UserProFacade } from '@store/user-pro/user-pro.facade'
import { ANALYTICS_USER_SELECTOR, Analytics } from '@library/utils/services/analytics.service'
import { getUserAnalytics } from '@store/user-pro/user-pro.selector';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    MainPageModule,
    QrCodeViewModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: UserFacade, useClass: UserProFacade },
    {
      provide: ANALYTICS_USER_SELECTOR,
      useValue: getUserAnalytics
    },
    Analytics
  ]

})
export class AppModule { }
