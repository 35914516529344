import { Component, OnInit } from '@angular/core';
import { LangPipe } from '@library/utils/pipes/lang.pipe'
import { ErrorTypes } from '../qr-code-module/qr-code-module-view.component'

@Component({
  selector: 'app-qr-code-not-found',
  templateUrl: './qr-code-not-found.component.html',
  styleUrls: ['./qr-code-not-found.component.scss']
})
export class QrCodeNotFoundComponent implements OnInit {
  errorText: ErrorTypes =  ErrorTypes.NOT_FOUND;
  constructor(private langPipe: LangPipe) { }

  ngOnInit(): void {
  }

}
