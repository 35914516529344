import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BuildingsEffects } from './buildings.effects'
import { BuildingsFacade } from './buildings.facade'
import { buildingsReducers } from './buildings.reducer'
import { BuildingsService } from './buildings.service'

@NgModule({
  imports: [
    StoreModule.forFeature('buildings', buildingsReducers),
    EffectsModule.forFeature([
      BuildingsEffects,
    ]),
  ],
  providers: [
    BuildingsFacade,
    BuildingsService
  ]
})
export class BuildingsModule {}
