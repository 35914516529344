import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { LocalStorageService } from '@library/utils/services/local-storage.service';

@Injectable({providedIn: 'root'})
export class ValidateStateGuard implements CanActivate {

  constructor(private localStorage: LocalStorageService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const storageState = this.localStorage.getItem('oauth2_state');
    const state = route.queryParams['state'];
    return storageState === state;
  }
}
