import { Pipe, PipeTransform } from '@angular/core';
import { LangPipe } from '@library/utils/pipes/lang.pipe'
import { DashboardService } from '@pages/dashboard-view/dashboard.service'

@Pipe({
  name: 'filterKey',
  pure: true,
})
export class FilterKeyPipe implements PipeTransform {

  constructor(
    private dashboardService: DashboardService,
    private langPipe: LangPipe,
  ) {

  }
  transform<T>(array: T[] = [], filter: string, ...keys: (keyof T)[]) {
    if (!array) {
      return [];
    }
    return array.filter(item => {
      return keys.reduce((acc, curr) => {
        if (curr === 'health_idx') {
         const idxString = this.langPipe.transform(this.dashboardService.getQualityKey(item[curr] as any as number))
         acc = acc ||idxString.toLowerCase().includes(filter.toLowerCase())
        } else if (typeof item[curr] !== 'undefined' && item[curr] !== null) {
          acc = acc || (item[curr] as any as string).toLowerCase().includes(filter.toLowerCase())
        }

        return acc;
      }, false as boolean);
    });
  }

}
