import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { QrCodeMeasuresForDisplay } from '@store/qr-codes/qr-codes.interface';
import { QRCodesService } from '@store/qr-codes/qr-codes.service';
import { formatHours } from '@store/qr-codes/qr-codes.utils';
import { QrCodeTimeCardComponent } from './qr-code-time-card/qr-code-time-card.component';

@Component({
  selector: 'app-qr-code-scrollbar',
  templateUrl: './qr-code-scrollbar.component.html',
  styleUrls: ['./qr-code-scrollbar.component.scss']
})
export class QrCodeScrollbarComponent implements OnInit {
  @ViewChild('scrollable') scrollableRef: ElementRef;
  @ViewChildren(QrCodeTimeCardComponent) timeCards: QueryList<QrCodeTimeCardComponent>
  @Input() qrCodeMeasuresForDisplay: QrCodeMeasuresForDisplay

  constructor(
    public qrCodesService: QRCodesService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.timeCards.changes.subscribe(() => {
      this.scrollToLastCard();
    })
  }

  formatCardHours(date: number, timeZone: string): string[] {
    return formatHours(date, timeZone);
  }

  scrollToLastCard() {
    const scrollWidth = this.scrollableRef.nativeElement.scrollWidth
    this.scrollableRef.nativeElement.scrollLeft += scrollWidth
  }

}
