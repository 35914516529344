import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service';
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { GroupsFacade } from '@store/groups/groups.facade';
import { Group } from '@store/groups/groups.interface';
import { combineLatest, distinctUntilKeyChanged, filter, map, Subscription, withLatestFrom } from 'rxjs';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard-view-sidebar',
  templateUrl: './dashboard-view-sidebar.component.html',
  styleUrls: ['./dashboard-view-sidebar.component.scss']
})
export class DashboardViewSidebarComponent implements OnInit {

  private groups$ = this.groupsFacade.buildingGroups$.pipe(
    map(groups => {
      // Move the empty groups at the end of the list
      const emptyGroups = groups.filter(g => g.devices.length === 0);
      return [...groups.filter(g => g.devices.length !== 0), ...emptyGroups];
    }),
  );
  private selectedGroups$ = this.dashboardService.selectedGroups$;

  viewModel$ = combineLatest({
    groups: this.groups$,
    selectedGroups: this.selectedGroups$,
    currentBuilding: this.buildingsFacade.currentBuilding$
  });

  private currentBuildingChange$ = this.buildingsFacade.currentBuilding$.pipe(
    filter(b => !!b),
    distinctUntilKeyChanged('id'),
  );

  subs = new Subscription();

  constructor(
    private groupsFacade: GroupsFacade,
    private dashboardService: DashboardService,
    private buildingsFacade: BuildingsFacade,
    private localStorage: LocalStorageService,
  ) { }

  async ngOnInit() {
    // Whenever the currentBuildingChanges update the selected groups
    this.subs.add(this.currentBuildingChange$.pipe(
      withLatestFrom(this.groups$)
    ).subscribe(([, groups]) => {
      this.selectStartingSelectedGroups(groups);
    }));

    // Whenever the selected groups changes, update the value in the localstorage
    this.subs.add(this.dashboardService.selectedGroups$.subscribe(groups => {
      this.localStorage.setItem('selectedGroups', groups.map(g => g.id).toString());
    }));
  }

  toggleGroup(group: Group, selectedGroups: Group[]) {
    if (selectedGroups.includes(group)) {
      this.dashboardService.selectedGroups.next(selectedGroups.filter(g => g !== group));
    } else {
      this.dashboardService.selectedGroups.next([...selectedGroups, group]);
    }
  }

  selectAllGroups(groups: Group[]) {
    this.dashboardService.selectedGroups.next([...groups]);
  }

  private selectStartingSelectedGroups(groups: Group[]) {
    if (this.localStorage.getItem('selectedGroups')) {
      // if we have selected groups in local storage select them
      let selectedGroupIds = this.localStorage.getItem('selectedGroups').split(',');

      const selectedGroups = groups.filter(group => selectedGroupIds.map(id => parseInt(id, 10)).includes(group.id))

      this.dashboardService.selectedGroups.next(selectedGroups);
    }  else {
      this.dashboardService.selectedGroups.next([]);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
