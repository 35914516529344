import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '@pages/dashboard-view/dashboard.service';
import { GroupsFacade } from '@store/groups/groups.facade';
import { Group } from '@store/groups/groups.interface';
import { ModulePro } from '@store/modules-pro/modules-pro.interface';
import { defer, take } from 'rxjs';

@Component({
  selector: 'app-dashboard-view-group-card',
  templateUrl: './dashboard-view-group-card.component.html',
  styleUrls: ['./dashboard-view-group-card.component.scss']
})
export class DashboardViewGroupCardComponent implements OnInit {

  @Input() group: Group;

  groupDevices: ModulePro[];

  devices$ = defer(() => this.groupsFacade.groupDevices(this.group));

  constructor(
    private groupsFacade: GroupsFacade,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    this.groupsFacade.groupDevices(this.group)
    .pipe(take(1))
    .subscribe(modules => this.groupDevices = modules);
  }

  getMaxC02(devices: ModulePro[]) {
    const CO2s = devices
      .map(dev => dev?.CO2)
      .filter(Number)
    const min = CO2s.length > 0 ? Math.max(...CO2s) : undefined;
    return  min ? min + ' ppm' : '-- ppm';
  }

  getMinQuality(devices: ModulePro[]) {
    const indexes = devices.map(dev => dev?.health_idx).filter(Boolean)
    const minIndex = Math.max(...indexes);

    return this.dashboardService.getQuality(minIndex)
  }

}
