import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnumMeasureTypes } from "@library/store/measures/measures.interface";
import { Thresholds } from "@store/buildings/buildings.interface";
import { Observable, ReplaySubject } from "rxjs";
import { HttpBuildingService } from "src/app/services/http-building.service";
import { ThresholdsService } from "src/app/services/thresholds.service";
import { QrCodeHistoricalDataFromAPI, AllQRCodesFromAPI, QrCodePDFRequest, QRCodeFromAPI, QrCodeQuery, QrCodeLiveDataFromAPI } from "./qr-codes.interface";
import { convertThresholdsToHealthIndex } from "./qr-codes.utils";


@Injectable()
export class QRCodesService {
  constructor(
    private http: HttpBuildingService,
    private thresholdsService: ThresholdsService,
  ) { }
  queryParamsEmpty = new HttpParams();

  private selectedCard = new ReplaySubject<{ idx: number, hourAndUnit: string[], date: number }>(0);
  currentSelectedCard = this.selectedCard.asObservable();

  getAllQRCodes(buildingId: number): Observable<AllQRCodesFromAPI> {
    const queryParams = { buildingId };
    return this.http.get('qr-codes/', queryParams);
  }

  /**
   * Fetches live data from the device using getmeasure
   * @param  { string } qrCodeUid
   */
  getQRCodeLiveData(buildingId: number, qrCodeUid: string): Observable<QrCodeLiveDataFromAPI> {
    return this.http.get('qr-codes/live/' + buildingId + '/' + qrCodeUid);
  }

  /**
   * Fetches historical data from the device using getmeasure
   * @param  { string } qrCodeUid
   * @param  { QrCodeQuery } params
   */
  getQRCodeHistoricalData(buildingId: number, qrCodeUid: string, params: QrCodeQuery): Observable<QrCodeHistoricalDataFromAPI> {
    return this.http.get('qr-codes/historical/' + buildingId + '/' + qrCodeUid, params);
  }

  /**
   * The API creates a new QrCode or deletes and recreates a new one if existing
   * @param {string} device_id
   * @param {boolean} [isEnabled]
   */
  createOrRegenerateQrCode(device_id: string, isEnabled?: boolean): Observable<{ data: QRCodeFromAPI }> {
    return this.http.post('qr-codes/', { data: { device: { device_id }, isEnabled: !!isEnabled } })
  }

  toggleQrCodeEnabledStatus(qrCodeId: number, isEnabled: boolean): Observable<{ data: QRCodeFromAPI }> {
    return this.http.put('qr-codes/' + qrCodeId, { data: { isEnabled } });
  };

  deleteQrCode(qrCodeId: number): Observable<{ data: QRCodeFromAPI }> {
    return this.http.delete('qr-codes/' + qrCodeId);
  };

  downloadPDF(params: QrCodePDFRequest) {
    return this.http.post('qr-codes/pdf',
      { data: params },
      '',
      { 'responseType': 'blob' as 'pdf' }
    );
  };

  selectTime(idx: number, hourAndUnit: string[], date: number) {
    this.selectedCard.next({ idx, hourAndUnit, date })
  }

  getQualityFromThreshold(measureType: EnumMeasureTypes, measure: number, thresholds?: Thresholds) {
    return {
      quality: (thresholds && typeof measure === 'number' && !isNaN(measure)) ? this.thresholdsService.getThresholdsMetadata(measureType).labels[convertThresholdsToHealthIndex(measureType, measure, thresholds)] : '—',
      color: (thresholds && typeof measure === 'number' && !isNaN(measure)) ? this.thresholdsService.getThresholdsMetadata(measureType).colors[convertThresholdsToHealthIndex(measureType, measure, thresholds)] : ''
    };
  }
}
