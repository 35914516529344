import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { RadioButtonItemComponent } from './radio-button-item/radio-button-item.component';
import { RadioButtonIconComponent } from './radio-button-icon/radio-button-icon.component';
import { RadioButtonLabelComponent } from './radio-button-label/radio-button-label.component';
import { IconModule } from '@library/ui/atoms/icon/icon.module';
import { TextModule } from '@library/ui/atoms/text/text.module';
@NgModule({
  declarations: [
    RadioButtonGroupComponent,
    RadioButtonItemComponent,
    RadioButtonIconComponent,
    RadioButtonLabelComponent
  ],
  exports: [
    RadioButtonGroupComponent,
    RadioButtonItemComponent,
    RadioButtonIconComponent,
    RadioButtonLabelComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    TextModule,
  ]
})
export class RadioButtonsModule { }
