<ng-container *ngIf="(data$ | async) as data">

  <app-context-menu #contextMenu>

    <a role="anchor" *ngIf="data.buildings.length > 0 && data.currentBuilding" >
      <app-sidebar-link icon="home-selector">
        <app-hover-text *ngIf="!(contextMenu.showMenu$ | async)">
          {{ 'iaq-app.__IAQ_BUILDING_SELECTOR' | AsyncLang | async }}
        </app-hover-text>
      </app-sidebar-link>
    </a>

    <app-context-menu-item *ngFor="let building of data.buildings"
      (click)="setCurrentBuilding(building.id)"
      [attr.data-building-id]="building.id" >

      <app-text
        class="marginless"
        [text]="building.name"
        [ngClass]="{'selected-building': building.id === data.currentBuilding.id}">
      </app-text>

    </app-context-menu-item>

    <app-context-menu-item>

      <app-add-building></app-add-building>

    </app-context-menu-item>

  </app-context-menu>

</ng-container>
