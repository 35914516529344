import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { currentBuilding } from '@store/buildings/buildings.selectors';
import { ModulePro } from './modules-pro.interface';

export const getModules = (state: AppState) => state.modulesPro.modulesPro;
const getGroups = (state: AppState) => state.groups.groups;

export const allCurrentBuildingModules = createSelector(
  getModules,
  currentBuilding,
  (modules, building) => {
    if (!building) {
      return null;
    } else {
      return modules.filter(mod => building.devices.includes(mod.id));
    }
  }
);

export const getModuleGroup = (id: ModulePro['id']) => createSelector(
  getModules,
  getGroups,
  (modules, groups) => {
    const module = modules.find(module => module.id === id);
    return groups.find(group => group.id === module.groupId);
  }
);

export const getModuleFromId = (id: ModulePro['device_id']) => createSelector(
  getModules,
  (modules) => {
    return modules.find(mod => mod.device_id === id);
  }
);
