import { createAction, props } from '@ngrx/store';
import { ModulePro, ModuleProDB } from '@store/modules-pro/modules-pro.interface'
import { UserPro, UserProError } from '@store/user-pro/user-pro.interface'
import { Building, RawBuilding, Thresholds } from './buildings.interface';

export const GetBuildings = createAction(
  '[Building] Get buildings',
);

export const GetBuildingsSuccess = createAction(
  '[Building] Get buildings sucess ',
  props<{buildings: Array<RawBuilding>, user: UserPro}>(),
);

export const GetBuildingsFailure = createAction(
  '[Building] Get buildings failure',
  props<{error: {code: number, status: number}}>(),
);

export const GetBuilding = createAction(
  '[Building] Get building',
  props<{id: number}>()
);

export const GetBuildingSuccess = createAction(
  '[Building] Get building sucess ',
  props<{buildings: Array<RawBuilding>, id: number}>(),
);

export const GetBuildingFailure = createAction(
  '[Building] Get building failure',
  props<{error: {code: number, status: number}, id: number}>(),
);

export const CreateBuilding = createAction(
  '[Building] Create building',
  props<{code: string, name: string, tz: string}>(),
);

export const CreateBuildingSuccess = createAction(
  '[Building] Create building success',
  props<{id: number, name: string}>()
);

export const CreateBuildingFailure = createAction(
  '[Building] Create building failure',
  props<{error, code: string, name: string, tz: string}>(),
);

export const SelectBuilding = createAction(
  '[Building] Select building',
  props<{id: number}>(),
);

export const GetBuildingDevices = createAction(
  '[Building] get building devices',
  props<{buildingId: number}>(),
);

export const GetBuildingDevicesSuccess = createAction(
  '[Building] get building devices success',
  props<{buildingId: number, devices: Array<ModulePro>}>(),
);

export const GetBuildingDevicesFailure = createAction(
  '[Building] get building devices failure',
  props<{error, buildingId: number}>(),
);

export const Authorize = createAction(
  '[Building] authorize one building',
);

export const AuthorizeSuccess = createAction(
  '[Building] authorize one building success',
  props<{url: string, state: string}>(),
);

export const AuthorizeFailure = createAction(
  '[Building] authorize one building failure',
  props<{error}>(),
);

export const CreateBuildingDevice = createAction(
  '[Building] Create Building Devices',
  props<{buildingId: number, device: ModuleProDB}>()
);

export const CreateBuildingDeviceSuccess = createAction(
  '[Building] Create Building Device success',
  props<{buildingId: number, device: ModulePro}>()
);

export const CreateBuildingDeviceFailure = createAction(
  '[Building] Create Building Devices failure',
  props<{error, buildingId: number, device: ModuleProDB}>(),
);

export const RefreshAuthorize = createAction(
  '[Building] Refresh Authorize',
  props<{buildingId: number}>(),
);

export const RefreshAuthorizeSuccess = createAction(
  '[Building] Refresh Authorize success',
  props<{url: string, state: string}>(),
);

export const RefreshAuthorizeFailure = createAction(
  '[Building] Refresh Authorize failure',
  props<{error}>(),
);

export const UpdateBuildingToken = createAction(
  '[Building] Update building tokens',
  props<{buildingId: number, code: string}>(),
);

export const UpdateBuildingTokenSuccess = createAction(
  '[Building] Update building tokens success',
);

export const UpdateBuildingTokenFailure = createAction(
  '[Building] Update building tokens failure',
  props<{error, buildingId: number, code: string}>(),
);

export const UpdateBuilding = createAction(
  '[Building] Update building',
  props<{building: Partial<Building>}>(),
);

export const UpdateBuildingSuccess = createAction(
  '[Building] Update building success',
  props<{building: Partial<Building>}>(),
);

export const UpdateBuildingFailure = createAction(
  '[Building] Update building failure',
  props<{error, building: Partial<Building>}>(),
);

export const DeleteUser = createAction(
  '[Building] DeleteUser',
  props<{buildingId: number, userId: number}>(),
);

export const DeleteUserSuccess = createAction(
  '[Building] DeleteUser success',
  props<{buildingId: number, userId: number}>(),
);

export const DeleteUserFailure = createAction(
  '[Building] DeleteUser failure',
  props<{error, buildingId: number, userId: number}>(),
);

export const DeleteBuilding = createAction(
  '[Building] DeleteBuilding',
  props<{buildingId: number}>(),
);

export const DeleteBuildingSuccess = createAction(
  '[Building] DeleteBuilding success',
  props<{buildingId: number}>(),
);

export const DeleteBuildingFailure = createAction(
  '[Building] DeleteBuilding failure',
  props<{error, buildingId: number}>(),
);

export const ResetThresholds = createAction(
  '[Building] ResetThresholds',
  props<{buildingId: number}>(),
);

export const ResetThresholdsSuccess = createAction(
  '[Building] ResetThresholds success',
  props<{buildingId: number, thresholds: Thresholds}>(),
);

export const ResetThresholdsFailure = createAction(
  '[Building] ResetThresholds failure',
  props<{error, buildingId: number}>(),
);

export const GetUnplacedDevicesData = createAction(
  '[USER] Get unplace devices data',
  props<{buildingId: number}>()
)

export const GetUnplacedDevicesDataSuccess = createAction(
  '[USER] Get unplace devices data success',
  props<{devices: ModulePro[]}>()
);

export const GetUnplacedDevicesDataFailure = createAction(
  '[USER] Get unplace devices data failure',
  props<{error: UserProError}>()
);

