import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { qrCodesReducers } from './qr-codes.reducer';
import { QRCodeEffects } from './qr-codes.effect';
import { QRCodesService } from './qr-codes.service';
import { QRCodesFacade } from './qr-codes.facade';


@NgModule({
  imports: [
    StoreModule.forFeature('qrCodes', qrCodesReducers),
    EffectsModule.forFeature([
      QRCodeEffects,
    ]),
  ],
  providers: [
    QRCodesService,
    QRCodesFacade
  ]
})
export class QRCodeModule {}
