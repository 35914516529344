import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingModalHeaderComponent } from './building-modal-header/building-modal-header.component';
import { BuildingModalHeaderTextComponent } from './building-modal-header/building-modal-header-text/building-modal-header-text.component';
import { BuildingModalBodyTextComponent } from './building-modal-body/building-modal-body-text/building-modal-body-text.component';
import { BuildingModalBodyComponent } from './building-modal-body/building-modal-body.component';
import { BuildingModalHeaderCloseComponent } from './building-modal-header/building-modal-header-close/building-modal-header-close.component';
import { RouterModule } from '@angular/router';
import { BuildingModalHeaderBackComponent } from './building-modal-header/building-modal-header-back/building-modal-header-back.component';
import { BuildingModalBodyButtonGroupComponent } from './building-modal-body/building-modal-body-button-group/building-modal-body-button-group.component';
import { BuildingModalBodyAlertButtonComponent } from './building-modal-body/building-modal-body-alert-button/building-modal-body-alert-button.component';
import { BuildingModalBodyButtonComponent } from './building-modal-body/building-modal-body-button/building-modal-body-button.component';
import { LayButtonModule } from '@library/ui/layout/button/button.module';
import { BuildingModalBodyPrimaryButtonComponent } from './building-modal-body/building-modal-body-primary-button/building-modal-body-primary-button.component';
import { UIModule } from '@library/ui/ui.module';



@NgModule({
  declarations: [
    BuildingModalHeaderComponent,
    BuildingModalHeaderTextComponent,
    BuildingModalBodyTextComponent,
    BuildingModalBodyComponent,
    BuildingModalHeaderCloseComponent,
    BuildingModalHeaderBackComponent,
    BuildingModalBodyButtonGroupComponent,
    BuildingModalBodyAlertButtonComponent,
    BuildingModalBodyButtonComponent,
    BuildingModalBodyPrimaryButtonComponent
  ],
  exports: [
    BuildingModalHeaderComponent,
    BuildingModalHeaderTextComponent,
    BuildingModalBodyTextComponent,
    BuildingModalBodyComponent,
    BuildingModalHeaderCloseComponent,
    BuildingModalHeaderBackComponent,
    BuildingModalBodyButtonGroupComponent,
    BuildingModalBodyAlertButtonComponent,
    BuildingModalBodyButtonComponent,
    BuildingModalBodyPrimaryButtonComponent
  ],
  imports: [
    CommonModule,
    UIModule,
    RouterModule,
    LayButtonModule
  ]
})
export class BuildingModalModule { }
