import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModulesProEffects } from './modules-pro.effects'
import { ModulesProFacade } from './modules-pro.facade'
import { buildingsReducers } from './modules-pro.reducer'
import { ModulesProService } from './modules-pro.service'

@NgModule({
  imports: [
    StoreModule.forFeature('modulesPro', buildingsReducers),
    EffectsModule.forFeature([
      ModulesProEffects,
    ]),
  ],
  providers: [
    ModulesProFacade,
    ModulesProService
  ]
})
export class ModulesProModule {}
