import { Pipe, PipeTransform } from '@angular/core';

export interface Order<T> {
  key: keyof T,
  dir: 'asc' | 'desc',
}

const sortAsc = <T>(ordering: Order<T>) =>
  (a: T, b: T) => {
    const valueA = a[ordering.key]?.valueOf();
    const valueB = b[ordering.key]?.valueOf();
    return valueA <= valueB
      ? valueA === valueB ? 0 : -1
      : 1;
  };

const sortDesc = <T>(ordering: Order<T>) =>
  (a: T, b: T) => {
    const valueA = a[ordering.key]?.valueOf();
    const valueB = b[ordering.key]?.valueOf();
    return valueA >= valueB
      ? valueA === valueB ? 0 : -1
      : 1;
  };

@Pipe({
  name: 'orderByKey',
  pure: true
})
export class OrderByKeyPipe implements PipeTransform {

  transform<T>(array: T[] = [], ordering: Order<T>): T[] {
    const nonDefined = array.filter(v => typeof v[ordering.key] === 'undefined' || v[ordering.key] === null);
    const others = array.filter(v => typeof v[ordering.key] !== 'undefined' && v[ordering.key] !== null);

    switch (ordering.dir) {
      case 'asc':
        return [...others.sort(sortAsc(ordering)), ...nonDefined];
      case 'desc':
        return [...others.sort(sortDesc(ordering)), ...nonDefined];
      default:
        return [...others, ...nonDefined];
    }
  }

}
