import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GroupsFacade } from '@store/groups/groups.facade';
import { ModulePro } from '@store/modules-pro/modules-pro.interface';
import { combineLatest, map, of, share, switchMap } from 'rxjs';
import { Order } from 'src/app/pipes/order-by-key/order-by-key.pipe';
import { DashboardService } from '../dashboard.service';
@Component({
  selector: 'app-dashboard-view-content',
  templateUrl: './dashboard-view-content.component.html',
  styleUrls: ['./dashboard-view-content.component.scss']
})
export class DashboardViewContentComponent implements OnInit {

  searchControl = new UntypedFormControl('');

  ordering: Order<ModulePro> = {
    key: 'name',
    dir: 'asc',
  };

  devices$ = combineLatest([
    this.dashboardService.selectedGroups$,
    this.groupsFacade.buildingGroups$,
  ]).pipe(
    switchMap(([selectedGroups, groups]) => {
      /**
       * When the selected groups or the building groups change
       * we have multiple options to decide what to show
       *  - When there is no group in the building we return an empty array, no devices
       *  - When there is no selected groups we show all the devices in all the groups
       *  - Otherwise we return only the devices in the selected groups
       */
      if (groups.length === 0) {
        return of([]);
      }

      if (selectedGroups.length === 0) {
        selectedGroups = groups;
      }

      return combineLatest(selectedGroups.map(g => this.groupsFacade.groupDevices(g)));
    }),
    map(groupsArr => groupsArr.flat()),
    share()
  );

  constructor(
    private dashboardService: DashboardService,
    private groupsFacade: GroupsFacade,
  ) { }

  ngOnInit(): void {

  }

}
