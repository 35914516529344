export interface UserProState {
  id: number,
  email: string,
  user_id: string,
  app_telemetry: boolean
}

export const initialUserProState: UserProState = {
  id: null,
  email: null,
  user_id: null,
  app_telemetry: false
};
