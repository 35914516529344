import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service'
import { GetBuildingDevicesSuccess } from '@store/buildings/buildings.actions'
import { BuildingsFacade } from '@store/buildings/buildings.facade'
import { BehaviorSubject, filter, firstValueFrom, map, shareReplay } from 'rxjs'

@Component({
  selector: 'app-information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent implements OnInit {
  // Event emiter to display banner
  @Output() isDisplayed = new EventEmitter<boolean>();

  // Load unplaced modules
  private hasUnplacedModule = new BehaviorSubject<boolean>(false);
  public hasUnplacedModule$ = this.hasUnplacedModule.asObservable();

  // Display information banner
  private displayInformation = new BehaviorSubject<boolean>(false);
  public displayInformation$ = this.displayInformation.asObservable();

  // Retrieve current building id
  private buildingId$ = this.buildingsFacade.currentBuilding$.pipe(
    filter(b => !!b),
    map(b => b.id),
    shareReplay(),
  );

  constructor(
    private buildingsFacade: BuildingsFacade,
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.loadUnplacedDevices();
  }

  // Load unplaced modules
  async loadUnplacedDevices() {
    const buildingId = await firstValueFrom(this.buildingId$);
    const action = await firstValueFrom(this.buildingsFacade.getBuildingDevices(buildingId));

    if (action.type === GetBuildingDevicesSuccess.type) {
      this.hasUnplacedModule.next(action.devices.length > 0);
      this.UpdateDisplayBanner();
    }
  }

  UpdateDisplayBanner() {
    const shouldDisplayBanner = this.localStorage.getItem('dismiss_warning') !== 'true' && this.hasUnplacedModule.value;

    this.displayInformation.next(shouldDisplayBanner);

    // Emit to parent if banner is displayed
    this.isDisplayed.emit(shouldDisplayBanner);
  }

  dismiss() {
    this.localStorage.setItem('dismiss_warning', 'true');
    this.UpdateDisplayBanner();
  }
}
