import { Component, OnInit } from '@angular/core'
import { LangFacade } from '@library/store/lang/lang.facade'
import { getCrowdinCode } from '@store/langs/supportedLangs'

@Component({
  selector: 'app-qr-code-view',
  templateUrl: './qr-code-view.component.html',
  styleUrls: ['./qr-code-view.component.scss']
})
export class QRCodeViewComponent implements OnInit {
  locale: string = getCrowdinCode(navigator.language) || 'en';

  constructor(
    private langFacade: LangFacade
    ) {

  }
  ngOnInit() {
    this.fetchLangs()
  }

  fetchLangs() {
    this.langFacade.loadLangs([
      { name: 'common-app', locale: this.locale },
      { name: 'iaq-app', locale: this.locale },
      { name: 'hc-app', locale: this.locale },
      { name: 'station-app', locale: this.locale }
    ]);
  }
}
