import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  Authorize, AuthorizeFailure, AuthorizeSuccess, CreateBuilding,
  CreateBuildingDevice, CreateBuildingDeviceFailure, CreateBuildingDeviceSuccess,
  CreateBuildingFailure, CreateBuildingSuccess, DeleteBuilding, DeleteBuildingFailure, DeleteBuildingSuccess, DeleteUser, DeleteUserFailure, DeleteUserSuccess, GetBuilding, GetBuildingDevices,
  GetBuildingDevicesFailure, GetBuildingDevicesSuccess, GetBuildingFailure,
  GetBuildings, GetBuildingsFailure, GetBuildingsSuccess, GetBuildingSuccess,
  GetUnplacedDevicesData,
  GetUnplacedDevicesDataFailure,
  GetUnplacedDevicesDataSuccess,
  RefreshAuthorize, RefreshAuthorizeFailure, RefreshAuthorizeSuccess,
  ResetThresholds,
  ResetThresholdsFailure,
  ResetThresholdsSuccess,
  SelectBuilding,
  UpdateBuilding, UpdateBuildingFailure, UpdateBuildingSuccess,
  UpdateBuildingToken, UpdateBuildingTokenFailure, UpdateBuildingTokenSuccess
} from './buildings.actions';
import { BuildingsFacade } from './buildings.facade';
import { BuildingsService } from './buildings.service';
import { SetHomeUser } from '@library/store/user/user.action'

@Injectable()
export class BuildingsEffects {

  constructor(
    private actions$: Actions,
    private buildingsService: BuildingsService,
    private buildingsFacade: BuildingsFacade,
    private router: Router,
  ) {}

  loadBuildings = createEffect(() => this.actions$.pipe(
    ofType(GetBuildings),
    switchMap((action) => {
      return this.buildingsService.loadBuildings().pipe(
        switchMap(({buildings, user}) => [
          GetBuildingsSuccess({buildings, user})
        ]),
        catchError(({error}) => of(GetBuildingsFailure({error, ...action})))
      );
    }),
  ));

  selectBuilding = createEffect(() => this.actions$.pipe(
    ofType(SelectBuilding),
    switchMap(action => ([GetBuilding({id: action.id})])),
  ));

  loadBuilding = createEffect(() => this.actions$.pipe(
    ofType(GetBuilding),
    switchMap(action => {
      return this.buildingsService.loadBuilding(action.id).pipe(
        switchMap(({buildings}) => {
          return [GetBuildingSuccess({buildings, id: action.id})]}),
        catchError(({error}) => {
          if (error?.status === 401) {
            this.router.navigateByUrl('/refresh')
          }
          return of(GetBuildingFailure({error, ...action}))})
      );
    }),
  ));

  refreshOnError = createEffect(() => this.actions$.pipe(
    ofType(GetBuildingsFailure),
    tap((action) => {
      if (action.error?.status === 401) {
        this.router.navigateByUrl('/refresh')
      }
    }),
  ), {dispatch: false});


  createBuildings = createEffect(() => this.actions$.pipe(
    ofType(CreateBuilding),
    switchMap((action) => {
      return this.buildingsService.createBuilding({code: action.code, name: action.name, tz: action.tz}).pipe(
        switchMap((res) => [
          CreateBuildingSuccess({id: res.id, name: res.name}),
        ]),
        catchError(({error}) => of(CreateBuildingFailure({error, ...action})))
      );
    })
  ));

  authorize = createEffect(() => this.actions$.pipe(
    ofType(Authorize),
    switchMap((action) => {
      return this.buildingsService.authorize().pipe(
        switchMap((data) => [
          AuthorizeSuccess(data),
        ]),
        catchError(({error}) => of(AuthorizeFailure({error, ...action})))
      );
    })
  ));

  createBuildingDevice = createEffect(() => this.actions$.pipe(
    ofType(CreateBuildingDevice),
    mergeMap((action) => {
      return this.buildingsService.createBuildingDevice({buildingId: action.buildingId, device: action.device}).pipe(
        switchMap(({data}) => [
          CreateBuildingDeviceSuccess({device: data, buildingId: action.buildingId}),
        ]),
        catchError(({error}) => of(CreateBuildingDeviceFailure({error, ...action})))
      );
    })
  ));

  getBuildingDevices = createEffect(() => this.actions$.pipe(
    ofType(GetBuildingDevices),
    switchMap((action) => {
      return this.buildingsService.getBuildingDevices(action.buildingId).pipe(
        switchMap((res) => [
          GetBuildingDevicesSuccess({...res, ...action}),
        ]),
        catchError(({error}) => of(GetBuildingDevicesFailure({error, buildingId: action.buildingId})))
      );
    })
  ));

  refreshAuthorize = createEffect(() => this.actions$.pipe(
    ofType(RefreshAuthorize),
    switchMap((action) => {
      return this.buildingsService.refreshAuthorize(action.buildingId).pipe(
        switchMap((res) => [
          RefreshAuthorizeSuccess(res),
        ]),
        catchError(({error}) => of(RefreshAuthorizeFailure({error, ...action})))
      );
    })
  ));

  updateBuildingToken = createEffect(() => this.actions$.pipe(
    ofType(UpdateBuildingToken),
    switchMap((action) => {
      return this.buildingsService.updateBuildingToken({code: action.code, buildingId: action.buildingId}).pipe(
        switchMap(() => [
          UpdateBuildingTokenSuccess(),
        ]),
        catchError(({error}) => of(UpdateBuildingTokenFailure({error, ...action})))
      );
    })
  ));

  updateBuilding = createEffect(() => this.actions$.pipe(
    ofType(UpdateBuilding),
    mergeMap((action) => {
      return this.buildingsService.updateBuilding({...action.building}).pipe(
        switchMap(() => [
          UpdateBuildingSuccess({building: {...action.building}}),
        ]),
        catchError(({error}) => of(UpdateBuildingFailure({error, ...action})))
      );
    })
  ));

  deleteUser = createEffect(() => this.actions$.pipe(
    ofType(DeleteUser),
    mergeMap(action => {
      return of(action).pipe(
        withLatestFrom(this.buildingsFacade.buildings$.pipe(
          map(buildings => buildings.find(b => b.id === action.buildingId))
        )),
        switchMap(([, building]) => {
          return this.buildingsService.deleteUser(building, {id: action.userId});
        }),
        switchMap(() => [
          DeleteUserSuccess({buildingId: action.buildingId, userId: action.userId}),
        ]),
        catchError(({error}) => of(DeleteUserFailure({error, ...action})))
      )
    }),
  ));

  deleteBuilding = createEffect(() => this.actions$.pipe(
    ofType(DeleteBuilding),
    mergeMap(action => {
      return this.buildingsService.deleteBuilding(action.buildingId).pipe(
        switchMap(() => [
          DeleteBuildingSuccess({...action}),
        ]),
        catchError(({error}) => of(DeleteBuildingFailure({error, ...action})))
      );
    }),
  ));

  resetThresholds = createEffect(() => this.actions$.pipe(
    ofType(ResetThresholds),
    mergeMap(action => {
      return this.buildingsService.resetThresholds(action.buildingId).pipe(
        switchMap((result) => [
          ResetThresholdsSuccess({...action, thresholds: result.thresholds}),
        ]),
        catchError(({error}) => of(ResetThresholdsFailure({error, ...action})))
      );
    }),
  ));

  unplacedDevicesData$ = createEffect(() => this.actions$.pipe(
    ofType(GetUnplacedDevicesData),
    switchMap((action) => {
      return this.buildingsService.getUnplacedDevicesData(action.buildingId).pipe(
        map((actions) => GetUnplacedDevicesDataSuccess({devices: actions.devices})),
        catchError(({ error }) => of(GetUnplacedDevicesDataFailure(error)))
      );
    })
  ))
}
