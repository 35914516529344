import { props, createAction } from '@ngrx/store';
import { UserProError, UserPro } from './user-pro.interface';

export const GetUserPro = createAction(
  '[USER] Get User data'
)

export const GetUserProSuccess = createAction(
  '[USER] Get user data success',
  props<{user: UserPro}>()
);

export const GetUserProFailure = createAction(
  '[USER] Get user data failure',
  props<{error: UserProError}>()
);

export const InviteUserPro = createAction(
  '[USER] Invite User',
  props<{buildingId: string}>()
)

export const InviteUserProSuccess = createAction(
  '[USER] Invite user success',
  props<{url: string}>()
);

export const InviteUserProFailure = createAction(
  '[USER] Get user failure',
  props<{error: UserProError}>()
);
