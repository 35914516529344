import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BuildingsFacade } from '@store/buildings/buildings.facade'

@Component({
  selector: 'app-building-selector',
  templateUrl: './building-selector.component.html',
  styleUrls: ['./building-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingSelectorComponent {

  data$ = combineLatest({
    buildings: this.buildingsFacade.buildings$,
    currentBuilding: this.buildingsFacade.currentBuilding$.pipe(filter(b => !!b))
  });

  constructor(
    private buildingsFacade: BuildingsFacade,
  ) {}

  setCurrentBuilding(buildingId: number): void {
    this.buildingsFacade.selectBuilding(buildingId);
  }

}
