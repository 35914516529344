<div class="measure-card">
  <app-icon [name]="measureTypesToIconTypes[measureType]" class="text-24 has-spacing-left-xxl has-spacing-right-xxs">
  </app-icon>
  <app-text class="text-18 is-family-semiBold has-spacing-left-m" [text]="qrCodeData[measureType]"></app-text>
  <app-text class="text-14 is-family-semiBold has-spacing-left-xs" unit [measureType]="measureType"> </app-text>
  <app-text *ngIf="this.qualityData$ | async as qualityData" class="text-14 is-family-semiBold quality-text"
    [ngStyle]="{'color': qualityData.color}" [text]="qualityData.quality | AsyncLang | async">
  </app-text>
  <app-text></app-text>
</div>
