import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserProReducers } from './user-pro.reducer';
import { UserProEffects } from './user-pro.effect';
import { UserProFacade } from './user-pro.facade';
import { UserProService } from './user-pro.service';

@NgModule({
  imports: [
    StoreModule.forFeature('userPro', UserProReducers),
    EffectsModule.forFeature([
      UserProEffects,
    ]),
  ],
  providers: [
    UserProService,
    UserProFacade
  ]
})
export class UserProModule {}
