import { Zone, TimetableSlot } from "../schedules/schedules.interface";

export function  createBasicZoneTimetable(price?) {
  let zones: Zone[];
  if (price) {
    zones = [
      {
        id: 0,
        price,
        price_type: 'basic'
      }
    ];
  } else {
    zones = [
      {
        id: 0,
        price_type: 'basic'
      }
    ];
  }

  const timetable = [
    {
      zone_id: 0,
      m_offset: 0
    }
  ];
  return { timetable, zones };
}

export function createZones(peakPrice: number, offPeakPrice: number): Zone[] {
  return [
    {
      id: 0,
      price: peakPrice,
      price_type: 'peak'
    },
    {
      id: 1,
      price: offPeakPrice,
      price_type: 'off_peak'
    }
  ];
}

export function createTimetable(flatArray: number[], hasNightPeriod: boolean): TimetableSlot[] {
  /*
   * First add period for 00h00 the first day of the week
   * If zoneId is 0 -> first zone peak, if 1 -> first zone offPeak
   * Change each loop because in a day, we always alernate peak / offPeak periods.
   * repeat for all the week -> 1440 minutes by day
   */
  const timetable = [];
  let zoneId = 1;

  if (!flatArray.includes(0) && !hasNightPeriod) {
    // first zoneId 0((off_peak) only if 0 is included in the off_peak periods
    timetable.push({ zone_id: 0, m_offset: 0 });
  } else if (hasNightPeriod && !flatArray.includes(0)) {
    timetable.push({ zone_id: 1, m_offset: 0 });
    zoneId = 0;
  }
  for (let i = 0; i < 7; i ++) {
    flatArray.forEach(offset => {
      timetable.push({ zone_id: zoneId, m_offset: offset + (1440 * i) });
      zoneId = zoneId ? 0 : 1;
    });
  }

  return timetable;
}

export function peakOffPeakTimetables(offPeakPeriods: [number, number] []) {
  /*
   * check if there is a period in two days (23h -> 6h) if so, add
   * flat the array and ascending sort
   */
  const hasNightPeriod = offPeakPeriods.some((period) => period[0] > period[1]);

  const flatArray = flattenArray(offPeakPeriods);
  const timetable = createTimetable(flatArray, hasNightPeriod);
  return timetable;
}

export function flattenArray(array: [number, number] []) {
  return Array.prototype.concat(...array).sort(((a, b) => a - b));
}

export function chunk(array: number[], size: number) {
  const chunkedArr = [];
  let index = 0;
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index));
    index += size;
  }
  return chunkedArr;
}


export function  createProductionZoneTimetable(price: number) {
  const zones: Zone[] = [
    {
      id: 0,
      price: price,
    }
  ]

  const timetable: TimetableSlot[] = [
    {
      zone_id: 0,
      m_offset: 0
    }
  ];
  return { timetable, zones };
}