import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service';

@Injectable()
export class LastVisitedGuard implements CanActivate {
    constructor(
        private router: Router,
        private storage: LocalStorageService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): UrlTree {
      const lastVisitedRoute = this.storage.getItem('last_visited_page');
      if (lastVisitedRoute) {
        return this.router.createUrlTree([`/${lastVisitedRoute}`], { queryParamsHandling: 'preserve' });
      } else {
        const page = 'dashboard';
        this.storage.setItem('last_visited_page', page);
        return this.router.createUrlTree([`/${page}`], { queryParamsHandling: 'preserve' });
      }
    }

}
