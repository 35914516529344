import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterKeyPipe } from './filter-key/filter-key.pipe';
import { OrderByKeyPipe } from './order-by-key/order-by-key.pipe';
import { PipesModule as LibraryPipesModule } from '@library/utils/pipes/pipes.module';



@NgModule({
  declarations: [
    FilterKeyPipe,
    OrderByKeyPipe,
  ],
  exports: [
    FilterKeyPipe,
    OrderByKeyPipe,
    LibraryPipesModule,
  ],
  imports: [
    CommonModule,
    LibraryPipesModule,
  ]
})
export class PipesModule { }
