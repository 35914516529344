import { Component, OnInit } from '@angular/core';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { emptyData } from '@store/qr-codes/qr-codes.interface';
import { ErrorTypes } from '../qr-code-module/qr-code-module-view.component'

@Component({
  selector: 'app-qr-code-unreachable',
  templateUrl: './qr-code-unreachable.component.html',
  styleUrls: ['./qr-code-unreachable.component.scss']
})
export class QrCodeUnreachableComponent implements OnInit {
  errorText: ErrorTypes = ErrorTypes.UNREACHABLE;
  emptyData = emptyData;
  emptyDataTypes = Object.keys(this.emptyData).filter( key => key !== 'name').map(k => k as EnumMeasureTypes)

  constructor() { }

  ngOnInit(): void {
  }

}
