import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '@pages/dashboard-view/dashboard.service';
import { QRCodesService } from '@store/qr-codes/qr-codes.service';

@Component({
  selector: 'app-qr-code-time-card',
  templateUrl: './qr-code-time-card.component.html',
  styleUrls: ['./qr-code-time-card.component.scss']
})
export class QrCodeTimeCardComponent implements OnInit {
  @Input() idx: number;
  @Input() healthIdx: number;
  @Input() date: number;
  @Input() hourAndUnit: string[];

  constructor(
    public qrCodesService: QRCodesService,
    public dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
  }

  onSelectTime(idx: number, hourAndUnit: string[], date: number) {
    this.qrCodesService.selectTime(idx, hourAndUnit, this.date);
  }
}
