import { Injectable } from '@angular/core';
import { Group } from '@store/groups/groups.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardService {

  selectedGroups = new BehaviorSubject<Group[]>([]);
  selectedGroups$ = this.selectedGroups.asObservable();

  constructor() {}

  getQuality(healthIndex: number) {
    switch(healthIndex) {
      case 0:
        return 'healthy';
      case 1:
        return 'fine';
      case 2:
        return 'fair';
      case 3:
        return 'poor';
      case 4:
        return 'unhealthy';
    default:
        return 'unknown';
    }
  }

  getQualityKey(healthIndex: number) {
    switch(healthIndex) {
      case 0:
        return 'hc-app.__HC_HEALTH_LEVEL_VERY_HEALTHY';
      case 1:
        return 'hc-app.__HC_HEALTH_LEVEL_HEALTHY';
      case 2:
        return 'hc-app.__HC_HEALTH_LEVEL_FAIR';
      case 3:
        return 'hc-app.__HC_HEALTH_LEVEL_UNHEALTHY';
      case 4:
        return 'hc-app.__HC_HEALTH_LEVEL_VERY_UNHEALTHY';
    default:
        return 'unknown';
    }
  }

}
