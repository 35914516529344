import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserProService } from './user-pro.service';
import {
  GetUserPro,
  GetUserProSuccess,
  GetUserProFailure,
  InviteUserPro,
  InviteUserProSuccess,
  InviteUserProFailure,
} from './user-pro.action';
import { UserPro } from './user-pro.interface';

@Injectable()
export class UserProEffects {
  constructor(
    private userProService: UserProService,
    private actions$: Actions,
  ) { }

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserPro),
    switchMap(() => {
      return this.userProService.getUser().pipe(
        map((user: UserPro) => GetUserProSuccess({user})),
        catchError(({ error }) => of(GetUserProFailure(error)))
      );
    })
  ))

  invitetUser$ = createEffect(() => this.actions$.pipe(
    ofType(InviteUserPro),
    switchMap((action) => {
      return this.userProService.invite(action.buildingId).pipe(
        map((action) => InviteUserProSuccess({url: action.url})),
        catchError(({ error }) => of(InviteUserProFailure(error)))
      );
    })
  ))


}

