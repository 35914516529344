import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GroupsFacade } from '@store/groups/groups.facade';
import { catchError, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { DeleteModule, DeleteModuleFailure, DeleteModuleSuccess, UpdateModule,
  UpdateModuleFailure, UpdateModuleGroup, UpdateModuleGroupFailure,
  UpdateModuleGroupSuccess, UpdateModuleSuccess } from './modules-pro.actions';
import { ModulesProFacade } from './modules-pro.facade';
import { ModulesProService } from './modules-pro.service';

@Injectable()
export class ModulesProEffects {

  constructor(
    private actions$: Actions,
    private modulesProService: ModulesProService,
    private moduleProFacade: ModulesProFacade,
    private groupsFacade: GroupsFacade,
  ) {}

  updateModule = createEffect(() => this.actions$.pipe(
    ofType(UpdateModule),
    mergeMap((action) => this.modulesProService.updateModule(action.module).pipe(
      switchMap(() => [
        UpdateModuleSuccess({module: action.module}),
      ]),
      catchError(({error}) => of(UpdateModuleFailure({error, ...action})))
    ))
  ));

  deleteModule = createEffect(() => this.actions$.pipe(
    ofType(DeleteModule),
    mergeMap((action) => this.modulesProService.deleteModule(action.id).pipe(
      switchMap(({data}) => [
        DeleteModuleSuccess({id: data.id}),
      ]),
      catchError(({error}) => of(DeleteModuleFailure({error, ...action})))
    ))
  ));

  updateModuleGroup = createEffect(() => this.actions$.pipe(
    ofType(UpdateModuleGroup),
    mergeMap(action => of(action).pipe(
      withLatestFrom(
        this.moduleProFacade.getModuleGroup(action.module.id),
        this.groupsFacade.getGroup(action.module.group),
      ),
    )),
    mergeMap(([action, previousGroup, group]) => this.modulesProService.updateModule(action.module).pipe(
      switchMap(() => [
        UpdateModuleGroupSuccess({
          module: action.module,
          previousGroup,
          group,
        }),
      ]),
      catchError(({error}) => of(UpdateModuleGroupFailure({error, ...action})))
    ))
  ));

}
