import { createAction, props } from '@ngrx/store';
import { Group } from '@store/groups/groups.interface';
import { ModuleProDB } from './modules-pro.interface';

export const CleanModulesProRemoved = createAction(
  '[ModulesPro] Clean modules removed from netatmo db',
);

export const UpdateModule = createAction(
  '[ModulesPro] Update module',
  props<{module: Partial<ModuleProDB>}>(),
);

export const UpdateModuleSuccess = createAction(
  '[ModulesPro] Update module success',
  props<{module: Partial<ModuleProDB>}>(),
);

export const UpdateModuleFailure = createAction(
  '[ModulesPro] Update module failure',
  props<{error, module: Partial<ModuleProDB>}>(),
);

export const DeleteModule = createAction(
  '[ModulesPro] Delete module',
  props<{id: number}>(),
);

export const DeleteModuleSuccess = createAction(
  '[ModulesPro] Delete module success',
  props<{id: number}>(),
);

export const DeleteModuleFailure = createAction(
  '[ModulesPro] Delete module failure',
  props<{error, id: number}>(),
);

export const UpdateModuleGroup = createAction(
  '[ModulesPro] Update Module Group',
  props<{module: Pick<ModuleProDB, 'id' | 'group'>}>(),
);

export const UpdateModuleGroupSuccess = createAction(
  '[ModulesPro] Update Module Group success',
  props<{module: Pick<ModuleProDB, 'id' | 'group'>, previousGroup: Group, group: Group}>(),
);

export const UpdateModuleGroupFailure = createAction(
  '[ModulesPro] Update Module Group failure',
  props<{error, module: Pick<ModuleProDB, 'id' | 'group'>}>(),
);
