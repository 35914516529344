import { Injectable } from '@angular/core';
import { HttpBuildingService } from 'src/app/services/http-building.service';
import { ModuleProDB } from './modules-pro.interface';

@Injectable()
export class ModulesProService {
  constructor(
    private http: HttpBuildingService) {
  }

  updateModule(module: Partial<ModuleProDB>) {
    return this.http.put(`devices/${module.id}`, {data: module});
  }

  deleteModule(id: number) {
    return this.http.delete(`devices/${id}`);
  }

}
