import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { GroupsEffects } from './groups.effects'
import { GroupsFacade } from './groups.facade'
import { buildingsReducers } from './groups.reducer'
import { GroupsService } from './groups.service'

@NgModule({
  imports: [
    StoreModule.forFeature('groups', buildingsReducers),
    EffectsModule.forFeature([
      GroupsEffects,
    ]),
  ],
  providers: [
    GroupsFacade,
    GroupsService
  ]
})
export class GroupsModule {}
