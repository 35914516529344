import { createSelector } from "@ngrx/store";
import { ContractTypes, ContractsState } from "./contracts.interface";

export const getProductionContract = createSelector(
  (state: { contracts: ContractsState }) => state.contracts,
  (contracts: ContractsState) => {
    return contracts.all.find(contract => contract.type === ContractTypes.production)
  }
);
export const getConsumptionContract = createSelector(
  (state: { contracts: ContractsState }) => state.contracts,
  (contracts: ContractsState) => {
    return contracts.all.find(contract => contract.type === ContractTypes.consumption);
  },
);

export const getPowerUnit = createSelector(
  (state: { contracts: ContractsState }) => state.contracts,
  (contract: ContractsState) => {
    return contract.powerUnit;
  },
)

export const getContractsTemplate = createSelector(
  (state: { contracts: ContractsState }) => state.contracts,
  (contracts: ContractsState) => {
    return contracts.contratTemplates;
  }
);

export const getContractTemplateById = (id: string) => createSelector(
  getContractsTemplate,
  (contratTemplates) => contratTemplates.find(template => template.id === id)
);

export const isConsumptionContractStatic = createSelector(
  getConsumptionContract,
  (contract) => contract?.version === 2
);
