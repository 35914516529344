import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardViewComponent } from './dashboard-view.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ViewModule } from 'src/app/components/view/view.module';
import { DashboardViewSidebarComponent } from './dashboard-view-sidebar/dashboard-view-sidebar.component';
import { DashboardViewContentComponent } from './dashboard-view-content/dashboard-view-content.component';
import { DashboardViewGroupCardComponent } from './dashboard-view-sidebar/dashboard-view-group-card/dashboard-view-group-card.component';
import { DashboardViewDeviceRowComponent } from './dashboard-view-content/dashboard-view-device-row/dashboard-view-device-row.component';
import { DashboardViewOrderingComponent } from './dashboard-view-content/dashboard-view-ordering/dashboard-view-ordering.component';
import { DashboardService } from './dashboard.service';
import { MoleculesModule } from '@molecules/molecules.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AtomsModule } from '@atoms/atoms.module';
import { UIModule } from '@library/ui/ui.module';
import { DirectivesModule } from '@library/ui/directives/directives.module';
import { NoDeviceInBuildingViewComponent } from '@pages/no-device-in-building-view/no-device-in-building-view.component'

const routes: Routes = [
  {
    path: '',
    component: DashboardViewComponent,
  },
  {
    path: 'no-device',
    component: NoDeviceInBuildingViewComponent
  }
];

@NgModule({
  declarations: [
    DashboardViewComponent,
    DashboardViewSidebarComponent,
    DashboardViewContentComponent,
    DashboardViewGroupCardComponent,
    DashboardViewDeviceRowComponent,
    DashboardViewOrderingComponent,
    NoDeviceInBuildingViewComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    ViewModule,
    DirectivesModule,
    AtomsModule,
    MoleculesModule,
    PipesModule,
  ],
  providers: [
    DashboardService,
  ]
})
export class DashboardViewModule { }
