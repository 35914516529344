import { MeasuresState } from './measures.interface';

export const initialMeasuresState: MeasuresState = {
  modules: [],
  rooms: [],
  homes: {},
  loadStatus: {
    homes: [],
    modules: [],
    rooms: [],
  }
};
