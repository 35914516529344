<app-icon class="building-icon is-size-4 has-text-netatmo" name="netatmo"></app-icon>

<app-building-selector></app-building-selector>

<a [routerLink]="['/dashboard']" routerLinkActive="active-icon">
  <app-sidebar-link icon="scenarios">
    <app-hover-text>
      {{'station-app.__DASHBOARD_TITLE' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>
<a [routerLink]="['/graphs']" *ngIf="hasModulesInBuilding$ | async" routerLinkActive="active-icon">
  <app-sidebar-link icon="activity">
    <app-hover-text>
      {{'common-app.__COM_SETTINGS_GRAPH' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>

<a [routerLink]="['/report']" *ngIf="hasModulesInBuilding$ | async" routerLinkActive="active-icon">
  <app-sidebar-link icon="pie-chart">
    <app-hover-text>
      {{'iaq-app.__IAQ_REPORTS' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>

<a [routerLink]="['/settings']" routerLinkActive="active-icon">
  <app-sidebar-link icon="settings">
    <app-hover-text>
      {{'common-settings.__MY_SETTINGS' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>

<a [routerLink]="['/settings/account']" class="bottom" >
  <app-sidebar-link icon="account">
    <app-hover-text>
      {{'common-settings.__MY_ACCOUNT' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>

<a [href]="logoutUrl" >
  <app-sidebar-link icon="log-out">
    <app-hover-text>
      {{'common-settings.__MY_LOGOUT' | AsyncLang | async}}
    </app-hover-text>
  </app-sidebar-link>
</a>
