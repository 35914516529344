import { Component, Input, OnInit } from '@angular/core';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { DashboardService } from '@pages/dashboard-view/dashboard.service';
import { ModulePro } from '@store/modules-pro/modules-pro.interface';

@Component({
  selector: 'app-dashboard-view-device-row',
  templateUrl: './dashboard-view-device-row.component.html',
  styleUrls: ['./dashboard-view-device-row.component.scss']
})
export class DashboardViewDeviceRowComponent implements OnInit {

  EnumMeasureTypes = EnumMeasureTypes;

  @Input() device: ModulePro;

  constructor(
    public dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
  }

  isReachable() {
    return this.device.reachable;
  }

}
