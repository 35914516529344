<ng-container *ngIf="viewModel$ | async as vm">

  <app-view-sidebar-title>{{'station-app.__DASHBOARD_TITLE' | AsyncLang | async}}</app-view-sidebar-title>
  <app-view-sidebar-subtitle>{{vm.currentBuilding ? vm.currentBuilding.name : ''}}</app-view-sidebar-subtitle>

  <ng-container *ngFor="let group of vm.groups">
    <app-dashboard-view-group-card [group]="group"
      class="is-cursor-pointer"
      [class.is-selected]="vm.selectedGroups.includes(group)"
      (click)="toggleGroup(group, vm.selectedGroups)">
    </app-dashboard-view-group-card>
  </ng-container>

</ng-container>
