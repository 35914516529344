import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { UpdateBuildingTokenFailure, UpdateBuildingTokenSuccess } from '@store/buildings/buildings.actions'
import { BuildingsFacade } from '@store/buildings/buildings.facade'
import { firstValueFrom } from 'rxjs'

@Component({
  selector: 'app-post-authorize-view',
  templateUrl: './post-authorize-view.component.html',
  styleUrls: ['./post-authorize-view.component.scss']
})
export class PostAuthorizeViewComponent implements OnInit {

  constructor(
    private buildingsFacade: BuildingsFacade,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    const params = await firstValueFrom(this.activatedRoute.queryParams);
    const buildingId = params.id

    const action = await firstValueFrom(this.buildingsFacade.updateBuildingTokens({buildingId, code: this.activatedRoute.snapshot.queryParams.code}))

    if (action.type === UpdateBuildingTokenSuccess.type) {
      this.router.navigate(['settings']);
    } else if (action.type === UpdateBuildingTokenFailure.type && action.error?.error?.status === 403 && action.error?.error?.message === 'NoBuildingAccess') {
      console.log('you do not have access to this building');
    }
  }
}
