import { Injectable } from '@angular/core';
import { ModulesFacade } from '@library/store/modules/modules.facade';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, combineLatest, map, switchMap, take, tap } from 'rxjs';
import { HomesFacade } from '../homes/homes.facade';
import { CreateContract, CreateContractFailure, CreateContractProduction, CreateContractProductionFailure, CreateContractProductionSuccess, CreateContractSuccess, GetContractTemplates, GetContractTemplatesFailure, GetContractTemplatesSuccess, GetContracts, GetContractsFailure, GetContractsSuccess, SetConsumptionContractStore, SetContract, SetContractFailure, SetContractSuccess, SetProductionContractStore, UpdateContractUnit } from './contracts.actions';
import { createBasicZoneTimetable, createProductionZoneTimetable, createZones, peakOffPeakTimetables } from './contracts.function';
import { Contract, ContractState, ContractTypes, PowerUnit } from './contracts.interface';
import { getConsumptionContract, getContractTemplateById, getContractsTemplate, getPowerUnit, getProductionContract, isConsumptionContractStatic } from './contracts.selector';

@Injectable()
export class ContractsFacade {

  productionContract$ = this.store$.pipe(select(getProductionContract));
  consumptionContract$ = this.store$.pipe(select(getConsumptionContract));
  isConsumptionContractStatic$ = this.store$.pipe(select(isConsumptionContractStatic));
  powerUnit$ = this.store$.pipe(select(getPowerUnit));
  contractTemplates$ = this.store$.pipe(select(getContractsTemplate));

  constructor(
    public store$: Store,
    public actions$: Actions,
    public modulesFacade: ModulesFacade,
    public homesFacade: HomesFacade,
  ) { }

  getContracts(homeId: string): Observable<Action> {
    this.store$.dispatch(GetContracts({ params: { home_id: homeId } }))
    return this.actions$.pipe(
      ofType(
        GetContractsSuccess,
        GetContractsFailure,
      ),
      take(1)
    );
  }

  getContractTemplateById(id: string): Observable<Partial<Contract>> {
    return this.store$.select(getContractTemplateById(id))
  }

  getContractsTemplates(country: string, tariffVariationDay: boolean, tariffVariationWeek: boolean): Observable<Action> {
    const isDaily = tariffVariationDay ? 'daily' : 'notDaily'
    const isWeekly = tariffVariationWeek ? 'weekly' : 'notWeekly'
    const filters = [isDaily, isWeekly];
    this.store$.dispatch(GetContractTemplates({ country, filters }));
    return this.actions$.pipe(
      ofType(
        GetContractTemplatesSuccess,
        GetContractTemplatesFailure
      ),
      take(1)
    )
  }

  setPowerUnit(unit: PowerUnit) {
    this.store$.dispatch(UpdateContractUnit({ unit }))
  }
  setConsumptionContractStore(params: Partial<ContractState>): void {
    return this.store$.dispatch(SetConsumptionContractStore({ contract: params }));
  }

  setContractProduction(): Observable<Action> {
    return combineLatest([
      this.homesFacade.currentHomeId$,
      this.productionContract$,
      this.powerUnit$
    ]).pipe(
      map((
        [homeId, productionContract, powerUnit]
      ) => ({ homeId, productionContract, powerUnit })
      ),
      tap(data => {
        const params: Contract = {
          id: data.productionContract.id,
          schedule_id: data.productionContract.schedule_id,
          home_id: data.homeId,
          tariff: data.productionContract.tariff,
          tariff_option: data.productionContract.tariff_option,
          power_threshold: data.productionContract.power_threshold,
          zones: data.productionContract.zones,
          timetable: data.productionContract.timetable,
          contract_power_unit: data.powerUnit,
          type: ContractTypes.production,
          production_contract_type: 'surplus', // todo for now not used
          // provider_info: 'engie', // still exisint ?
        };
        this.store$.dispatch(SetContract({ params }));
      }),
      switchMap(() => this.actions$),
      ofType(
        SetContractSuccess,
        SetContractFailure,
      ),
      take(1));
  }

  setContractConsumption(): Observable<Action> {
    return combineLatest([
      this.homesFacade.currentHomeId$,
      this.consumptionContract$,
      this.powerUnit$
    ]).pipe(
      map(([homeId, consumptionContract, powerUnit]
      ) => ({ homeId, consumptionContract, powerUnit })),
      tap(data => {
        const params: Contract = {
          creation_date: data.consumptionContract.creation_date,
          id: data.consumptionContract.id,
          template_id: data.consumptionContract.template_id,
          schedule_id: data.consumptionContract.schedule_id,
          home_id: data.homeId,
          regulated: data.consumptionContract.regulated,
          tariff: data.consumptionContract.tariff,
          tariff_option: data.consumptionContract.tariff_option,
          power_threshold: data.consumptionContract.power_threshold,
          zones: data.consumptionContract.zones,
          timetable: data.consumptionContract.timetable,
          contract_power_unit: data.powerUnit,
          type: ContractTypes.consumption,
          version: data.consumptionContract.version,
          // provider_info: {name: 'engie'}, // TODO when we have spec
        };

        this.store$.dispatch(SetContract({ params }));
      }),
      switchMap(() => this.actions$),
      ofType(
        SetContractSuccess,
        SetContractFailure,
      ),
      take(1));
  }

  createContractConsumption() {
    return combineLatest([
      this.homesFacade.currentHomeId$,
      this.consumptionContract$,
      this.powerUnit$
    ]).pipe(
      map(([homeId, consumptionContract, powerUnit]
      ) => ({ homeId, consumptionContract, powerUnit })),
      tap(data => {
        const params: Contract = {
          creation_date: data.consumptionContract.creation_date,
          home_id: data.homeId,
          template_id: data.consumptionContract.template_id,
          tariff: data.consumptionContract.tariff,
          tariff_option: data.consumptionContract.tariff_option,
          power_threshold: data.consumptionContract.power_threshold,
          zones: data.consumptionContract.zones?.length !== 0 ? data.consumptionContract.zones : undefined,
          timetable: data.consumptionContract.timetable?.length !== 0 ? data.consumptionContract.timetable : undefined,
          contract_power_unit: data.consumptionContract.contract_power_unit ?? data.powerUnit,
          version: data.consumptionContract.version,
          regulated: data.consumptionContract.regulated,
          type: ContractTypes.consumption,
        };

        this.store$.dispatch(CreateContract({ params }));
      }),
      switchMap(() => this.actions$),
      ofType(
        CreateContractSuccess,
        CreateContractFailure,
      ),
      take(1));
  }

  createContractProduction() {
    return combineLatest([
      this.homesFacade.currentHomeId$,
      this.productionContract$,
      this.powerUnit$
    ]).pipe(
      map(([homeId, productionContract, powerUnit]
      ) => ({ homeId, productionContract, powerUnit })),
      tap(data => {
        const params: Contract = {
          home_id: data.homeId,
          zones: data.productionContract.zones?.length !== 0 ? data.productionContract.zones : undefined,
          timetable: data.productionContract.timetable?.length !== 0 ? data.productionContract.timetable : undefined,
          contract_power_unit: data.powerUnit,
          type: ContractTypes.production,
          production_contract_type: 'surplus' // for now only surplus is supported
        };

        this.store$.dispatch(CreateContractProduction({ params }));
      }),
      switchMap(() => this.actions$),
      ofType(
        CreateContractProductionSuccess,
        CreateContractProductionFailure,
      ),
      take(1));
  }


  setContractBaseStore(price?: number) {
    // when no price -> edf
    const { timetable, zones } = createBasicZoneTimetable(price);
    this.setConsumptionContractStore({ timetable, zones, basicPrice: price });
  }

  setContractPeakOffPeakStore(offPeakPeriods: [number, number][], peakPrice: number, offPeakPrice: number) {
    const timetable = peakOffPeakTimetables(offPeakPeriods);
    const zones = createZones(peakPrice, offPeakPrice);
    this.setConsumptionContractStore({ timetable, zones, peak: peakPrice, off_peak: offPeakPrice });
  }

  setProductionContractStore(price: number) {
    const { timetable, zones } = createProductionZoneTimetable(price);
    return this.store$.dispatch(SetProductionContractStore({ contract: { timetable, zones, basicPrice: price, production_contract_type: 'surplus', type: ContractTypes.production } }));
  }

}
