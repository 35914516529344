import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LangReducers } from './lang.reducers';
import { LangEffects } from './lang.effects';
import { LangFacade } from './lang.facade';
import { LangService } from './lang.service';

@NgModule({
  imports: [
    StoreModule.forFeature('langs', LangReducers),
    EffectsModule.forFeature([
      LangEffects,
    ]),
  ],
  providers: [
    LangService,
    LangFacade
  ]
})
export class LangModule {}
