import { createAction, props } from "@ngrx/store";
import { ModulePro } from "@store/modules-pro/modules-pro.interface";
import { QRCodeFromAPI, AllQRCodesFromAPI, QrCodePDFRequest, QrCodeQuery, QrCodeHistoricalDataFromAPI, QrCodeLiveDataFromAPI } from "./qr-codes.interface";

export const GetAllQRCodes = createAction(
  '[QRCODES] Get all QR codes',
  props<{ buildingId: number }>()
);

export const GetAllQRCodesSuccess = createAction(
  '[QRCODES] Get all QR codes success',
  props<{data: AllQRCodesFromAPI, modules: ModulePro[]}>()
);

export const GetAllQRCodesFailure = createAction(
  '[QRCODES] Get all QR codes failure',
  props<{ error: { code: number, status: number } }>()
);

export const GetQRCodeLiveData = createAction(
  '[QRCODES] Get QR Code Live Data',
  props<{buildingId: number, qrCodeUid: string }>()
);

export const GetQRCodeLiveDataSuccess = createAction(
  '[QRCODES] Get QR Code Live Data success',
  props<{data: QrCodeLiveDataFromAPI, qrCodeUid: string}>()
);

export const GetQRCodeLiveDataFailure = createAction(
  '[QRCODES] Get QR Code Live Data failure',
  props<{data: null, error: { code: number, status: number },  qrCodeUid: string }>()
);

export const GetQRCodeHistoricalData = createAction(
  '[QRCODES] Get QR Code Historical Data',
  props<{buildingId: number, qrCodeUid: string, params: QrCodeQuery }>()
);

export const GetQRCodeHistoricalDataSuccess = createAction(
  '[QRCODES] Get QR Code Historical Data success',
  props<{data: QrCodeHistoricalDataFromAPI, qrCodeUid: string}>()
);

export const GetQRCodeHistoricalDataFailure = createAction(
  '[QRCODES] Get QR Code Historical Data failure',
  props<{data: null, error: { code: number, status: number },  qrCodeUid: string }>()
);

export const GenerateNewQrCode = createAction(
  '[QRCODES] Generate new QR Code',
  props<{ device_id:string, isEnabled?: boolean }>()
);

export const GenerateNewQrCodeSuccess = createAction(
  '[QRCODES] Generate new QR Code success',
  props<{data: QRCodeFromAPI}>()
);

export const GenerateNewQrCodeFailure = createAction(
  '[QRCODES] Generate new QR Code failure',
  props<{data: null, error: { code: number, status: number },  qrCodeUid: string }>()
);

export const RegenerateQrCode = createAction(
  '[QRCODES] Regenerate QR Code',
  props<{ device_id:string, isEnabled?: boolean }>()
);

export const RegenerateQrCodeSuccess = createAction(
  '[QRCODES] Regenerate QR Code success',
  props<{data: QRCodeFromAPI}>()
);

export const RegenerateQrCodeFailure = createAction(
  '[QRCODES] Regenerate QR Code failure',
  props<{data: null, error: { code: number, status: number },  qrCodeUid: string }>()
);

export const DeleteQrCode = createAction(
  '[QRCODES] Delete QR Code',
  props<{qrCodeId: number}>()
);
export const DeleteQrCodeSuccess = createAction(
  '[QRCODES] Delete QR Code success',
  props<{data: QRCodeFromAPI}>()
);

export const DeleteQrCodeFailure = createAction(
  '[QRCODES] Delete QR Code failure',
  props<{data: null, error: { code: number, status: number },  qrCodeUid: string }>()
);

export const ToggleQrCodeEnabledStatus = createAction(
  '[QRCODES] Toggle QR Code enabled status',
  props<{ qrCodeId: number, isEnabled:boolean}>()
);
export const ToggleQrCodeEnabledStatusSuccess = createAction(
  '[QRCODES] Toggle QR Code enabled status success',
  props< {data: QRCodeFromAPI}>()
);

export const ToggleQrCodeEnabledStatusFailure = createAction(
  '[QRCODES] Toggle QR Code enabled status failure',
  props<{data: null, error: { code: number, status: number },  qrCodeId: number }>()
);

export const GeneratePDF = createAction(
  '[QRCODES] Generate PDF',
  props<{params: QrCodePDFRequest}>()
);

export const GeneratePDFSuccess = createAction(
  '[QRCODES] Generate PDF success',
  props< {data: Blob}>()
);

export const GeneratePDFFailure = createAction(
  '[QRCODES] Generate PDF failure',
  props<{data: null, error: { code: number, status: number },  qrCodeId: number }>()
);