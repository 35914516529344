import { Injectable } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service'
import { Actions, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { ModulePro, ModuleProDB } from '@store/modules-pro/modules-pro.interface';
import { filter, Observable, take } from 'rxjs';
import {
  Authorize, AuthorizeFailure, AuthorizeSuccess, CreateBuilding,
  CreateBuildingDevice, CreateBuildingDeviceFailure, CreateBuildingDeviceSuccess,
  CreateBuildingFailure, CreateBuildingSuccess, DeleteBuilding, DeleteBuildingFailure, DeleteBuildingSuccess, DeleteUser, DeleteUserSuccess, GetBuilding, GetBuildingDevices,
  GetBuildingDevicesFailure, GetBuildingDevicesSuccess, GetBuildingFailure,
  GetBuildings, GetBuildingsFailure, GetBuildingsSuccess, GetBuildingSuccess,
  RefreshAuthorize, RefreshAuthorizeFailure, RefreshAuthorizeSuccess, ResetThresholds, ResetThresholdsFailure, ResetThresholdsSuccess, SelectBuilding,
  GetUnplacedDevicesData,
  GetUnplacedDevicesDataFailure,
  GetUnplacedDevicesDataSuccess,
  UpdateBuilding, UpdateBuildingFailure, UpdateBuildingSuccess, UpdateBuildingToken,
  UpdateBuildingTokenFailure, UpdateBuildingTokenSuccess
} from './buildings.actions';
import { Building, BuildingsState } from './buildings.interface';
import { buildings, currentBuilding, currentBuildingAdminId, currentBuildingId, currentTimezone } from './buildings.selectors';

@Injectable()
export class BuildingsFacade {
  buildings$ = this.store.pipe(select(buildings));
  currentBuilding$ = this.store.pipe(select(currentBuilding));
  currentBuildingId$ = this.store.pipe(select(currentBuildingId));
  currentTimezone$ = this.store.pipe(select(currentTimezone));
  currentBuildingAdminId$ = this.store.pipe(select(currentBuildingAdminId));

  constructor(
    private store: Store<{'buildings': BuildingsState}>,
    private localStorage: LocalStorageService,
    public actions$: Actions,
  ) { }

  loadBuildings() {
    this.store.dispatch(GetBuildings());
    return this.actions$.pipe(
      ofType(GetBuildingsSuccess, GetBuildingsFailure),
      take(1)
    );
  }

  loadBuilding(id: number) {
    this.store.dispatch(GetBuilding({id}));
    return this.actions$.pipe(
      ofType(GetBuildingSuccess, GetBuildingFailure),
      filter(action => action.id === id),
      take(1)
    );
  }

  createBuilding(code: string, name: string, tz: string) {
    this.store.dispatch(CreateBuilding({code, name, tz}));
    return this.actions$.pipe(
      ofType(CreateBuildingSuccess, CreateBuildingFailure),
      take(1)
    );
  }

  selectBuilding(id: number): Observable<Action> {
    this.store.dispatch(SelectBuilding({id}));
    this.localStorage.setItem('selectedBuilding', id.toString());
    return this.actions$.pipe(
      ofType(GetBuildingSuccess, GetBuildingFailure),
      take(1)
    )
  }

  getBuildingDevices(buildingId: number) {
    this.store.dispatch(GetBuildingDevices({buildingId}));

    return this.actions$.pipe(
      ofType(GetBuildingDevicesSuccess, GetBuildingDevicesFailure),
      filter(action => action.buildingId === buildingId),
      take(1)
    );
  }

  authorize() {
    this.store.dispatch(Authorize());

    return this.actions$.pipe(
      ofType(AuthorizeSuccess, AuthorizeFailure),
      take(1)
    );
  }

  createBuildingDevice(buildingId: number, device: ModuleProDB) {
    this.store.dispatch(CreateBuildingDevice({ buildingId, device }));

    return this.actions$.pipe(
      ofType(CreateBuildingDeviceSuccess, CreateBuildingDeviceFailure),
      take(1)
    );
  }

  refreshAuthorize(buildingId: number) {
    this.store.dispatch(RefreshAuthorize({buildingId}));

    return this.actions$.pipe(
      ofType(RefreshAuthorizeSuccess, RefreshAuthorizeFailure),
      take(1)
    );
  }

  updateBuildingTokens(params: {buildingId: number, code: string}) {
    this.store.dispatch(UpdateBuildingToken(params));
    return this.actions$.pipe(
      ofType(UpdateBuildingTokenSuccess, UpdateBuildingTokenFailure),
      take(1)
    );
  }

  updateBuilding(building: Partial<Building>) {
    this.store.dispatch(UpdateBuilding({building}));
    return this.actions$.pipe(
      ofType(UpdateBuildingSuccess, UpdateBuildingFailure),
      take(1)
    );
  }

  deleteUser(buildingId: number, userId: number) {
    this.store.dispatch(DeleteUser({buildingId, userId}));
    return this.actions$.pipe(
      ofType(DeleteUserSuccess),
      filter(action => action.buildingId === buildingId && userId === userId),
      take(1),
    )
  }

  deleteBuilding(buildingId: number) {
    this.store.dispatch(DeleteBuilding({buildingId}));
    return this.actions$.pipe(
      ofType(DeleteBuildingSuccess, DeleteBuildingFailure),
      filter(action => action.buildingId === buildingId),
      take(1),
    )
  }

  resetThresholds(buildingId: number) {
    this.store.dispatch(ResetThresholds({buildingId}));
    return this.actions$.pipe(
      ofType(ResetThresholdsSuccess, ResetThresholdsFailure),
      filter(action => action.buildingId === buildingId),
      take(1),
    );
  }

  getUnplacedDevicesData(buildingId: Building['id']) {
    this.store.dispatch(GetUnplacedDevicesData({buildingId}))
    return this.actions$.pipe(
      ofType(GetUnplacedDevicesDataSuccess, GetUnplacedDevicesDataFailure),
      take(1),
    );
  }
}
