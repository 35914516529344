import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs'
import { CreateGroup, CreateGroupFailure, CreateGroupSuccess, DeleteGroup, DeleteGroupFailure, DeleteGroupSuccess, UpdateGroup, UpdateGroupFailure, UpdateGroupSuccess } from './groups.actions'
import { Group, GroupsState } from './groups.interface'
import { buildingGroups, getGroup, getGroupDevice, groupDevices } from './groups.selectors'

@Injectable()
export class GroupsFacade {

  buildingGroups$ = this.store.pipe(select(buildingGroups));

  getGroup = (id: Group['id']) => this.store.pipe(select(getGroup(id)));
  getFirstDeviceIdOfGroup = (id: Group['id']) => this.store.pipe(select(getGroupDevice(id)))

  constructor(
    private store: Store<{'groups': GroupsState}>,
    public actions$: Actions,
  ) { }

  groupDevices(group: Group) {
    return this.store.pipe(select(groupDevices(group)));
  }

  createGroup(name: string) {
    // check if group name is unique in the building
    this.store.dispatch(CreateGroup({name}));
    return this.actions$.pipe(
      ofType(CreateGroupSuccess, CreateGroupFailure),
      take(1)
    );
  }

  updateGroup(group: Partial<Group>) {
    this.store.dispatch(UpdateGroup({group}));
    return this.actions$.pipe(
      ofType(UpdateGroupSuccess, UpdateGroupFailure),
      filter(action => action.group.id === group.id),
      take(1)
    );
  }

  deleteGroup(id: number) {
    this.store.dispatch(DeleteGroup({id}));
    return this.actions$.pipe(
      ofType(DeleteGroupSuccess, DeleteGroupFailure),
      filter(action => action.id === id),
      take(1)
    );
  }
}
