<app-view>

  <app-view-sidebar>
    <app-dashboard-view-sidebar></app-dashboard-view-sidebar>
  </app-view-sidebar>

  <app-view-content [class]="{ 'has-extra-padding-top' : (displayBannerInformation$ | async) }">

    <app-information-banner (isDisplayed)="shouldDisplayBannerInformation($event)"></app-information-banner>
    <app-dashboard-view-content></app-dashboard-view-content>

  </app-view-content>

</app-view>
