import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Analytics } from '@library/utils/services/analytics.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  constructor(
    private analytics: Analytics
  ) {}

  async ngOnInit() {
    // Init user consent for analytics
    this.analytics.initUserConsent();
  }
}
