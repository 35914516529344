import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { MenuItemComponent } from './context-menu/menu-item/menu-item.component';
import { MeasureTypesComponent } from './measure-types/measure-types.component';
import { SettingsAddButtonComponent } from './settings-add-button/settings-add-button.component';
import { SettingsInputTextComponent } from './settings-input-text/settings-input-text.component';
import { SettingsSearchBarComponent } from './settings-search-bar/settings-search-bar.component';
import { InformationBannerComponent } from './information-banner/information-banner.component';
import { PipesModule } from '@library/utils/pipes/pipes.module'
import { UIModule } from '@library/ui/ui.module';
import { RadioButtonsModule } from '@library/ui/organisms/radio-buttons/radio-buttons.module';
import { RouterModule } from '@angular/router'
import { SectionHeaderComponent } from './section-header/section-header.component';
import { TextModule } from '@library/ui/atoms/text/text.module'

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UIModule,
    RadioButtonsModule,
    ReactiveFormsModule,
    RouterModule,
    TextModule
  ],
  declarations: [
    ContextMenuComponent,
    MenuItemComponent,
    MeasureTypesComponent,
    SettingsSearchBarComponent,
    SettingsAddButtonComponent,
    SettingsInputTextComponent,
    InformationBannerComponent,
    SectionHeaderComponent
  ],
  exports: [
    ContextMenuComponent,
    MenuItemComponent,
    MeasureTypesComponent,
    SettingsSearchBarComponent,
    SettingsAddButtonComponent,
    SettingsInputTextComponent,
    InformationBannerComponent,
    SectionHeaderComponent
  ],
})
export class MoleculesModule { }
