import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';
import { AcceptInvitationComponent } from '@pages/accept-invitation/accept-invitation.component'
import { MainPageComponent } from '@pages/main-page/main-page.component';
import { NoAccessViewComponent } from '@pages/no-access-view/no-access-view.component'
import { PostAuthorizeViewComponent } from '@pages/post-authorize-view/post-authorize-view.component';
import { RefreshAuthorizeViewComponent } from '@pages/refresh-authorize-view/refresh-authorize-view.component';
import { ValidateStateGuard } from 'src/app/router/validate-state.gard';
import { InvitationExpiredComponent } from './invitation-expired/invitation-expired.component'
import { LastVisitedGuard } from './router/last-visited.guard';
import { LastVisitedResolver } from './router/last-visited.resolver';
import { BuildingLangsResolver } from './store/langs/building-langs.resolver';
import { NoBuildingViewComponent } from '@pages/no-building-view/no-building-view.component';

const routes: Routes = [
  {
    path: '',
    resolve : { BuildingLangsResolver },
        component: MainPageComponent,
        children: [
          {
            path: '',
            component: {} as Type<any>,
            canActivate: [LastVisitedGuard],
          },
          {
            path: 'dashboard',
            resolve: { LastVisitedResolver },
            loadChildren: () => import('./pages/dashboard-view/dashboard-view.module').then( m => m.DashboardViewModule),
            data: {
              visited_route: 'dashboard'
            }
          },
          {
            path: 'settings',
            loadChildren: () => import('./pages/settings-view/settings-view.module').then( m => m.SettingsViewModule),
            data: {
              visited_route: 'settings'
            }
          },
          {
            path: 'report',
            resolve: { LastVisitedResolver },
            loadChildren: () => import('./pages/report-view/report-view.module').then( m => m.ReportViewModule),
            data: {
              visited_route: 'report'
            }
          },
          {
            path: 'graphs',
            resolve: { LastVisitedResolver },
            loadChildren: () => import('./pages/graphs-view/graphs-view.module').then( m => m.GraphsViewModule),
            data: {
              visited_route: 'graphs'
            }
          },
          {
            path: 'download-report',
            loadChildren: () => import('./pages/download-report-view/download-report-view.module').then( m => m.DownloadReportViewModule),
          },
          {
            path: 'refresh',
            children: [
              {
                path: '',
                component: RefreshAuthorizeViewComponent,
              },
              {
                path: 'postauthorize',
                component: PostAuthorizeViewComponent,
                canActivate: [ValidateStateGuard]
              }
            ]
          },
          {
            path: 'accept',
            component: AcceptInvitationComponent
          },
          {
            path: 'no-building',
            component: NoBuildingViewComponent,
          },
          {
            path: 'no-access',
            component: NoAccessViewComponent
          },
          {
            path: 'expired-invitation',
            component: InvitationExpiredComponent
          },
          {
            path: 'installation',
            loadChildren: () => import('./pages/installation-view/installation-view.module').then( m => m.InstallationViewModule),
          },
    ],
  },
  {
    path: 'qr-code',
    loadChildren: () => import('./pages/qr-code-view/qr-code-view.module').then( m => m.QrCodeViewModule),
  },
  // catchall for wrong Urls
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [LastVisitedGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
