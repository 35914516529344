import { createReducer, on, State } from '@ngrx/store';
import { CreateBuildingDeviceSuccess, DeleteBuildingSuccess, GetBuildingsSuccess, GetBuildingSuccess } from '@store/buildings/buildings.actions';
import { DeleteGroupSuccess } from '@store/groups/groups.actions';
import { RawGroup } from '@store/groups/groups.interface';
import { GetQRCodeLiveDataSuccess } from '@store/qr-codes/qr-codes.actions';
import { CleanModulesProRemoved, DeleteModuleSuccess, UpdateModuleGroupSuccess } from './modules-pro.actions';
import { ModulePro, ModulesProState } from './modules-pro.interface';

export const initialModulesproState: ModulesProState = {
  modulesPro: [],
};

export const buildingsReducers = createReducer(
  initialModulesproState,

  on(GetBuildingsSuccess, (state, {buildings}) => {
    const devicesMap: Map<number, ModulePro> = new Map([]);

    buildings.forEach(building => {
      if (building.devices) {
        building.devices.forEach(device => {
          // get group of device
          let group: RawGroup;
          if (building.groups) {
            group = building.groups.find(group => group.devices.find(dev => dev.id === device.id))
          }
          // if the device has no group, we create a fake one
          if (!group) {
            group = {
              id: -1,
            } as RawGroup;
          }

          devicesMap.set(device.id, {
            ...device,
            buildingId: building.id,
            groupId: group ? group.id : null,
            groupName: group? group.name: null,
            error: device.error
          });
        })
      }
    })

    return {
      ...state,
      modulesPro: [...devicesMap.values()]
    }
  }),

  on(GetBuildingSuccess, (state, {buildings}) => {
    const devicesMap: Map<number, ModulePro> = new Map(state.modulesPro.map(module => {
      return [module.id, module];
    }));

    buildings.forEach(building => {
      if (building.devices) {
        building.devices.forEach(device => {
          devicesMap.set(device.id, {
            ...devicesMap.get(device.id),
            ...device,
            buildingId: building.id,
            error: device.error
          });
        })
      }
    })

    return {
      ...state,
      modulesPro: [...devicesMap.values()]
    }
  }),

  on(CleanModulesProRemoved, (state) => {
    // filter the modules which we don't have access to
    return {
      ...state,
      modulesPro: state.modulesPro.filter(mod => !(mod.error?.message === 'NoDeviceAccess'))
    }
  }),

  on(DeleteModuleSuccess, (state, action) => {
    return {
      ...state,
      modulesPro: state.modulesPro.filter(m => m.id !== action.id)
    };
  }),

  on(DeleteGroupSuccess, (state, action) => {
    /**
     * When we delete a group we need to change the group Id and Name of the devices
     * that were on the deleted group, to the 'no group'
     */
    return {
      ...state,
      modulesPro: state.modulesPro.map(m => {
        if (m.groupId === action.id) {
          return {
            ...m,
            groupId: -1,
          };
        } else {
          return m;
        }
      }),
    };
  }),

  on(UpdateModuleGroupSuccess, (state, action) => {
    /**
     * When we update a module group we need to change it's groupId and
     * groupName using it's new group
     */
    return {
      ...state,
      modulesPro: state.modulesPro.map(module => {
        if (module.id === action.module.id) {
          module = {
            ...module,
            groupId: action.group.id,
            groupName: action.group.name,
          };
        }

        return module;
      })
    };
  }),

  on(CreateBuildingDeviceSuccess, (state, action) => {
    return {
      ...state,
      modulesPro: [
        ...state.modulesPro,
        {
          ...action.device,
          buildingId: action.buildingId,
          groupId: action.device.group ? action.device.group.id : -1 ,
          groupName: action.device.group ? action.device.group.name : null,
        }
      ],
    };
  }),

  on(DeleteBuildingSuccess, (state, action) => {
    return {
      ...state,
      modulesPro: state.modulesPro.filter(m => m.buildingId !== action.buildingId),
    };
  }),

  on(GetQRCodeLiveDataSuccess, (state, action) => {
    const { data } = action;
    return {
      ...state,
      modulesPro: [...state.modulesPro, {...data.dashboard_data, name: data.station_name, device_id: data.id}]
    }
  })

);

