import { Injectable, InjectionToken } from '@angular/core';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ConnectableObservable, Observable } from 'rxjs';
import { map, publishReplay, take, tap } from 'rxjs/operators';
import * as HomesActions from './homes.action';
import { AckLocalResourcesPayload, AutoTempModes, CoolingModes, GetHomeInfoPayload, LoadComparisonDataPayload, LoadForecastPayload, PlaceUpdate, SetHomeConfigPayload, SetStatePayload, TemperatureControlModes, ThermModes } from './homes.interface';
import * as HomesSelectors from './homes.selector';

export const HOMES_FACADE = new InjectionToken('HOMES_FACADE');

/**
 * Has to be extended by a specific facade for each project
 * Extending facade must have {providedIn: 'root'} in its Injectable decorator
 */
@Injectable()
export class HomesFacade {
  homes$ = this.store$.pipe(select(HomesSelectors.getHomes));
  currentHome$ = this.store$.pipe(select(HomesSelectors.getCurrentHome));
  currentHomeId$ = this.store$.pipe(select(HomesSelectors.getCurrentHomeId));
  currentHomePersons$ = this.store$.pipe(select(HomesSelectors.getCurrentHomePersons));
  isLoadingHomes$ = this.store$.pipe(select(HomesSelectors.isLoadingHomes));
  errorModalDisplay$ = this.store$.pipe(select(HomesSelectors.getErrorModalDisplay));
  homeUsers$ = this.store$.pipe(select(HomesSelectors.getHomeUsers));
  adminAccessCode$ = this.store$.pipe(select(HomesSelectors.getAdminAccessCode));
  homeTimezone$ = this.store$.pipe(select(HomesSelectors.getHomeTimezone));
  homeCoordinates$ = this.store$.pipe(select(HomesSelectors.getHomeCoordinates));
  homeAltitude$ = this.store$.pipe(select(HomesSelectors.getHomeAltitude));
  homeAddress$ = this.store$.pipe(select(HomesSelectors.getHomeAddress));
  homeName$ = this.store$.pipe(select(HomesSelectors.getHomeName));
  currentHomeCountry$ = this.store$.pipe(select(HomesSelectors.getCurrentHomeCountry));
  isFrance$ = this.store$.pipe(select(HomesSelectors.isFrance));
  electricitySchedule$ = this.store$.pipe(select(HomesSelectors.electricitySchedule));
  isSupportedCountry$ = this.store$.pipe(select(HomesSelectors.isSupportedCountry));
  currencySymbol$ = this.store$.pipe(select(HomesSelectors.currencySymbol));
  currentHomeHasNDL$ = this.store$.pipe(select(HomesSelectors.currentHomeHasNDL));

  hasDefaultElectricitySchedule$ = this.store$.pipe(select(HomesSelectors.hasDefaultElectricitySchedule));
  supportElectricitySchedule$ = this.store$.pipe(select(HomesSelectors.supportElectricitySchedule));
  electricityPowerThreshold$ = this.store$.pipe(select(HomesSelectors.electricityPowerThreshold));
  electricityPowerUnit$ = this.store$.pipe(select(HomesSelectors.electricityPowerUnit));
  defaultElectricityContractId$ = this.store$.pipe(select(HomesSelectors.defaultElectricityContractId));
  hasElectricitySchedules$ = this.store$.pipe(select(HomesSelectors.hasElectricitySchedules));
  productionElectricitySchedule$ = this.store$.pipe(select(HomesSelectors.productionElectricitySchedule));

  homesSuccess$ = this.actionOfType<HomesActions.GetHomesSuccess>(HomesActions.EnumHomesActions.GetHomesSuccess);
  homesStatusSuccess$ = this.actionOfType<HomesActions.GetHomeStatusSuccess>(HomesActions.EnumHomesActions.GetHomeStatusSuccess);
  homeSelected$ = this.actionOfType<HomesActions.SelectHome>(HomesActions.EnumHomesActions.HomeSelected);
  currentStatusLoaded$ = this.store$.pipe(select(HomesSelectors.currentStatusLoaded));
  currentConfigLoaded$ = this.store$.pipe(select(HomesSelectors.currentConfigLoaded));
  hasBNSE$ = this.store$.pipe(select(HomesSelectors.hasBNSE));

  homeType$ = this.store$.pipe(select(HomesSelectors.getHomeType));

  heatingSchedules$ = this.store$.pipe(select(HomesSelectors.heatingSchedules));
  coolingSchedules$ = this.store$.pipe(select(HomesSelectors.coolingSchedules));
  autoSchedules$ = this.store$.pipe(select(HomesSelectors.autoSchedules));

  temperatureControlMode$ = this.store$.pipe(select(HomesSelectors.temperatureControlMode));

  thermMode$ = this.store$.pipe(select(HomesSelectors.thermMode));
  thermModeEndTime$ = this.store$.pipe(select(HomesSelectors.thermModeEndTime));

  coolingMode$ = this.store$.pipe(select(HomesSelectors.coolingMode));
  coolingModeEndTime$ = this.store$.pipe(select(HomesSelectors.coolingModeEndTime));

  autoTempMode$ = this.store$.pipe(select(HomesSelectors.autoTempMode));
  autoModeEndTime$ = this.store$.pipe(select(HomesSelectors.autoModeEndTime));

  currentModeEndTime$ = this.store$.pipe(select(HomesSelectors.currentModeEndTime));

  thermEndDate$ = this.store$.pipe(select(HomesSelectors.thermEndDate));
  supportsProduction$ = this.store$.pipe(select(HomesSelectors.supportsProduction));
  supportsOverproduction$ = this.store$.pipe(select(HomesSelectors.supportsOverproduction));

  hgTemperature$ = this.store$.pipe(select(HomesSelectors.hgTemperature));

  homeThermostats$ = this.store$.pipe(select(HomesSelectors.homeThermostats));
  supportsCooling$ = this.store$.pipe(select(HomesSelectors.supportsCooling));
  hasThermostat$ = this.store$.pipe(select(HomesSelectors.hasThermostat));

  homeThermRelays$ = this.store$.pipe(select(HomesSelectors.homeThermRelays));

  hasRoomControlledByUnkownThermRelay$ = this.store$.pipe(select(HomesSelectors.hasRoomControlledByUnkownThermRelay));
  
  constructor(
    protected store$: Store<SettingsState>,
    protected actions$: Actions<HomesActions.HomesActions>,
  ) { }

  loadHomes(params: any = {}): Observable<Action> {
      this.store$.dispatch(new HomesActions.GetHomes(params));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetHomesSuccess),
          take(1)
      );
  }

  /**
   * Loads data of the current home
   * @param id ID of current home from url
   * @param params Parameters of the request
   * @returns Observable on action of type GetHomesSuccess when it is fired
   */
  loadHome(id: string, params: {}): Observable<Action> {
      this.store$.dispatch(new HomesActions.GetHomes(params));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetHomesSuccess),
          tap(() => this.selectHome(id)),
          take(1)
      );
  }

  selectHome(id: string) {
      this.store$.dispatch(new HomesActions.SelectHome(id));
  }

  /**
   * Loads status of current home modules
   * @param id ID of current home
   * @returns Observable on action of type GetHomesStatusSuccess when it is fired
   */
  loadHomeStatus(params: GetHomeInfoPayload): Observable<HomesActions.GetHomeStatusSuccess> {
      this.store$.dispatch(new HomesActions.GetHomeStatus(params));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetHomeStatusSuccess),
          take(1)
      );
  }

  getReadonlyDeviceState() {
      this.store$.dispatch(new HomesActions.GetReadonlyDeviceState());

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetReadonlyDeviceStateSuccess),
          take(1)
      );
  }

  /**
   * Loads config of current home modules
   * @param id ID of current home
   * @returns Observable on action of type GetHomesConfigSuccess when it is fired
   */
  loadHomeConfig(params: GetHomeInfoPayload): Observable<Action> {
      this.store$.dispatch(new HomesActions.GetHomeConfig(params));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetHomeConfigSuccess),
          take(1)
      );
  }

  /**
   * Sets config of current home modules
   * @param homeId ID of home we want to set the modules of
   * @param config New config of modules
   * @returns Observable on action of type SetConfigSuccess when it is fired
   */
  setConfig(homeId: string, config: {}): Observable<Action> {
    const params = {
        home_id: homeId,
        home: config
    } as SetHomeConfigPayload;

    this.store$.dispatch(new HomesActions.SetConfig(params));
    return this.actions$.pipe(
        ofType(HomesActions.EnumHomesActions.SetConfigSuccess),
      take(1)
    );
  }
  /**
   * Sets config of current home modules
   * @param id ID of home we want to set the modules of
   * @param config New config of modules
   * @returns Observable on action of type SetConfigSuccess when it is fired
   */
  setState(params: {id: string, modules?: {}, rooms?: {}}, header?:  { [key: string]: string }): Observable<HomesActions.HomesActions> {
      const setStatePayload = { home: params } as SetStatePayload;
      this.store$.dispatch(new HomesActions.SetState(setStatePayload, header));
      return this.actions$.pipe(
          ofType(
            HomesActions.EnumHomesActions.SetStateSuccess,
            HomesActions.EnumHomesActions.SetStateErrors,
            HomesActions.EnumHomesActions.SetStateFailure,
          ),
          map(action => {
            if ((action as any)?.payload?.error?.code === '7') {
              return action;
            }
            if (action.type === HomesActions.EnumHomesActions.SetStateErrors) {
              throw action;
            } else {
              return action;
            }
          } ),
          take(1)
      );
  }

  /**
   * Ack for local resources in case of error 31
   * @param params parameters about room or module to fix local resources
   */
  ackLocalResources(params: {home_id: string, device_id: string, modules?: {}, rooms?: {}}, header?:  { [key: string]: string }) {
      const ackLocalResourcesPayload = params as AckLocalResourcesPayload;
      this.store$.dispatch(new HomesActions.AckLocalResources(ackLocalResourcesPayload, header));
      return this.actions$.pipe(
        ofType(
          HomesActions.EnumHomesActions.AckLocalResourcesSuccess,
          HomesActions.EnumHomesActions.AckLocalResourcesFailure
          ),
        take(1)
      );
  }

  /**
   * Updates the location and timezone of a place
   * @param placeUpdate Parameters of the request:
   * If lat and lng, will update location and timezone; if only timezone, will update only timezone
   */
  updatePlace(placeUpdate: PlaceUpdate): void {
      this.store$.dispatch(new HomesActions.UpdateHomePlace(placeUpdate));
  }

  /**
   * Loads users of home
   * @param homeId ID of current home
   * @returns Observable on action of type GetHomeUsersSuccess when it is fired
   */
  loadHomeUsers(homeId: string): Observable<Action> {
      this.store$.dispatch(new HomesActions.GetHomeUsers(homeId));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetHomeUsersSuccess),
          take(1)
      );
  }

  /**
   * Remove a user from the home
   * @param homeId ID of the home
   * @param userId ID of the user
   * @returns Observable on action of type RemoveUserFromHomeSuccess when it is fired
   */
  removeUserFromHome(homeId: string, userId: string): Observable<Action> {
      const payload = { homeId, userId };

      this.store$.dispatch(new HomesActions.RemoveUserFromHome(payload));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.RemoveUserFromHomeSuccess),
          take(1)
      );
  }

  /**
   * Generates a home admin access code
   * @param homeId ID of current home
   * @returns Observable on action of type GetAdminAccessCodeSuccess when it is fired
   */
  getAdminAccessCode(homeId: string): Observable<Action> {
      this.store$.dispatch(new HomesActions.GetAdminAccessCode(homeId));

      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.GetAdminAccessCodeSuccess),
          take(1)
      );
  }

  /**
   * Hides the error 400 modal
   */
  hideErrorModal(): void {
      this.store$.dispatch(new HomesActions.HideErrorModal());
  }

  getHomeNFA(params: {home_id: string, app_type: string}) {
      this.store$.dispatch(new HomesActions.GetHomeNFA(params));

      return this.actions$.pipe(
          ofType(
              HomesActions.EnumHomesActions.GetHomeNFASuccess,
              HomesActions.EnumHomesActions.GetHomeNFAFailure
          ),
          take(1),
          map( (action: HomesActions.GetHomeNFASuccess | HomesActions.GetHomeNFAFailure) => action.payload)
      );
  }

  /**
   * Set home type
   */
    setHomeType(params: {home: {id: string, home_type: string}}) {

      this.store$.dispatch(new HomesActions.SetHomeType(params));

      return this.actions$.pipe(
        ofType(HomesActions.EnumHomesActions.SetHomeTypeSuccess),
        take(1)
      );
    }

  loadForecast(payload: LoadForecastPayload): Observable<HomesActions.LoadForecastSuccess> {
    this.store$.dispatch(new HomesActions.LoadForecast(payload));

    return this.actions$.pipe(
      ofType(HomesActions.EnumHomesActions.LoadForecastSuccess),
      take(1),
    );
  }

  private actionOfType<T = Action>(action: string) {
    const obs$ = this.actions$.pipe(
      ofType(action),
      publishReplay(1),
    ) as ConnectableObservable<T>;
    obs$.subscribe();
    obs$.connect();
    return obs$;
  }

  removeDeviceFromHome(homeId: string, deviceId: string) {
      const params = {home_id: homeId, device_id: deviceId }
      this.store$.dispatch(new HomesActions.RemoveDeviceFromHome(params));
  }

  changeThermHomeMode(homeId: string, therm_mode: string, endtime?: number) {
      this.store$.dispatch(new HomesActions.SetThermMode(homeId, therm_mode, endtime));
      return this.actions$.pipe(
          ofType(HomesActions.EnumHomesActions.SetThermModeSuccess, HomesActions.EnumHomesActions.SetThermModeFailure),
          take(1)
        );
  }

  setThermPoint(homeId: string, room_id: string, mode: string, endtime?: number, temp?: number) {
    this.store$.dispatch(new HomesActions.SetThermPoint({home_id: homeId, room_id, mode, endtime, temp}));
    return this.actions$.pipe(
        ofType(HomesActions.EnumHomesActions.SetThermPointSuccess, HomesActions.EnumHomesActions.SetThermPointFailure),
        take(1)
      );
  }

  setHeatingMode(homeId: string, thermMode?: ThermModes, thermModeEndtime?: number) {
    this.store$.dispatch(new HomesActions.SetHeatingMode({
      home: {
        id: homeId,
        temperature_control_mode: TemperatureControlModes.HEATING,
        therm_mode: thermMode,
        therm_mode_endtime: thermModeEndtime,
      }
    }));

    return this.actions$.pipe(
      ofType(HomesActions.EnumHomesActions.SetHeatingModeSuccess, HomesActions.EnumHomesActions.SetHeatingModeFailure),
      take(1)
    );
  }

  setCoolingMode(homeId: string, cooling_mode?: CoolingModes, cooling_mode_endtime?: number) {
    this.store$.dispatch(new HomesActions.SetCoolingMode({
      home: {
        id: homeId,
        temperature_control_mode: TemperatureControlModes.COOLING,
        cooling_mode,
        cooling_mode_endtime,
      }
    }));

    return this.actions$.pipe(
      ofType(HomesActions.EnumHomesActions.SetCoolingModeSuccess, HomesActions.EnumHomesActions.SetCoolingModeFailure),
      take(1)
    );
  }

  setAutoMode(homeId: string, autoTempMode?: AutoTempModes, autoModeEndtime?: number ) {
    this.store$.dispatch(new HomesActions.SetAutoMode({
      home: {
        id: homeId,
        temperature_control_mode: TemperatureControlModes.AUTO,
        auto_temp_mode: autoTempMode,
        auto_mode_endtime: autoModeEndtime,
      }
    }));

    return this.actions$.pipe(
      ofType(HomesActions.EnumHomesActions.SetAutoModeSuccess, HomesActions.EnumHomesActions.SetAutoModeFailure),
      take(1)
    );
  }

  loadReportsComparisonData(payload: LoadComparisonDataPayload): Observable<HomesActions.LoadComparisonDataSuccess> {
    this.store$.dispatch(new HomesActions.LoadComparisonData(payload));

    return this.actions$.pipe(
      ofType(HomesActions.EnumHomesActions.LoadComparisonDataSuccess),
      take(1),
    );
  }

}
