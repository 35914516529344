import { Injectable } from '@angular/core';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';

@Injectable({
  providedIn: 'root'
})
export class ThresholdsService {

  constructor() { }

  getThresholdsMetadata(type: EnumMeasureTypes) {
    switch (type) {
      case EnumMeasureTypes.CO2:
        return {
          type: EnumMeasureTypes.CO2,
          label: 'station-app.__DASHBOARD_TITLE_CO2',
          icon: 'co-2',
          colors: ['var(--green-confirmation)', 'var(--yellow)', 'var(--red-alert)', 'var(--grey-30)'],
          highlightedColors: [null, 'var(--yellow-transparent)', 'var(--ruby-pink-transparent)', null],
          labels: ['hc-app.__HC_HEALTH_LEVEL_VERY_HEALTHY', 'common-app.__HIGH', 'station-app.__GAUGE_CITEAIR_5'],
          min: 400,
          max: 2000,
          step: 50,
        };
      case EnumMeasureTypes.TEMPERATURE:
        return {
          type: EnumMeasureTypes.TEMPERATURE,
          label: 'station-app.__PLOT_TITLE_TEMPERATURE',
          icon: 'temperature-32',
          colors: ['var(--arctic-blue)', 'var(--green-confirmation)', 'var(--red-alert)', 'var(--grey-30)'],
          highlightedColors: ['var(--arctic-blue-transparent)', null, 'var(--ruby-pink-transparent)', null],
          labels: ['hc-app.__HC_TEMP_1', 'iaq-app.__IAQ_COMFORTABLE', 'hc-app.__HC_TEMP_6'],
          min: 15,
          max: 31,
          step: .5,
        };
      case EnumMeasureTypes.HUMIDITY:
        return {
          type: EnumMeasureTypes.HUMIDITY,
          label: 'station-app.__DASHBOARD_TITLE_HUMIDITY',
          icon: 'water',
          colors: ['var(--yellow)', 'var(--green-confirmation)', 'var(--arctic-blue)', 'var(--grey-30)'],
          highlightedColors: ['var(--yellow-transparent)', null, 'var(--arctic-blue-transparent)', null],
          labels: ['hc-app.__HC_HUMIDITY_1', 'iaq-app.__IAQ_COMFORTABLE', 'iaq-app.__IAQ_HUMID'],
          min: 16,
          max: 80,
          step: 2,
        };
      case EnumMeasureTypes.NOISE:
        return {
          type: EnumMeasureTypes.NOISE,
          label: 'common-app.__NOISE',
          icon: 'sound',
          colors:  ['var(--green-confirmation)', 'var(--yellow)', 'var(--red-alert)', 'var(--grey-30)'],
          highlightedColors: [null, 'var(--yellow-transparent)', 'var(--ruby-pink-transparent)', null],
          labels: ['station-app.__DASHBOARD_SONO_HIGH_COMFORT', 'station-app.__DASHBOARD_SONO_MIDDLE_COMFORT', 'station-app.__COMFORT_SONO_TOO_HIGH'],
          min: 36,
          max: 100,
          step: 2,
        };
      default:
        throw new Error(`Threshold for type ${type} not implemented`);
    }
  }
}
