import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view.component';
import { ViewSidebarComponent } from './view-sidebar/view-sidebar.component';
import { ViewSidebarTitleComponent } from './view-sidebar/view-sidebar-title/view-sidebar-title.component';
import { ViewContentComponent } from './view-content/view-content.component';
import { TextModule } from '@library/ui/atoms/text/text.module';
import { ViewContentTitleComponent } from './view-content/view-content-title/view-content-title.component';
import { ViewSidebarSubTitleComponent } from './view-sidebar/view-sidebar-subtitle/view-sidebar-subtitle.component';

@NgModule({
  declarations: [
    ViewComponent,
    ViewSidebarComponent,
    ViewSidebarTitleComponent,
    ViewContentComponent,
    ViewContentTitleComponent,
    ViewSidebarSubTitleComponent,
  ],
  imports: [
    CommonModule,
    TextModule,
  ],
  exports: [
    ViewComponent,
    ViewSidebarComponent,
    ViewSidebarTitleComponent,
    ViewContentComponent,
    ViewContentTitleComponent,
    ViewSidebarSubTitleComponent
  ],
})
export class ViewModule { }
