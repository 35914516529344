import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModulePro } from '@store/modules-pro/modules-pro.interface';
import { Order } from 'src/app/pipes/order-by-key/order-by-key.pipe';

export type Category = {
  label: string,
  key: keyof ModulePro,
};

@Component({
  selector: 'app-dashboard-view-ordering',
  templateUrl: './dashboard-view-ordering.component.html',
  styleUrls: ['./dashboard-view-ordering.component.scss']
})
export class DashboardViewOrderingComponent {

  @Input() ordering: Order<ModulePro>;
  @Output() orderingChange = new EventEmitter<Order<ModulePro>>();

  categories: Category[] = [
    { label: 'iaq-app.__IAQ_SENSOR', key: 'name' },
    { label: 'iaq-app.__IAQ_GROUP', key: 'groupName' },
    { label: 'hc-app.__HC_AIR_QUALITY', key: 'health_idx' },
    { label: 'common-app.__CO2', key: 'CO2' },
    { label: 'common-settings.__DATA_TEMPERATURE', key: 'Temperature' },
    { label: 'common-settings.__DATA_HUMIDITY', key: 'Humidity' },
    { label: 'common-settings.__DATA_NOISE', key: 'Noise' },
  ];

  /**
   * Set the ordering for given key.
   * When the ordering is already based on the given key we have to flip
   * the direction of the ordering.
   * Otherwise we need to set the ordering to the default value for the given key ('asc')
   */
  selectOrdering(key: Order<ModulePro>['key']) {
    if (this.ordering.key === key) {
      this.orderingChange.emit({
        key,
        dir: this.ordering.dir == 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.orderingChange.emit({
        key,
        dir: 'asc',
      });
    }
  }

}
