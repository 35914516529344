import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { currentBuildingId } from '@store/buildings/buildings.selectors'
import { ModulesProState } from '@store/modules-pro/modules-pro.interface';
import { Group } from './groups.interface';

const getGroups = (state: AppState) => state.groups.groups;
const buildingState = (state: AppState) => state.buildings;
const modulesState = (state: AppState) => state.modulesPro;

export const buildingGroups = createSelector(
  getGroups,
  buildingState,
  (groups, buildings) => {
    return groups.filter(group => group.buildingId === buildings.currentBuildingId)
  }
);

export const groupDevices = (group: Group) => createSelector(
  (state: AppState) => state.modulesPro,
  (modulesProState: ModulesProState) => {
    return group.devices.map(deviceId => modulesProState.modulesPro.find(mod => mod.id === deviceId));
  }
);

export const getGroup = (id: Group['id']) => createSelector(
  getGroups,
  (groups) => {
    return groups.find(group => group.id === id);
  }
);


export const getGroupDevice = (id: Group['id']) => createSelector(
  modulesState,
  getGroups,
  currentBuildingId,
  (modulesProState: ModulesProState, groups: Group [], buildingId) => {
    // if don't have an id that means we need to return "no group" devices
    if (id)
    {
      return groups.find(group => group.id === id && buildingId === group.buildingId )?.devices[0]
    } else {
      // id is null so we find a device any device of building
      return modulesProState.modulesPro.find(dev => dev.buildingId ===  buildingId).id
    }
  }
);

