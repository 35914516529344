import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpUnauthorizedInterceptor } from 'src/app/router/unauthorized-interceptor.service';
import { MeasuresService } from '@library/store/measures/measures.service';
import { NHCMeasuresService } from 'src/app/services/nhc-measures.service';
import { AcceptInvitationComponent } from '@pages/accept-invitation/accept-invitation.component';
import { PostAuthorizeViewComponent } from '@pages/post-authorize-view/post-authorize-view.component';
import { RefreshAuthorizeViewComponent } from '@pages/refresh-authorize-view/refresh-authorize-view.component';
import { MainPageComponent } from './main-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LangModule } from '@library/store/lang/lang.module';
import { UIModule } from '@library/ui/ui.module';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { SidebarModule } from '@organisms/sidebar/sidebar.module';
import { DashboardViewModule } from '@pages/dashboard-view/dashboard-view.module';
import { StateModule } from '@store/state.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { InvitationExpiredComponent } from 'src/app/invitation-expired/invitation-expired.component'
import { ReportDataService } from 'src/app/services/report-data.service'
import { NoAccessViewComponent } from '@pages/no-access-view/no-access-view.component'
import { BehaviorSubject } from 'rxjs'
import { HomesFacade } from '@library/store/homes/homes.facade'
import { NoBuildingViewComponent } from '@pages/no-building-view/no-building-view.component'

@Injectable()
class NHCHomeFacade {
  homes$ = new BehaviorSubject([])
}

@NgModule({
  declarations: [
    RefreshAuthorizeViewComponent,
    PostAuthorizeViewComponent,
    AcceptInvitationComponent,
    NoBuildingViewComponent,
    MainPageComponent,
    InvitationExpiredComponent,
    NoAccessViewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StateModule,
    HttpClientModule,
    DashboardViewModule,
    SidebarModule,
    UIModule,
    LangModule,
    PipesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpUnauthorizedInterceptor,
      multi: true
    },
    {
      provide: MeasuresService,
      useClass: NHCMeasuresService
    },
    {
      provide: HomesFacade,
      useClass: NHCHomeFacade
    },
    ReportDataService,
  ]
})
export class MainPageModule { }
