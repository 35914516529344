import { environment } from "@environments/environment";
import { EnumMeasureTypes, Measure, ModuleMeasuresSets } from "@library/store/measures/measures.interface";
import { Thresholds } from "@store/buildings/buildings.interface";
import { ModulePro } from "@store/modules-pro/modules-pro.interface";
import { measureDataOrEmpty, QRCode, QRCodeFromAPI, QrCodeLiveDataFromAPI, QrCodeMeasuresForDisplay, QrCodePublicDataForDisplay, QrCodeQuery, QR_CODES_DOWNLOAD_PDF_URL, QR_CODES_URL_PATH } from "./qr-codes.interface";



export function formatQrCodeForDisplay(qrCode: QRCodeFromAPI): QRCode {
  const { device } = qrCode;
  return {
    ...qrCode,
    device_id: device.device_id, // MAC Address
    moduleId: device.id,
    deviceName: device.name,
    url: `${environment.appUrl}${QR_CODES_URL_PATH}${qrCode.building.id}/${qrCode.uid}`,
    pdfUrl: environment.appUrl + QR_CODES_DOWNLOAD_PDF_URL,
  }
};

/**
 * Takes a module and uses its data to create an inactive qrCode.
 *
 * @export
 * @param {ModulePro} module
 * @return {*}  {QRCode}
 */
export function formatInactiveQrCodeFromModule(module: ModulePro): QRCode {
  return {
    isEnabled: false,
    deviceName: module.name,
    device_id: module.device_id,
    moduleId: module.id
  }
};

export function formatLiveDataForDisplay(data: QrCodeLiveDataFromAPI): QrCodePublicDataForDisplay {
  const hourAndUnit = formatHours(data.dashboard_data.time_utc * 1000, data.timezone)
  return {
    name: data.station_name,
    co2: data.dashboard_data.CO2,
    temperature: data.dashboard_data.Temperature,
    humidity: data.dashboard_data.Humidity,
    noise: data.dashboard_data.Noise,
    timezone: data.timezone,
    thresholds: data.thresholds,
    health_idx: data.dashboard_data.health_idx,
    hour: hourAndUnit[0],
    hourUnit: hourAndUnit[1],
    date: data.dashboard_data.time_utc
  }
};
export function formatModuleDataInStoreForDisplay(data: ModulePro, hourAndUnit: string[]): QrCodePublicDataForDisplay {
  return {
    name: data.name,
    co2: data.CO2,
    temperature: data.Temperature,
    humidity: data.Humidity,
    noise: data.Noise,
    health_idx: data.health_idx,
    hour: hourAndUnit[0],
    hourUnit: hourAndUnit[1],
    date: data.time_utc
  }
};

/**
 * Convert the measures in store of type ModuleMeasuresSets to an array of formatted measures of type QrCodeMeasuresForDisplay
 *
 * @param {ModuleMeasuresSets} measures
 * @param {string} moduleName
 * @param {QrCodeQuery} queryParams
 * @return {*}  {QrCodeMeasuresForDisplay}
 */
export function formatMeasuresOfStoreForDisplay(measures: ModuleMeasuresSets, module: ModulePro, queryParams: QrCodeQuery, thresholds: Thresholds): QrCodeMeasuresForDisplay {
  return Object.entries(measures).reduce((acc, curr: [EnumMeasureTypes, Measure[]]) => {
    const [measureType, measureValues] = [curr[0], curr[1]]
    if (queryParams.type.includes(measureType as EnumMeasureTypes)) {
      measureValues[queryParams.scale].map((measure: Measure, idx: number, values: Measure[]) => {
        const measuresAtDateX = {
          date: measure.x,
          name: module.name,
          [measureType]: measure.y ?? '—'
        }
        if (measureType === EnumMeasureTypes.CO2) {
          idx === values.length - 1 // Use health-idx from live data (last hour) or calculate it from thresholds
            ? measuresAtDateX.health_idx = module.health_idx
            : measuresAtDateX.health_idx = convertThresholdsToHealthIndex(measureType, measure.y, thresholds)
        }
        if (acc[idx]) {
          acc[idx][measureType] = measuresAtDateX[measureType]
          if ('health_idx' in measuresAtDateX) { acc[idx].health_idx = measuresAtDateX.health_idx };
        } else {
          acc.push(measuresAtDateX)
        }
      })
    }
    return acc
  }, [])
};

export function convertThresholdsToHealthIndex(type: EnumMeasureTypes, value: measureDataOrEmpty, thresholds: Thresholds): number {
  if ((typeof value === 'string') || (typeof value === 'number' && isNaN(value))) {
    return null
  }
  if (value <= thresholds[type].low) {
    return 0
  } else if (value < thresholds[type].high) {
    return 1
  }
  return 2
};

/**
  * Convert unix date to formatted hour :
  * '1667863800000' -> 0h, 1h, 2h, 3h, ... 14h, 15h etc with fr locale, 1am, 2am ... 3pm with us locale
  * @param {number} date
  * @param {number} idx
  * @return {string}
  */
export function formatHours(date: number, timeZone: string): string[] {
  // Converts to unix time in millisecond if needed
  date = date.toString().length === 10 ? date * 1000 : date

  // Translate hour converting it to locale and timezone.
  const translatedHour = new Intl.DateTimeFormat(navigator.language, {timeZone, hour:'numeric'}).format(date).toUpperCase()
  // Remove leading zero and returns a split string: '07 h' -> '[7,h]'
  const splitHour = translatedHour.replace(/^0{1}/g, '').split(' ')
  return splitHour
}

/**
 * If there is no historical data (device disconnected for a few hours),
 * replace empty fields with '-' for display purposes and add device name.
 * @param {ModulePro} moduleWithLiveData
 * @param {QrCodePublicDataForDisplay} qrCodeData
 * @return {*}
 */
export function replaceEmptyValuesAndAddName(moduleWithLiveData: ModulePro, qrCodeData: QrCodePublicDataForDisplay): QrCodePublicDataForDisplay {
  Object.entries(qrCodeData).map(([key, value]: [string, number]) => {
    if(isNaN(value)) {
      qrCodeData[key] = '—'
    }
    qrCodeData.name = moduleWithLiveData.name
  })
  return qrCodeData;
}
