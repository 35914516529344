import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-hover-text',
  templateUrl: './hover-text.component.html',
  styleUrls: ['./hover-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoverTextComponent {

  constructor() { }

}
