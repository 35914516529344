import { Injectable, InjectionToken } from '@angular/core';
import { SettingsState } from '@library/utils/interfaces/settings-state.interface';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ConnectableObservable, of } from 'rxjs';
import { publishReplay, take } from 'rxjs/operators';
import { EnumModulesActions, GetModulesSuccess, RemoveModuleFromHome, SelectModuleAction, UpdateModuleName, UpdateModuleNameFailure, UpdateModuleNameSuccess, UpdateModuleRoom, UpdateModuleRoomFailure, UpdateModuleRoomSuccess, UpdateModulesStatus } from './modules.action';
import { Module, ModuleNameUpdate, ModuleRoomUpdate, RemoveModulePayload } from './modules.interface';
import * as ModulesSelectors from './modules.selector';

export const MODULES_FACADE = new InjectionToken('MODULES_FACADE');

/**
 * Has to be extended by a specific facade for each project
 * Extending facade must have {providedIn: 'root'} in its Injectable decorator
 */
@Injectable({providedIn: 'root'})
export class ModulesFacade {
  modules$ = this.store$.pipe(select(ModulesSelectors.getModules));
  currentHomeModules$ = this.store$.pipe(select(ModulesSelectors.getCurrentHomeModules));
  currentModule$ = this.store$.pipe(select(ModulesSelectors.getCurrentModule));
  liveAppliances$ = this.store$.select(ModulesSelectors.getLiveAppliances);
  appliances$ = this.store$.select(ModulesSelectors.getGraphsAppliances);
  powerLines$ = this.store$.select(ModulesSelectors.getPowerLines);
  phases$ = this.store$.select(ModulesSelectors.getPhases);

  modulesSuccess$ = this.actionOfType<GetModulesSuccess>(EnumModulesActions.GetModulesSuccess);

  currentHomeBNMH$ = this.store$.select(ModulesSelectors.currentHomeBNMH);
  currentHomeBtGateway$ = this.store$.select(ModulesSelectors.currentHomeBtGateway)

  getCurrentHomeHeatingReportGateways$ = this.store$.pipe(select(ModulesSelectors.getCurrentHomeReportGateways));

  constructor(
    protected store$: Store<SettingsState>,
    protected actions$: Actions
  ) { }

  getModuleById(moduleId: string) {
    return this.store$.pipe(select(ModulesSelectors.getModuleById(moduleId)));
  }

  getModulesById(ids: string[]) {
    return this.store$.pipe(select(ModulesSelectors.getModulesById(ids)));
  }

  getModulesByType(types: string[]) {
    return this.store$.pipe(select(ModulesSelectors.getModulesByType(types)));
  }

  selectModule(moduleId: string) {
    this.store$.dispatch(new SelectModuleAction(moduleId));
    return of(true);
  }

  removeModuleFromHome(params: RemoveModulePayload) {
    this.store$.dispatch(new RemoveModuleFromHome(params));

    return this.actions$.pipe(
      ofType(EnumModulesActions.RemoveModuleFromHomeSuccess),
      take(1)
    );
  }

  assignModuleToRoom(params: ModuleRoomUpdate, header?:{ [key: string]: string }) {
    this.store$.dispatch(new UpdateModuleRoom(params, header));

    return this.actions$.pipe(
      ofType<UpdateModuleRoomSuccess | UpdateModuleRoomFailure>
      (EnumModulesActions.UpdateModuleRoomSuccess, EnumModulesActions.UpdateModuleRoomFailure),
      take(1)
    );
  }

  updateModuleName(params: ModuleNameUpdate, header?:{ [key: string]: string }) {
    this.store$.dispatch(new UpdateModuleName(params, header));

    return this.actions$.pipe(
      ofType<UpdateModuleNameSuccess | UpdateModuleNameFailure>
        (EnumModulesActions.UpdateModuleNameSuccess, EnumModulesActions.UpdateModuleNameFailure),
      take(1)
    );
  }

  updateModulesStatus(homeId: string, modules: Module[]) {
    this.store$.dispatch(new UpdateModulesStatus({id: homeId, modules}));
  }

  private actionOfType<T = Action>(action: string) {
    const obs$ = this.actions$.pipe(
      ofType(action),
      publishReplay(1),
    ) as ConnectableObservable<T>;
    obs$.subscribe();
    obs$.connect();
    return obs$;
  }

  getCurrentHomeModulesByRoomId(roomId: string) {
    return this.store$.pipe(select(ModulesSelectors.getCurrentHomeModulesByRoomId(roomId)));
  }


}
