import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@library/utils/services/local-storage.service';
import { GetBuildingsFailure } from '@store/buildings/buildings.actions';
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { Building } from '@store/buildings/buildings.interface';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  constructor(
    public buildingsFacade: BuildingsFacade,
    public localStorage: LocalStorageService,
    public router: Router
  ) { }

  async ngOnInit() {
    /**
     * Here we fetch the buildings associated to the user,
     * Once we retrieved the buildings we can select the current building from the
     * localstorage or we take the first one in the list
     * If the user has no building we redirect the user to the '/installation/summary' page
     */
    const action = await firstValueFrom(this.buildingsFacade.loadBuildings());

    if (action.type === GetBuildingsFailure.type) {
      return;
    }

    const buildings = await firstValueFrom(this.buildingsFacade.buildings$);

    if (!location.pathname.includes('installation') && !location.pathname.includes('/download-report') ) {
      if (buildings && buildings.length > 0) {
        this.selectBuilding(buildings);
      } else {
        // Launch installation flow if user has no building
        this.router.navigateByUrl('/installation/summary');
      }
    }
  }

  selectBuilding(buildings: Building[]) {
    const selectedBuildingId = parseInt(this.localStorage.getItem('selectedBuilding'), 10);
    const building = buildings.find(h => h.id === selectedBuildingId) ?? buildings[0];

    this.buildingsFacade.selectBuilding(building.id);

    // #TODO check if user is admin of the building
    // Redirect to installation if user has one building and no device
    if (buildings && buildings.length === 1 && building.devices.length === 0) {
      this.router.navigate(['/installation/device-summary']);
    }
  }

}
