import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state'

const buildingsState = (state: AppState) => state.buildings;

export const buildings = createSelector(
  buildingsState,
  state => state.buildings,
);

export const currentBuilding = createSelector(
  buildingsState,
  state => state.buildings ? state.buildings .find(building => building.id === state.currentBuildingId) : null
);

export const currentBuildingId = createSelector(
  buildingsState,
  state => state.currentBuildingId
);

export const currentTimezone = createSelector(
  buildingsState,
  state =>  state.buildings ? state.buildings.find(building => building.id === state.currentBuildingId)?.timezone : null
);

export const currentBuildingAdminId = createSelector(
  currentBuilding,
  building =>  building.admins[0].id // we always have only 1 admin
);

