export enum EnumScales {
  YEAR = 'year',
  YEAR_WEEKLY = 'year_weekly',
  YEAR_MONTHLY = 'year_monthly',

  MONTH_WEEKLY = 'month_weekly',
  MONTH_DAILY = 'month_daily',

  WEEK = 'week',
  WEEK_DAILY = 'week_daily',

  DAY = 'day',
  DAY_HOURLY = 'day_hourly',
}

export interface Measure {
  x: number;
  y: number;
}

export enum EnumApiScales {
  ONE_MONTH = '1month',
  ONE_WEEK = '1week',
  ONE_DAY = '1day',
  THREE_HOURS = '3hours',
  ONE_HOUR = '1hour',
  FIVE_MINUTES = '5min',
}

export type Measures = Measure[];

export enum EnumApiMeasureTypes {
  // ELEC
  // No Contract
  SUM_ENERGY_ELEC = 'sum_energy_elec',
  SUM_ENERGY_PRICE = 'sum_energy_price',

  // Base contract
  SUM_ENERGY_ELEC$0 = 'sum_energy_elec$0',
  SUM_ENERGY_PRICE$0 = 'sum_energy_price$0',

  // Peak
  SUM_ENERGY_ELEC$1 = 'sum_energy_elec$1',
  SUM_ENERGY_PRICE$1 = 'sum_energy_price$1',

  // Off Peak
  SUM_ENERGY_ELEC$2 = 'sum_energy_elec$2',
  SUM_ENERGY_PRICE$2 = 'sum_energy_price$2',

  SUM_ENERGY_BUY_FROM_GRID = 'sum_energy_buy_from_grid',
  SUM_ENERGY_BUY_FROM_GRID_PRICE = 'sum_energy_buy_from_grid_price',

  SUM_ENERGY_BUY_FROM_GRID$0 = 'sum_energy_buy_from_grid$0',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$0 = 'sum_energy_buy_from_grid_price$0',

  SUM_ENERGY_BUY_FROM_GRID$1 = 'sum_energy_buy_from_grid$1',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$1 = 'sum_energy_buy_from_grid_price$1',

  SUM_ENERGY_BUY_FROM_GRID$2 = 'sum_energy_buy_from_grid$2',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$2 = 'sum_energy_buy_from_grid_price$2',

  SUM_ENERGY_BUY_FROM_GRID$3 = 'sum_energy_buy_from_grid$3',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$3 = 'sum_energy_buy_from_grid_price$3',

  SUM_ENERGY_BUY_FROM_GRID$4 = 'sum_energy_buy_from_grid$4',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$4 = 'sum_energy_buy_from_grid_price$4',

  SUM_ENERGY_BUY_FROM_GRID$5 = 'sum_energy_buy_from_grid$5',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$5 = 'sum_energy_buy_from_grid_price$5',

  SUM_ENERGY_BUY_FROM_GRID$6 = 'sum_energy_buy_from_grid$6',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$6 = 'sum_energy_buy_from_grid_price$6',

  SUM_ENERGY_BUY_FROM_GRID$7 = 'sum_energy_buy_from_grid$7',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$7 = 'sum_energy_buy_from_grid_price$7',

  SUM_ENERGY_BUY_FROM_GRID$8 = 'sum_energy_buy_from_grid$8',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$8 = 'sum_energy_buy_from_grid_price$8',

  SUM_ENERGY_BUY_FROM_GRID$9 = 'sum_energy_buy_from_grid$9',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$9 = 'sum_energy_buy_from_grid_price$9',

  SUM_ENERGY_BUY_FROM_GRID$10 = 'sum_energy_buy_from_grid$10',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$10 = 'sum_energy_buy_from_grid_price$10',

  SUM_ENERGY_BUY_FROM_GRID$11 = 'sum_energy_buy_from_grid$11',
  SUM_ENERGY_BUY_FROM_GRID_PRICE$11 = 'sum_energy_buy_from_grid_price$11',

  SUM_ENERGY_RESELL_TO_GRID = 'sum_energy_resell_to_grid',
  SUM_ENERGY_RESELL_TO_GRID_PRICE = 'sum_energy_resell_to_grid_price',
  SUM_ENERGY_SELF_CONSUMPTION = 'sum_energy_self_consumption',

  POWER_PROD_ELEC = 'power_prod_elec',

  // FLUID: WATER & GAS
  SUM_FLUID_CONSUMPTION = 'sum_fluid_consumption$0',
  SUM_FLUID_PRICE = 'sum_fluid_price$0',

  // WEATHER
  PRESSURE = 'pressure',

  SUM_RAIN = 'sum_rain',
  PROB_RAIN = 'prob_rain',

  WIND_STRENGTH = 'WindStrength',
  GUST_STRENGTH = 'GustStrength',
  WIND_ANGLE = 'WindAngle',
  GUST_ANGLE = 'GustAngle',

  TEMPERATURE = 'temperature',
  MIN_TEMPERATURE = 'min_temp',
  MAX_TEMPERATURE = 'max_temp',

  HUMIDITY = 'humidity',
  MIN_HUMIDITY = 'min_hum',
  MAX_HUMIDITY = 'max_hum',

  CO2 = 'co2',
  MIN_CO2 = 'min_co2',
  MAX_CO2 = 'max_co2',

  NOISE = 'noise',
  MIN_NOISE = 'min_noise',
  MAX_NOISE = 'max_noise',

  SP_TEMPERATURE = 'Sp_Temperature',

  SUM_BOILER_ON = 'sum_boiler_on',
  SUM_BOILER_OFF = 'sum_boiler_off',

  HEATING_POWER_REQUEST = 'heating_power_request',
  
  MODE_DURATION_0 = 'mode_duration$0',
  MODE_DURATION_1 = 'mode_duration$1',

  COUNT_SP_TEMPERATURE_OFFSET_POSITIVE = 'count_sp_temperature_offset$0',
  COUNT_SP_TEMPERATURE_OFFSET_NEGATIVE = 'count_sp_temperature_offset$1',
  SP_TEMPERATURE_OFFSET_POSITIVE ='sp_temperature_offset$0',
  SP_TEMPERATURE_OFFSET_NEGATIVE ='sp_temperature_offset$1',

  TEMPERATURE_EXT = 'temperature_ext',

  SUM_HEATING_TIME = 'sum_heating_time',
}

export enum EnumMeasureTypes {
  SUM_ENERGY_ELEC_NO_CONTRACT = 'sum_energy_elec_no_contract',

  SUM_ENERGY_ELEC_BASE = 'sum_energy_elec_base',
  SUM_ENERGY_PRICE_BASE = 'sum_energy_price_base',

  SUM_ENERGY_ELEC_PEAK = 'sum_energy_elec_peak',
  SUM_ENERGY_PRICE_PEAK = 'sum_energy_price_peak',

  SUM_ENERGY_ELEC_OFFPEAK = 'sum_energy_elec_offpeak',
  SUM_ENERGY_PRICE_OFFPEAK = 'sum_energy_price_offpeak',

  SUM_FLUID_CONSUMPTION_COLD_WATER = 'sum_fluid_consumption_cold_water',
  SUM_FLUID_PRICE_COLD_WATER = 'sum_fluid_price_cold_water',

  SUM_FLUID_CONSUMPTION_HOT_WATER = 'sum_fluid_consumption_hot_water',
  SUM_FLUID_PRICE_HOT_WATER = 'sum_fluid_price_hot_water',

  SUM_FLUID_CONSUMPTION_GAS = 'sum_fluid_consumption_gas',
  SUM_FLUID_PRICE_GAS = 'sum_fluid_price_gas',

  SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT = 'sum_energy_buy_from_grid_no_contract',

  SUM_ENERGY_BUY_FROM_GRID_BASE = 'sum_energy_buy_from_grid_base',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE = 'sum_energy_buy_from_grid_price_base',

  SUM_ENERGY_BUY_FROM_GRID_PEAK = 'sum_energy_buy_from_grid_peak',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK = 'sum_energy_buy_from_grid_price_peak',

  SUM_ENERGY_BUY_FROM_GRID_OFFPEAK = 'sum_energy_buy_from_grid_offpeak',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK = 'sum_energy_buy_from_grid_price_offpeak',

  SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK = 'sum_energy_buy_from_grid_super_offpeak',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK = 'sum_energy_buy_from_grid_price_super_offpeak',

  SUM_ENERGY_BUY_FROM_GRID_WEEKEND = 'sum_energy_buy_from_grid_weekend',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND = 'sum_energy_buy_from_grid_price_weekend',

  SUM_ENERGY_BUY_FROM_GRID_FREE = 'sum_energy_buy_from_grid_free',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE = 'sum_energy_buy_from_grid_price_free',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM1 = 'sum_energy_buy_from_grid_custom1',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1 = 'sum_energy_buy_from_grid_price_custom1',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM2 = 'sum_energy_buy_from_grid_custom2',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2 = 'sum_energy_buy_from_grid_price_custom2',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM3 = 'sum_energy_buy_from_grid_custom3',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3 = 'sum_energy_buy_from_grid_price_custom3',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM4 = 'sum_energy_buy_from_grid_custom4',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4 = 'sum_energy_buy_from_grid_price_custom4',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM5 = 'sum_energy_buy_from_grid_custom5',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5 = 'sum_energy_buy_from_grid_price_custom5',

  SUM_ENERGY_BUY_FROM_GRID_CUSTOM6 = 'sum_energy_buy_from_grid_custom6',
  SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6 = 'sum_energy_buy_from_grid_price_custom6',

  SUM_ENERGY_RESELL_TO_GRID = 'sum_energy_resell_to_grid',
  SUM_ENERGY_RESELL_TO_GRID_PRICE = 'sum_energy_resell_to_grid_price',
  SUM_ENERGY_SELF_CONSUMPTION = 'sum_energy_self_consumption',

  POWER_PROD_ELEC = 'power_prod_elec',

  // WEATHER
  PRESSURE = 'pressure',
  SUM_RAIN = 'sum_rain',
  PROB_RAIN = 'prob_rain',

  WIND_STRENGTH = 'WindStrength',
  GUST_STRENGTH = 'GustStrength',
  WIND_ANGLE = 'WindAngle',
  GUST_ANGLE = 'GustAngle',

  TEMPERATURE = 'temperature',
  MIN_TEMPERATURE = 'min_temperature',
  MAX_TEMPERATURE = 'max_temperature',

  HUMIDITY = 'humidity',
  MIN_HUMIDITY = 'min_humidity',
  MAX_HUMIDITY = 'max_humidity',

  CO2 = 'co2',
  MIN_CO2 = 'min_co2',
  MAX_CO2 = 'max_co2',

  NOISE = 'noise',
  MIN_NOISE = 'min_noise',
  MAX_NOISE = 'max_noise',

  SP_TEMPERATURE = 'sp_temperature',

  SUM_BOILER_ON = 'sum_boiler_on',
  SUM_BOILER_OFF = 'sum_boiler_off',

  PERCENT_BOILER_ON = 'percent_boiler_on',

  HEATING_POWER_REQUEST = 'heating_power_request',

    
  MODE_DURATION_FG = 'mode_duration_fg',
  MODE_DURATION_AWAY = 'mode_duration_away',

  TEMPERATURE_EXT = 'temperature_ext',

  COUNT_SP_TEMPERATURE_OFFSET_POSITIVE = 'count_sp_temperature_offset_positive',
  COUNT_SP_TEMPERATURE_OFFSET_NEGATIVE = 'count_sp_temperature_offset_negative',

  SP_TEMPERATURE_OFFSET_POSITIVE = 'sp_temperature_offset_positive',
  SP_TEMPERATURE_OFFSET_NEGATIVE = 'sp_temperature_offset_negative',
  
  SUM_HEATING_TIME = 'sum_heating_time',

}

export const MeasureTypes = [
  EnumMeasureTypes.SUM_ENERGY_ELEC_NO_CONTRACT,
  EnumMeasureTypes.SUM_ENERGY_ELEC_BASE,
  EnumMeasureTypes.SUM_ENERGY_PRICE_BASE,
  EnumMeasureTypes.SUM_ENERGY_ELEC_PEAK,
  EnumMeasureTypes.SUM_ENERGY_PRICE_PEAK,
  EnumMeasureTypes.SUM_ENERGY_ELEC_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_PRICE_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_WEEKEND,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_FREE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM1,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM2,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM3,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM4,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM5,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM6,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6,
  EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID,
  EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID_PRICE,
  EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION,
  EnumMeasureTypes.SUM_FLUID_CONSUMPTION_COLD_WATER,
  EnumMeasureTypes.SUM_FLUID_PRICE_COLD_WATER,
  EnumMeasureTypes.SUM_FLUID_CONSUMPTION_HOT_WATER,
  EnumMeasureTypes.SUM_FLUID_PRICE_HOT_WATER,
  EnumMeasureTypes.SUM_FLUID_CONSUMPTION_GAS,
  EnumMeasureTypes.SUM_FLUID_PRICE_GAS,
  EnumMeasureTypes.PRESSURE,
  EnumMeasureTypes.SUM_RAIN,
  EnumMeasureTypes.WIND_STRENGTH,
  EnumMeasureTypes.GUST_STRENGTH,
  EnumMeasureTypes.WIND_ANGLE,
  EnumMeasureTypes.GUST_ANGLE,
  EnumMeasureTypes.TEMPERATURE,
  EnumMeasureTypes.MIN_TEMPERATURE,
  EnumMeasureTypes.MAX_TEMPERATURE,
  EnumMeasureTypes.HUMIDITY,
  EnumMeasureTypes.MIN_HUMIDITY,
  EnumMeasureTypes.MAX_HUMIDITY,
  EnumMeasureTypes.CO2,
  EnumMeasureTypes.MIN_CO2,
  EnumMeasureTypes.MAX_CO2,
  EnumMeasureTypes.NOISE,
  EnumMeasureTypes.MIN_NOISE,
  EnumMeasureTypes.MAX_NOISE,
  EnumMeasureTypes.SP_TEMPERATURE,
  EnumMeasureTypes.SUM_BOILER_ON,
  EnumMeasureTypes.SUM_BOILER_OFF,
  EnumMeasureTypes.PERCENT_BOILER_ON,
  EnumMeasureTypes.HEATING_POWER_REQUEST,
  EnumMeasureTypes.MODE_DURATION_FG,
  EnumMeasureTypes.MODE_DURATION_AWAY,
  EnumMeasureTypes.TEMPERATURE_EXT,
  EnumMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_POSITIVE,
  EnumMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_NEGATIVE,
  EnumMeasureTypes.SP_TEMPERATURE_OFFSET_POSITIVE,
  EnumMeasureTypes.SP_TEMPERATURE_OFFSET_NEGATIVE,
  EnumMeasureTypes.SUM_HEATING_TIME,
];

export const EnergyElectricityMeasureTypes = [
  EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_WEEKEND,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_FREE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM1,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM2,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM3,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM4,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM5,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM6,
]

export const PriceElectricityMeasureTypes = [
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5,
  EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6
]

export const ElectricityMeasureTypes = [
  ...EnergyElectricityMeasureTypes,
  ...PriceElectricityMeasureTypes,
]

export type MeasuresSet = Record<EnumApiScales, Measures>;
export type MeasuresSets = Record<EnumMeasureTypes, MeasuresSet>;

export interface ModuleMeasuresSets extends MeasuresSets {
  module: {
    id: string;
  };
}

export interface HomeMeasuresSets extends MeasuresSets {
  home: {
    id: string;
  };
}

export interface RoomMeasuresSets extends MeasuresSets {
  room: {
    id: string;
  };
}

export type MeasuresSetsScaled = Record<EnumMeasureTypes, Measures>;

export interface ModuleMeasuresSetsScaled extends MeasuresSetsScaled {
  module: {
    id: string;
  };
}

export interface HomeMeasuresSetsScaled extends MeasuresSetsScaled {
  home: {
    id: string;
  };
}

export interface RoomMeasuresSetsScaled extends MeasuresSetsScaled {
  room: {
    id: string;
  };
}

export interface MeasuresState {
  modules: ModuleMeasuresSets[];
  homes: Record<string, HomeMeasuresSets>;
  rooms: RoomMeasuresSets[];
  loadStatus: {
    homes: {id: string, scale: EnumApiScales}[],
    modules: {id: string, scale: EnumApiScales}[],
    rooms: {id: string, scale: EnumApiScales}[],
  },
}

export interface TimeRange {
  dateBegin: moment.Moment;
  dateEnd: moment.Moment;
}

export type MomentScaleDuration = 'month' | 'week' | 'day' | 'hour';
export type MomentScaleUnits = 'month' | 'isoWeek' | 'day' | 'hour';

export const MomentApiScalesDurations = new Map([
  [EnumApiScales.ONE_HOUR, 'hour'],
  [EnumApiScales.ONE_DAY, 'day'],
  [EnumApiScales.ONE_WEEK, 'week'],
  [EnumApiScales.ONE_MONTH, 'month'],
]) as Map<EnumApiScales, MomentScaleDuration>;

export const MomentScalesDurations = new Map([
  [EnumScales.DAY, 'hour'],
  [EnumScales.WEEK, 'day'],
  [EnumScales.MONTH_WEEKLY, 'week'],
  [EnumScales.MONTH_DAILY, 'day'],
  [EnumScales.YEAR, 'month'],
]) as Map<EnumScales, MomentScaleDuration>;

export const MomentApiScalesUnits = new Map([
    [EnumApiScales.ONE_HOUR, 'hour'],
    [EnumApiScales.ONE_DAY, 'day'],
    [EnumApiScales.ONE_WEEK, 'isoWeek'],
    [EnumApiScales.ONE_MONTH, 'month'],
]) as Map<EnumApiScales, MomentScaleUnits>;

export const MomentScalesUnits = new Map([
    [EnumScales.DAY, 'hour'],
    [EnumScales.WEEK, 'day'],
    [EnumScales.MONTH_WEEKLY, 'isoWeek'],
    [EnumScales.MONTH_DAILY, 'day'],
    [EnumScales.YEAR, 'month'],
]) as Map<EnumScales, MomentScaleUnits>;

export const ApiScales = new Map([
    [EnumScales.DAY, '1hour'],
    [EnumScales.WEEK, '1day'],
    [EnumScales.MONTH_WEEKLY, '1week'],
    [EnumScales.MONTH_DAILY, '1day'],
    [EnumScales.YEAR, '1month'],
]) as Map<EnumScales, EnumApiScales>;

export interface RawMeasuresBatch {
  beg_time: number;
  step_time: number;
  value: Array<number>[];
}

export interface RawModuleMeasures {
  id: string;
  measures: RawMeasuresBatch[];
  measureTypes?: EnumMeasureTypes[],
}

export const MomentTimeRangeScalesDurations = new Map([
  [EnumScales.DAY,            {unit: 'day',       amount: 1}],
  [EnumScales.WEEK,           {unit: 'isoWeek',      amount: 1}],
  [EnumScales.MONTH_WEEKLY,   {unit: 'isoWeek',   amount: 4}],
  [EnumScales.MONTH_DAILY,    {unit: 'month',     amount: 1}],
  [EnumScales.YEAR,           {unit: 'year',      amount: 1}],

  [EnumScales.DAY_HOURLY,     {unit: 'day',       amount: 1}],
  [EnumScales.WEEK_DAILY,     {unit: 'isoWeek',   amount: 1}],
  [EnumScales.MONTH_WEEKLY,   {unit: 'isoWeek',   amount: 4}],
  [EnumScales.MONTH_DAILY,    {unit: 'month',     amount: 1}],
  [EnumScales.YEAR_MONTHLY,   {unit: 'year',      amount: 1}],

]) as Map<EnumScales, {unit: moment.unitOfTime.DurationConstructor, amount: number}>;

export const MomentScaleSteps = new Map([
    [EnumScales.DAY,            {unit: 'hour',  amount: 1}],
    [EnumScales.WEEK,           {unit: 'day',   amount: 1}],
    [EnumScales.MONTH_WEEKLY,   {unit: 'week',  amount: 1}],
    [EnumScales.MONTH_DAILY,    {unit: 'day',   amount: 1}],
    [EnumScales.YEAR,           {unit: 'month', amount: 1}],
]) as Map<EnumScales, {unit: moment.unitOfTime.DurationConstructor, amount: number}>;

export const LoadMeasuresThresholds = new Map([
    [EnumScales.DAY, 24],
    [EnumScales.WEEK, 7],
    [EnumScales.MONTH_WEEKLY, 4],
    [EnumScales.MONTH_DAILY, 30],
    [EnumScales.YEAR, 12],
]) as Map<EnumScales, number>;

export const MeasureTypeToApiMeasureType: Record<EnumMeasureTypes, EnumApiMeasureTypes> = {
  [EnumMeasureTypes.SUM_ENERGY_ELEC_NO_CONTRACT]: EnumApiMeasureTypes.SUM_ENERGY_ELEC,

  [EnumMeasureTypes.SUM_ENERGY_ELEC_BASE]: EnumApiMeasureTypes.SUM_ENERGY_ELEC$0,
  [EnumMeasureTypes.SUM_ENERGY_PRICE_BASE]: EnumApiMeasureTypes.SUM_ENERGY_PRICE$0,

  [EnumMeasureTypes.SUM_ENERGY_ELEC_PEAK]: EnumApiMeasureTypes.SUM_ENERGY_ELEC$1,
  [EnumMeasureTypes.SUM_ENERGY_PRICE_PEAK]: EnumApiMeasureTypes.SUM_ENERGY_PRICE$1,

  [EnumMeasureTypes.SUM_ENERGY_ELEC_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_ELEC$2,
  [EnumMeasureTypes.SUM_ENERGY_PRICE_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_PRICE$2,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_NO_CONTRACT]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_BASE]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$0,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_BASE]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$0,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$1,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_PEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$1,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$2,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$2,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_SUPER_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$3,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_SUPER_OFFPEAK]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$3,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_WEEKEND]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$4,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_WEEKEND]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$4,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_FREE]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$5,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_FREE]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$5,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM1]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$6,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM1]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$6,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM2]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$7,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM2]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$7,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM3]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$8,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM3]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$8,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM4]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$9,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM4]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$9,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM5]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$10,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM5]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$10,

  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_CUSTOM6]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID$11,
  [EnumMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE_CUSTOM6]: EnumApiMeasureTypes.SUM_ENERGY_BUY_FROM_GRID_PRICE$11,

  [EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID]: EnumApiMeasureTypes.SUM_ENERGY_RESELL_TO_GRID,
  [EnumMeasureTypes.SUM_ENERGY_RESELL_TO_GRID_PRICE]: EnumApiMeasureTypes.SUM_ENERGY_RESELL_TO_GRID_PRICE,
  [EnumMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION]: EnumApiMeasureTypes.SUM_ENERGY_SELF_CONSUMPTION,

  [EnumMeasureTypes.SUM_FLUID_CONSUMPTION_COLD_WATER]: EnumApiMeasureTypes.SUM_FLUID_CONSUMPTION,
  [EnumMeasureTypes.SUM_FLUID_PRICE_COLD_WATER]: EnumApiMeasureTypes.SUM_FLUID_PRICE,

  [EnumMeasureTypes.SUM_FLUID_CONSUMPTION_HOT_WATER]: EnumApiMeasureTypes.SUM_FLUID_CONSUMPTION,
  [EnumMeasureTypes.SUM_FLUID_PRICE_HOT_WATER]: EnumApiMeasureTypes.SUM_FLUID_PRICE,

  [EnumMeasureTypes.SUM_FLUID_CONSUMPTION_GAS]: EnumApiMeasureTypes.SUM_FLUID_CONSUMPTION,
  [EnumMeasureTypes.SUM_FLUID_PRICE_GAS]: EnumApiMeasureTypes.SUM_FLUID_PRICE,

  [EnumMeasureTypes.POWER_PROD_ELEC]: EnumApiMeasureTypes.POWER_PROD_ELEC,

  // WEATHER
  [EnumMeasureTypes.PRESSURE]: EnumApiMeasureTypes.PRESSURE,
  [EnumMeasureTypes.SUM_RAIN]: EnumApiMeasureTypes.SUM_RAIN,
  [EnumMeasureTypes.PROB_RAIN]: EnumApiMeasureTypes.PROB_RAIN,
  [EnumMeasureTypes.WIND_STRENGTH]: EnumApiMeasureTypes.WIND_STRENGTH,
  [EnumMeasureTypes.GUST_STRENGTH]: EnumApiMeasureTypes.GUST_STRENGTH,
  [EnumMeasureTypes.WIND_ANGLE]: EnumApiMeasureTypes.WIND_ANGLE,
  [EnumMeasureTypes.GUST_ANGLE]: EnumApiMeasureTypes.GUST_ANGLE,

  [EnumMeasureTypes.TEMPERATURE]: EnumApiMeasureTypes.TEMPERATURE,
  [EnumMeasureTypes.MIN_TEMPERATURE]: EnumApiMeasureTypes.MIN_TEMPERATURE,
  [EnumMeasureTypes.MAX_TEMPERATURE]: EnumApiMeasureTypes.MAX_TEMPERATURE,

  [EnumMeasureTypes.HUMIDITY]: EnumApiMeasureTypes.HUMIDITY,
  [EnumMeasureTypes.MIN_HUMIDITY]: EnumApiMeasureTypes.MIN_HUMIDITY,
  [EnumMeasureTypes.MAX_HUMIDITY]: EnumApiMeasureTypes.MAX_HUMIDITY,

  [EnumMeasureTypes.CO2]: EnumApiMeasureTypes.CO2,
  [EnumMeasureTypes.MIN_CO2]: EnumApiMeasureTypes.MIN_CO2,
  [EnumMeasureTypes.MAX_CO2]: EnumApiMeasureTypes.MAX_CO2,

  [EnumMeasureTypes.NOISE]: EnumApiMeasureTypes.NOISE,
  [EnumMeasureTypes.MIN_NOISE]: EnumApiMeasureTypes.MIN_NOISE,
  [EnumMeasureTypes.MAX_NOISE]: EnumApiMeasureTypes.MAX_NOISE,

  [EnumMeasureTypes.SP_TEMPERATURE]: EnumApiMeasureTypes.SP_TEMPERATURE,

  [EnumMeasureTypes.SUM_BOILER_ON]: EnumApiMeasureTypes.SUM_BOILER_ON,
  [EnumMeasureTypes.SUM_BOILER_OFF]: EnumApiMeasureTypes.SUM_BOILER_OFF,

  // Not used
  [EnumMeasureTypes.PERCENT_BOILER_ON]: null,

  [EnumMeasureTypes.HEATING_POWER_REQUEST]: EnumApiMeasureTypes.HEATING_POWER_REQUEST,
  [EnumMeasureTypes.MODE_DURATION_FG]: EnumApiMeasureTypes.MODE_DURATION_0,
  [EnumMeasureTypes.MODE_DURATION_AWAY]: EnumApiMeasureTypes.MODE_DURATION_1,
  [EnumMeasureTypes.TEMPERATURE_EXT]: EnumApiMeasureTypes.TEMPERATURE_EXT,
  [EnumMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_POSITIVE]: EnumApiMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_POSITIVE,
  [EnumMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_NEGATIVE]: EnumApiMeasureTypes.COUNT_SP_TEMPERATURE_OFFSET_NEGATIVE,

  [EnumMeasureTypes.SP_TEMPERATURE_OFFSET_POSITIVE]: EnumApiMeasureTypes.SP_TEMPERATURE_OFFSET_POSITIVE,
  [EnumMeasureTypes.SP_TEMPERATURE_OFFSET_NEGATIVE]: EnumApiMeasureTypes.SP_TEMPERATURE_OFFSET_NEGATIVE,

  [EnumMeasureTypes.SUM_HEATING_TIME]: EnumApiMeasureTypes.SUM_HEATING_TIME,

}
