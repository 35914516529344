import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigFacade } from '@library/store/config/config.facade';
import { UserProService } from '@store/user-pro/user-pro.service';
import { filter, firstValueFrom, take, tap } from 'rxjs';
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { UserProFacade } from '@store/user-pro/user-pro.facade';
@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    protected configFacade: ConfigFacade,
    private userProService: UserProService,
    private userProFacade: UserProFacade,
    private buildingsFacade: BuildingsFacade,
  ) { }

  async ngOnInit() {
    this.userProFacade.currentUser$.pipe(
      filter(user => user?.email !== null),
      tap(async () => {
        const params = await firstValueFrom(this.activatedRoute.queryParams);
        try {
          await firstValueFrom(this.userProService.acceptInvite(params.token));
        } catch(error) {
          // redirect to new page
          return this.router.navigate(['/expired-invitation']);
        }

        await firstValueFrom(this.buildingsFacade.loadBuildings());

        this.buildingsFacade.selectBuilding(parseInt(params.buildingId,10));

        this.router.navigate(['/dashboard']);
      }
    ),
    take(1)
    ).subscribe()
  }
}
