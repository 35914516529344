import { createAction, props } from "@ngrx/store";
import { Contract, ContractState, PowerUnit } from "./contracts.interface";
import { Home } from "../homes/homes.interface";

export interface ContractProps {
  home_id: string,
}

export const GetContracts = createAction(
  '[Contracts] Get Contracts',
  props<{ params: ContractProps }>()
);
export const GetContractsSuccess = createAction(
  '[Contracts] Get Contracts success',
  props<{ contracts: ContractState[] }>()
);

export const GetContractsFailure = createAction(
  '[Contracts] Get Contracts failure',
);

export const GetContractTemplates = createAction(
  '[Contracts] Get Contract Templates',
  props<{ country: string, filters: string[] }>()
);

export const GetContractTemplatesSuccess = createAction(
  '[Contracts] Get Contract Templates success',
  props<{ templates: Contract[] }>()
);


export const GetContractTemplatesFailure = createAction(
  '[Contracts] Get Contract Templates failure',
  props<{ error?: string }>()
);

export const SetConsumptionContractStore = createAction(
  '[Contracts] Set consumption Contract data in store',
  props<{ contract: Partial<ContractState> }>()
);

export const SetContract = createAction(
  '[Contracts] Set Contract data',
  props<{ params: Contract }>()
);

export const SetContractSuccess = createAction(
  '[Contracts] Set Contract data success',
)

export const SetContractFailure = createAction(
  '[Contracts] Set Contract data failure',
)

export const GetContractsFromHome = createAction(
  '[Contract] Get contracts from homesdata',
  props<{ home: Home, currentHomeId: string }>()
)
export const CreateContract = createAction(
  '[Contracts] Create Contract data',
  props<{ params: Contract }>()
);

export const CreateContractSuccess = createAction(
  '[Contracts] Create Contract data success',
);

export const CreateContractFailure = createAction(
  '[Contracts] Create Contract data failure',
);

export const SetProductionContractStore = createAction(
  '[Contracts] Set Production Contract data in store',
  props<{ contract: Partial<ContractState> }>()
);
export const CreateContractProduction = createAction(
  '[Contracts] Create Contract production data',
  props<{ params: Contract }>()
);

export const CreateContractProductionSuccess = createAction(
  '[Contracts] Create Contract production data success',
);

export const CreateContractProductionFailure = createAction(
  '[Contracts] Create Contract production data failure',
);

export const UpdateContractUnit = createAction(
  '[Contracts] Update Contract unit',
  props<{ unit: PowerUnit }>()
);
