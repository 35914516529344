import { createAction, props } from '@ngrx/store';
import { Group } from './groups.interface'

export const CreateGroup = createAction(
  '[Group] Create Group',
  props<{ name: string }>(),
);

export const CreateGroupSuccess = createAction(
  '[Group] Create Group success',
  props<{id: number, name: string, buildingId: number}>()
);

export const CreateGroupFailure = createAction(
  '[Group] Create Group failure',
  props<{error, name: string}>(),
);

export const UpdateGroup = createAction(
  '[Group] Update Group',
  props<{group: Partial<Group>}>(),
);

export const UpdateGroupSuccess = createAction(
  '[Group] Update Group success',
  props<{group: Group}>(),
);

export const UpdateGroupFailure = createAction(
  '[Group] Update Group failure',
  props<{error, group: Partial<Group>}>(),
);

export const DeleteGroup = createAction(
  '[Group] Delete Group',
  props<{id: number}>(),
);

export const DeleteGroupSuccess = createAction(
  '[Group] Delete Group success',
  props<{id: number}>(),
);

export const DeleteGroupFailure = createAction(
  '[Group] Delete Group failure',
  props<{error, id: number}>(),
);
