import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { HttpBuildingService } from 'src/app/services/http-building.service';
import { Group } from './groups.interface'

@Injectable()
export class GroupsService {
  constructor(
    private http: HttpBuildingService) {
  }

  createGroup(params: { name: string, buildingId: number }) {
    return this.http.post('groups', params);
  }

  updateGroup(group: Partial<Group>): Observable<Group> {
    return this.http.put(`groups/${group.id}`, {data: group});
  }

  deleteGroup(id: number) {
    return this.http.delete(`groups/${id}`);
  }
}
