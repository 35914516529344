<app-text class="category device-name ellipsis text-grey-90 is-family-semiBold" [text]="device.name ? device.name: ('iaq-app.__IAQ_NO_NAME' | AsyncLang | async)"></app-text>
<app-text class="category ellipsis text-grey-90" [text]="device.groupName ? device.groupName : ('iaq-app.__IAQ_NO_GROUP' | AsyncLang | async)"></app-text>

<ng-container [ngSwitch]="device.reachable">
  <ng-container *ngSwitchCase="true">
    <ng-container *ngTemplateOutlet="reachable"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <ng-container *ngTemplateOutlet="unreachable"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="undefined">
    <ng-container *ngTemplateOutlet="loading"></ng-container>
  </ng-container>
</ng-container>

<ng-template #reachable>
  <div class="category">
    <app-text class="quality text-12 is-family-medium"
      [class]="dashboardService.getQuality(device.health_idx)"
      [text]="dashboardService.getQualityKey(device.health_idx) | AsyncLang | async"
      ></app-text>
  </div>

  <div class="category">
    <app-icon name="co-2" class="has-spacing-right-xxs"></app-icon>
    <app-text class="text-18 is-family-semiBold">{{device.CO2}}</app-text>
    <app-text class="text-14 is-family-semiBold has-spacing-left-xxs" unit [measureType]="EnumMeasureTypes.CO2"></app-text>

  </div>

  <div class="category">
    <app-icon name="temperature" class="has-spacing-right-xxs"></app-icon>
    <app-text class="text-18 is-family-semiBold">{{device.Temperature}}</app-text>
    <app-text class="text-14 is-family-semiBold has-spacing-left-xxs" unit [measureType]="EnumMeasureTypes.TEMPERATURE"></app-text>
  </div>
  <div class="category">

    <app-icon name="water" class="has-spacing-right-xxs"></app-icon>
    <app-text class="text-18 is-family-semiBold">{{device.Humidity}}</app-text>
    <app-text class="text-14 is-family-semiBold has-spacing-left-xxs" unit [measureType]="EnumMeasureTypes.HUMIDITY"></app-text>

  </div>

  <div class="category">
    <app-icon name="sound" class="has-spacing-right-xxs"></app-icon>
    <app-text class="text-18 is-family-semiBold">{{device.Noise}}</app-text>
    <app-text class="text-14 is-family-semiBold has-spacing-left-xxs" unit [measureType]="EnumMeasureTypes.NOISE"></app-text>
  </div>
</ng-template>

<ng-template #unreachable>
  <div class="is-flex-grow-1 is-flex is-justify-center">
    <app-icon class="is-size-4 has-text-danger" name="error-circle"></app-icon>
    <app-text class="text-18 is-family-semiBold has-spacing-left-s">{{'iaq-app.__IAQ_UNREACHABLE' | AsyncLang | async}}</app-text>
  </div>
</ng-template>

<ng-template #loading>
  <div class="is-flex-grow-1 is-flex is-justify-center items-align-center">
    <app-loading-circle size="24px"></app-loading-circle>
  </div>
</ng-template>
