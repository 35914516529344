import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {

  private showMenu = new BehaviorSubject<boolean>(false);
  public showMenu$ = this.showMenu.asObservable();

  static documentClick$ = fromEvent(document, 'click').pipe(
    share()
  );

  documentClickSubscription: Subscription;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.documentClickSubscription = ContextMenuComponent.documentClick$.subscribe((event) => {
      const target = event.target;
      const nativeEl = this.elementRef.nativeElement;
      if (!nativeEl.contains(target) || nativeEl.isEqualNode(target)) {
        this.showMenu.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.documentClickSubscription.unsubscribe();
  }

  toggleMenu(event: Event, value: boolean): void {
    this.showMenu.next(!value);
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    this.showMenu$.pipe(
      take(1),
      tap((val) => this.toggleMenu(event, val))
    ).subscribe();
  }

}
