import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MeasuresReducers } from './measures.reducer';
import { MeasuresEffects } from './measures.effects';
import { MeasuresFacade } from './measures.facade';
import { MeasuresService } from './measures.service';
import { MeasuresLoaderService } from './measures-loader.service';

@NgModule({
  imports: [
    StoreModule.forFeature('measures', MeasuresReducers),
    EffectsModule.forFeature([
      MeasuresEffects,
    ]),
  ],
  providers: [
    MeasuresService,
    MeasuresFacade,
    MeasuresLoaderService,
  ]
})
export class MeasuresModule {}
