import { createReducer, on } from '@ngrx/store';
import { CreateBuildingDeviceSuccess, DeleteBuildingSuccess, GetBuildingsSuccess } from '@store/buildings/buildings.actions';
import { DeleteModuleSuccess, UpdateModuleGroupSuccess } from '@store/modules-pro/modules-pro.actions';
import { CreateGroupSuccess, DeleteGroupSuccess, UpdateGroupSuccess } from './groups.actions';
import { Group, GroupsState } from './groups.interface';

export const initialGroupsState: GroupsState = {
  groups: [],
};

export const buildingsReducers = createReducer(
  initialGroupsState,

  on(GetBuildingsSuccess, (state, {buildings}) => {
    const groupsArr: Group[][] = [];

    buildings.forEach(building =>  {
      if (building.devices && building.groups) {
        const groups = building.groups.map(group => {
          return {
            ...group,
            devices: group.devices.map(dev => dev.id),
            buildingId: building.id
          };
        });

        // unplaced devices are the ones which are not in any group
        const unplacedDevices = building.devices.filter(dev => {
          return !building.groups.find(group => group.devices.map(d => d.id).includes(dev.id))
        });

        // if we have at list one device that have no group, create a fake group for this building
        if (unplacedDevices && unplacedDevices.length > 0) {
          groups.push({
            id: -1,
            buildingId: building.id,
            devices: unplacedDevices.map(dev => dev.id)
          });
        }

        groupsArr.push(groups);
      }
    });

    return {
      ...state,
      groups: [...groupsArr.flat()]
    }
  }),


  on(CreateGroupSuccess, (state, {id, name, buildingId}) => {
    const groups = [...state.groups]
    groups.push({id, name, buildingId, devices: []})
      return {
        ...state,
        groups,
      };
   }
  ),

  on(UpdateGroupSuccess, (state, {group}) => {
    const groups = state.groups.map(g => g.id === group.id ? {...g, ...group} : g)
    return {
      ...state,
      groups,
    };
   }
  ),


  on(DeleteGroupSuccess, (state, {id}) => {
    /**
     * When we delete a group we need to do complementary actions on the groups
     *   - create the 'no group' if it does not exist & if there are devices in the deleted group
     *   - move the devices that were on the deleted group to the 'no group'
     */
    const deletedGroup = state.groups.find(g => g.id === id);
    const noGroup = state.groups.find(g => g.id === -1 && g.buildingId === deletedGroup.buildingId)

    const groups: Group[] = noGroup || deletedGroup.devices.length === 0
      ? state.groups
      : [...state.groups, {id: -1, buildingId: deletedGroup.buildingId, devices: []} as Group];

    return {
      ...state,
      groups: groups
        .filter(g => g !== deletedGroup)
        .map(group => {
          if (group.id === -1 && group.buildingId === deletedGroup.buildingId) {
            return {
              ...group,
              devices: [...group.devices, ...deletedGroup.devices],
            } as Group;
          } else {
            return group;
          }
        }),
    };
   }
  ),

  on(DeleteModuleSuccess, (state, action) => {
    /**
     * When we remove a device we need to remove it from it's group
     */
    return {
      ...state,
      groups: state.groups.map(group => ({
        ...group,
        devices: group.devices.filter(d => d !== action.id),
      })),
    };
  }),

  on(UpdateModuleGroupSuccess, (state, action) => {
    /**
     * When we update a module group we need to move the device
     * from it's old group to it's new one
     */
    return {
      ...state,
      groups: state.groups
        .map(group => {
          // Remove from old group
          if (group.id === action.previousGroup.id) {
            group = {
              ...group,
              devices: group.devices.filter(d => d !== action.module.id),
            };
          }

          // Add in the new group
          if (group.id === action.module.group) {
            group = {
              ...group,
              devices: [...group.devices, action.module.id],
            };
          }

          return group;
        })
        .filter(group => {
          /**
           * When we delete the last device in the no group we should remove the no group
           * from the store
           */
          if (group.id == -1 && action.group.buildingId === group.buildingId && group.devices.length === 0) {
            return false;
          }
          return true;
        })
    };
  }),

  on(CreateBuildingDeviceSuccess, (state, action) => {
    /**
     * When we add new devices to a building we need to add the devices :
     * to the group indicated by the device
     * or to the 'no group' of the building if field group is null.
     * If the 'no group' does not exist we need to create one
     */

    const groupId = action.device.group ? action.device.group.id : -1;

    // If device has 'no group' we check if the 'no group' exists
    const noGroup = groupId === -1
      ? state.groups.find(g => g.id === -1 && g.buildingId === action.buildingId)
      : null;

    // If device has group or 'no group' already exist we don't need to create a new one
    const groups: Group[] = (noGroup || groupId !== -1)
    ? state.groups
    : [...state.groups, {id: -1, buildingId: action.buildingId, devices: [] } as Group];

    return {
      ...state,
      groups: groups.map((group) => {
        if (group.id === groupId && group.buildingId === action.buildingId) {
          group = {
            ...group,
            devices: [...group.devices, action.device.id],
          }
        }

        return group;
      }),
    };
  }),

  on(DeleteBuildingSuccess, (state, action) => {
    return {
      ...state,
      groups: state.groups.filter(g => g.buildingId !== action.buildingId),
    };
  }),
);

