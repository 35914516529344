import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConfigReducers } from './config.reducer';
import { ConfigEffects } from './config.effects';
import { ConfigFacade } from './config.facade';
import { ConfigService } from './config.service';

@NgModule({
  imports: [
    StoreModule.forFeature('config', ConfigReducers),
    EffectsModule.forFeature([
      ConfigEffects,
    ]),
  ],
  providers: [
    ConfigService,
    ConfigFacade
  ]
})
export class ConfigModule {}
