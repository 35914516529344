<svg [attr.width]="size" [attr.height]="size" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
      <path id="kuaqw9utna" d="M0 0h72v72H0z"/>
  </defs>
  <g fill="none" fill-rule="evenodd">
      <mask id="psgrysvvjb" fill="#fff">
          <use xlink:href="#kuaqw9utna"/>
      </mask>
      <path d="M36 72c19.882 0 36-16.118 36-36S55.882 0 36 0 0 16.118 0 36s16.118 36 36 36zm0-4C18.327 68 4 53.673 4 36 4 18.327 18.327 4 36 4c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32z" fill="#F4F5F7" mask="url(#psgrysvvjb)"/>
      <path d="M36 72c19.882 0 36-16.118 36-36a2 2 0 1 0-4 0c0 17.673-14.327 32-32 32a2 2 0 1 0 0 4z" fill="#FF8500" mask="url(#psgrysvvjb)"/>
  </g>
</svg>
