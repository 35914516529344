import { Component, Input, OnInit } from '@angular/core';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { DashboardService } from '@pages/dashboard-view/dashboard.service';
import { Thresholds } from '@store/buildings/buildings.interface';
import { QrCodePublicDataForDisplay } from '@store/qr-codes/qr-codes.interface';
import { QRCodesService } from '@store/qr-codes/qr-codes.service';
import { BehaviorSubject } from 'rxjs';
import { ThresholdsService } from 'src/app/services/thresholds.service';

enum measureTypesToIconTypes {
  co2 = 'co-2',
  noise = 'sound',
  temperature = 'temperature',
  humidity = 'humidity'
}

@Component({
  selector: 'app-qr-code-measure-card',
  templateUrl: './qr-code-measure-card.component.html',
  styleUrls: ['./qr-code-measure-card.component.scss']
})
export class QrCodeMeasureCardComponent implements OnInit {
  measureTypesToIconTypes = measureTypesToIconTypes;
  EnumMeasureTypes = EnumMeasureTypes;
  @Input() qrCodeData: QrCodePublicDataForDisplay;
  @Input() measureType: EnumMeasureTypes;
  @Input() thresholds: Thresholds;
  qualityData$ = new BehaviorSubject<{ quality: string, color: string }>(null);


  constructor(
    public qrCodesService: QRCodesService,
    public thresholdsService: ThresholdsService,
    public dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    this.qualityData$.next(this.qrCodesService.getQualityFromThreshold(this.measureType, this.qrCodeData[this.measureType], this.thresholds))
  }
}
