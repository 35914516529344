import { Injectable } from '@angular/core';
import { MeasuresFacade } from '@library/store/measures/measures.facade';
import { EnumApiScales, EnumMeasureTypes, TimeRange } from '@library/store/measures/measures.interface';
import { ModulesFacade } from '@library/store/modules/modules.facade';
import { Module } from '@library/store/modules/modules.interface';
import { Room } from '@library/store/rooms/rooms.interface';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { firstValueFrom } from 'rxjs';

// Object to retain timranges loaded for each scale, of each module
// and each measure types
const LoadedTimeRanges: {
  [measureType: string]: {
    [id: string]: {
      [apiScale: string]: {
        start: number,
        end: number
      }[]
    },
  }
} = {};

@Injectable()
export class MeasuresLoaderService {

  constructor(
    public measuresFacade: MeasuresFacade,
    public modulesFacade: ModulesFacade,
    public store$: Store,
    public actions$: Actions,
  ) { }

  async loadModulesMeasures(
    modules: Module[],
    measureTypes: Record<string, EnumMeasureTypes[]>,
    scale: EnumApiScales,
    timeRange: TimeRange,
  ) {
    const modulesNeedingMeasuresUpdate = await this.getItemsNeedingUpdate(modules, measureTypes, scale, timeRange);
    if (modulesNeedingMeasuresUpdate.length > 0) {
      return this.measuresFacade.loadHomeMeasures(
        modulesNeedingMeasuresUpdate,
        [],
        scale,
        timeRange,
      );
    }
  }

  async loadRoomsMeasures(
    rooms: Room[],
    measureTypes: Record<string, EnumMeasureTypes[]>,
    scale: EnumApiScales,
    timeRange: TimeRange,
  ) {
    const roomsNeedingUpdate = await this.getItemsNeedingUpdate(rooms, measureTypes, scale, timeRange);
    if (roomsNeedingUpdate.length > 0) {
      return this.measuresFacade.loadHomeMeasures(
        [],
        roomsNeedingUpdate,
        scale,
        timeRange,
      );
    }
  }

  // Sends the request to get the consumption measures (gas,water, power...) of the home. Filters to get the right modules to be measured
  async loadHomeElecMeasures(
    measureTypes: EnumMeasureTypes[],
    scale: EnumApiScales,
    timeRange: TimeRange,
  ) {
    const homeModules = await firstValueFrom(this.modulesFacade.currentHomeModules$);
    const homeElecModules = ['NLG', 'NLE', 'NLPC', 'NLPS', 'NLY', 'BNXM', 'BNLC'];
    const modules = homeModules
      .filter(module =>  homeElecModules.includes(module.type))
    const modulesMeasureTypes: Record<string, EnumMeasureTypes[]> = modules.reduce((acc, curr) => {
      acc[curr.id] = measureTypes;
      return acc;
    }, {})

    const modulesNeedingMeasuresUpdate = await this.getItemsNeedingUpdate(modules, modulesMeasureTypes, scale, timeRange);
    if (modulesNeedingMeasuresUpdate.length > 0) {
      return this.measuresFacade.loadHomeMeasures(
        modulesNeedingMeasuresUpdate,
        [],
        scale,
        timeRange,
      );
    }
  }

  private async getItemsNeedingUpdate<T extends {id: string}>(
    items: T[],
    measureTypes: Record<string, EnumMeasureTypes[]>,
    scale: EnumApiScales,
    timeRange: TimeRange,
  ) {
    return items.reduce((acc, item, index) => {
      let needUpdate = false;

      // always refresh when dateEnd is in the future
      if (timeRange.dateEnd.clone().isAfter(moment())) {
        needUpdate = true;
      }

      // Check if the given timerange il already loaded for the module,
      // the scale and the given measure types
      measureTypes[items[index].id].forEach(measureType => {

        if (!LoadedTimeRanges[measureType]) {
          LoadedTimeRanges[measureType] = {};
        }

        if (!LoadedTimeRanges[measureType][items[index].id]) {
          LoadedTimeRanges[measureType][items[index].id] = {};
        }

        if (!LoadedTimeRanges[measureType][items[index].id][scale]) {
          LoadedTimeRanges[measureType][items[index].id][scale] = [];
        }

        const timeranges = LoadedTimeRanges[measureType][items[index].id][scale];

        needUpdate = needUpdate || !timeranges.some(t => timeRange.dateBegin.valueOf() >= t.start && timeRange.dateEnd.valueOf() <= t.end);

        if (needUpdate) {
          timeranges.unshift({start: timeRange.dateBegin.valueOf(), end: timeRange.dateEnd.valueOf()});
        }
      });

      if (needUpdate) {
        acc.push([item, measureTypes[items[index].id]]);
      }

      return acc;;
    }, [] as [T, EnumMeasureTypes[]][]);
  }
}
