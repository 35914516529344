import { EnumApiScales, EnumMeasureTypes, TimeRange } from "@library/store/measures/measures.interface"
import { Thresholds } from "@store/buildings/buildings.interface"
import { ModulePro } from "@store/modules-pro/modules-pro.interface"

export const QR_CODES_URL_PATH = '/qr-code/'
export const QR_CODES_DOWNLOAD_PDF_URL = '/qr-code/pdf/'

export interface QRCodeState {
  qrCodes: QRCode[]
}
export type measureDataOrEmpty = number | '—';
export interface QRCodeFromAPI {
  id: number,
  isEnabled: boolean,
  uid: string,
  device: {
    id: number,
    device_id: string, // MAC address of the module
    name: string
  },
  building: {
    id: number
  }
}
export interface AllQRCodesFromAPI {
  qrCodes: QRCodeFromAPI[]
}
export interface QRCode {
  id?: number,
  isEnabled: boolean,
  deviceName?: string,
  uid?: string,
  url?: string,
  pdfUrl?: string,
  device_id?: string, // MAC address of the module
  moduleId?: number // actual id of the module
}
export interface QrCodeHistoricalDataFromAPI {
  device_id: string,
  name: string,
  types: EnumMeasureTypes[],
  date_begin: number,
  date_end: number,
  beg_time: number,
  step_time: number,
  value: Array<number>[],
  thresholds: Thresholds,
  timezone: string,
}
export interface QrCodeLiveDataFromAPI {
  station_name: string,
  place: {
    altitude: number,
    city: string,
    street: string,
    country: string,
    timezone: string,
    location: [number, number] // GPS coordinates
  },
  dashboard_data: ModulePro,
  advice: string,
  id: string,
  thresholds: Thresholds,
  timezone: string,
}

export interface QrCodePublicDataForDisplay {
  name: string,
  co2: measureDataOrEmpty,
  temperature: measureDataOrEmpty,
  humidity: measureDataOrEmpty,
  noise: measureDataOrEmpty,
  date?: number, // date in unix time
  timezone?: string,
  thresholds?: Thresholds,
  health_idx?: number,
  hour?: string,
  hourUnit?: string
}

export type QrCodeMeasuresForDisplay = QrCodePublicDataForDisplay[];
export interface QrCodePDFRequest {
  url: string,
  uid: string,
  checkAirQuality?: string,
  scanMe?: string,
  CO2?: string,
  temperature?: string,
  humidity?: string,
  noise?: string,
  pageSize?: string
}

export enum CrowdinKeysForPDF {
  checkAirQuality = 'iaq-app.__IAQ_QR_CODE_PDF_TITLE',
  scanMe = 'iaq-app.__IAQ_QR_CODE_PDF_SCAN_ME',
  CO2 = 'common-app.__CO2',
  temperature = 'common-app.__TEMPERATURE',
  humidity = 'common-app.__HUMIDITY',
  noise = 'common-app.__NOISE',
}

export interface QrCodeQuery {
  scale: EnumApiScales,
  type: EnumMeasureTypes[],
  date_begin?: number,
  date_end?: number,
  timeRange?: TimeRange
}

export const emptyData: QrCodePublicDataForDisplay = {
  name: '',
  temperature: '—',
  humidity: '—',
  co2: '—',
  noise: '—',
}

export const queryParams: QrCodeQuery = {
  scale: EnumApiScales.ONE_HOUR,
  type: [
    EnumMeasureTypes.TEMPERATURE,
    EnumMeasureTypes.HUMIDITY,
    EnumMeasureTypes.CO2,
    EnumMeasureTypes.NOISE
  ]
}
