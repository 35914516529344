import { Component, OnInit } from '@angular/core';
import { AuthorizeSuccess } from '@store/buildings/buildings.actions';
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { environment } from '@environments/environment';
import { LocalStorageService } from '@library/utils/services/local-storage.service';


@Component({
  selector: 'app-no-building-view',
  templateUrl: './no-building-view.component.html',
  styleUrls: ['./no-building-view.component.scss']
})
export class NoBuildingViewComponent implements OnInit {

  logoutUrl = environment.logoutUrl + '?message=__DISCONNECTED&next_url=' + environment.appUrl;

  constructor(
    public buildingFacade: BuildingsFacade,
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
  }

  async addBuilding() {
    this.buildingFacade.authorize().subscribe(
      action => {
        if (action.type === AuthorizeSuccess.type) {
          this.localStorage.setItem('oauth2_state', action.state);
          document.location.href = action.url
        }
      }
    )
  }

}
