import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service';
import { AuthorizeSuccess } from '@store/buildings/buildings.actions'
import { BuildingsFacade } from '@store/buildings/buildings.facade'

@Component({
  selector: 'app-add-building',
  templateUrl: './add-building.component.html',
  styleUrls: ['./add-building.component.scss']
})
export class AddBuildingComponent implements OnInit {

  constructor(
    public buildingFacade: BuildingsFacade,
    public localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
  }

  async addBuilding() {
    this.buildingFacade.authorize().subscribe(
      action => {
        if (action.type === AuthorizeSuccess.type) {
          this.localStorage.setItem('oauth2_state', action.state);
          document.location.href = action.url;
        }
      }
    )
  }

}
