import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocalStorageService {

  public setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  public getItem(key: string): string {
    return window.localStorage.getItem(key);
  }
}
