import { createAction, props } from '@ngrx/store';
import { Module } from '../modules/modules.interface';
import { EnumApiScales, EnumMeasureTypes, RawModuleMeasures, TimeRange } from './measures.interface';

interface LoadMeasuresProps {
  home: {
    id: string,
    modules?: {id: string, bridge?: string, types: EnumMeasureTypes[]}[],
    rooms?: {id: string, bridge: string, types: EnumMeasureTypes[]}[],
  },
  real_time?: boolean,
  timeRange: TimeRange,
  scale: EnumApiScales,
}

export interface LoadMeasuresSuccessProps {
  home: {
    id: string,
    modules?: RawModuleMeasures[],
    rooms?: RawModuleMeasures[],
  },
  timeRange: TimeRange,
  scale: EnumApiScales,
  real_time?: boolean,
  homeModules?: Module[],
  isConsumptionContractStatic?: boolean
}

export const LoadHomeMeasures = createAction(
  '[Measures] Load Home Measures',
  props<LoadMeasuresProps>()
);

export const LoadHomeMeasuresSuccess = createAction(
  '[Measures] Load Home Measures Success',
  props<LoadMeasuresSuccessProps>()
);

