import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCircleComponent {
  @Input() size: string = '45px';
}
