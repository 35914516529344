import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settings-search-bar',
  templateUrl: './settings-search-bar.component.html',
  styleUrls: ['./settings-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsSearchBarComponent),
      multi: true
    }
  ]
})
export class SettingsSearchBarComponent implements OnInit {

  searchControl = new UntypedFormControl('');

  sub = new Subscription();

  constructor() { }

  ngOnInit(): void {
    this.sub.add(this.searchControl.valueChanges.subscribe(value => {
      this.propagateChange(value);
    }));
  }

  propagateChange: any = () => {};

  writeValue(value: string) {
    this.searchControl.setValue(value);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // Unused but necessary to implement ControlValueAccessor
  registerOnTouched() {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
