import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { BuildingsFacade } from '@store/buildings/buildings.facade'

@Component({
  selector: 'app-invitation-expired',
  templateUrl: './invitation-expired.component.html',
  styleUrls: ['./invitation-expired.component.scss']
})
export class InvitationExpiredComponent implements OnInit {

  constructor(
    private router: Router,
    private buildingsFacade: BuildingsFacade,
  ) { }

  ngOnInit(): void {
  }

  async goDashboard() {
    this.buildingsFacade.buildings$.subscribe(buildings => {
      if (buildings && buildings.length > 0) {
        this.router.navigate(['/dashboard']);
      } else {
        // Launch installation flow
        this.router.navigate(['/installation/summary']);
      }
    })

  }

}
