
export enum Tariffs {
  EDF = 'edf_tarif_bleu',
  CUSTOM = 'custom',
}

export enum TariffOptions {
  PEAK_OFF_PEAK = 'peak_and_off_peak',
  OTHER = 'other',
  BASIC = 'basic',
}

export type TariffType = Tariffs.EDF | Tariffs.CUSTOM;

export type TariffOptionsType =
  TariffOptions.PEAK_OFF_PEAK | TariffOptions.OTHER | TariffOptions.BASIC;
