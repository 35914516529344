import { Injectable } from '@angular/core';
import { EnumApiScales, EnumScales } from '@library/store/measures/measures.interface';



@Injectable({
  providedIn: 'root'
})
export class ScalesService {

  constructor() { }

  scaleToApiScale(scale: EnumScales) {
    switch (scale) {
      case EnumScales.DAY:
        return EnumApiScales.FIVE_MINUTES;
      case EnumScales.WEEK:
        return EnumApiScales.THREE_HOURS;
      case EnumScales.MONTH_DAILY:
        return EnumApiScales.ONE_DAY;
      case EnumScales.YEAR:
        return EnumApiScales.ONE_WEEK;
    }
  }
}
