import { createReducer, on } from "@ngrx/store";
import { DeleteModuleSuccess } from "@store/modules-pro/modules-pro.actions";
import { GenerateNewQrCodeSuccess, DeleteQrCodeSuccess, GetAllQRCodesSuccess, ToggleQrCodeEnabledStatusSuccess, RegenerateQrCodeSuccess } from "./qr-codes.actions";
import { QRCode } from "./qr-codes.interface";
import { initialQRCodeState } from "./qr-codes.state";
import { formatQrCodeForDisplay, formatInactiveQrCodeFromModule } from "./qr-codes.utils";


export const qrCodesReducers = createReducer(
  initialQRCodeState,

  on(GetAllQRCodesSuccess, (state, action) => {
    const { data: { qrCodes }, modules } = action;

    // Modules without qrCodes, generate inactive qrCodes for display purposes.
    if (!qrCodes || qrCodes.length === 0) {
      const inactiveQrCodes = modules.map(m => (formatInactiveQrCodeFromModule(m)));
      return {
        ...state,
        qrCodes: inactiveQrCodes
      }
    }

    // Merging received qrCodes and modules without qrCodes,
    const mergedQrCodes = modules
      .map(m => {
        const matchingQrCode = qrCodes.find(qrCode => qrCode.device.device_id === m.device_id);
        return matchingQrCode ? formatQrCodeForDisplay(matchingQrCode) : formatInactiveQrCodeFromModule(m)
      })

    return {
      ...state,
      qrCodes: mergedQrCodes
    }

  }),

  on(DeleteQrCodeSuccess, (state, action) => {
    return {
      ...state,
      qrCodes: state.qrCodes.filter(qrCode =>
        qrCode.id !== action.data.id)
    }
  }),

  on(ToggleQrCodeEnabledStatusSuccess, (state, action) => {
    return {
      ...state,
      qrCodes: [
        ...state.qrCodes.map(qrCode =>
          qrCode.id === action.data.id
            ? { ...qrCode, isEnabled: action.data.isEnabled }
            : qrCode)
      ]
    }
  }),

  on(GenerateNewQrCodeSuccess, (state, action) => {
    const { device } = action.data;
    const newQrCode: QRCode = formatQrCodeForDisplay(action.data)
    return {
      ...state,
      qrCodes: [
        ...state.qrCodes.map(qrCode =>
          qrCode.device_id === device.device_id
            ? newQrCode
            : qrCode)
      ]
    };
  }),

  on(RegenerateQrCodeSuccess, (state, action) => {
    const { device } = action.data;
    const newQrCode: QRCode = formatQrCodeForDisplay(action.data)

    return {
      ...state,
      qrCodes: [
        ...state.qrCodes.map(qrCode =>
          qrCode.device_id === device.device_id
            ? newQrCode
            : qrCode)
      ]
    };
  }),

  // Remove Qrcode if its module is deleted
  on(DeleteModuleSuccess, (state, action) => {
    return {
      ...state,
      qrCodes: state.qrCodes.filter(qrCode =>
        qrCode.moduleId !== action.id)
    }
  }),

)

