import { Injectable, InjectionToken } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import * as UserProSelectors from './user-pro.selector';
import * as UserProActions from './user-pro.action';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { combineLatest, map } from 'rxjs';
import { AppState } from '@store/app.state'
import { BuildingsFacade } from '@store/buildings/buildings.facade'

@Injectable()
export class UserProFacade {
  currentUser$ = this.store.pipe(select(UserProSelectors.getUser));
  userMail$ = this.store.pipe(select(UserProSelectors.getUserEmail));
  isAdmin$ = this.store.pipe(select(UserProSelectors.isAdmin));
  userId$ =  this.store.pipe(select(UserProSelectors.getUserId));

  constructor(
    protected store: Store<AppState>,
    protected actions$: Actions,
    private buildingFacade: BuildingsFacade,
  ) { }

  inviteUser(buildingId) {
    this.store.dispatch(UserProActions.InviteUserPro({buildingId}));
    return this.actions$.pipe(
        ofType(UserProActions.InviteUserProSuccess),
        take(1)
    );
  }


}
