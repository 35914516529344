export const DefaultLanguage = "en";

export const SupportedLanguages = [
  { language: 'cs', locale: 'cs-CS', crowdin: 'cs' },
  { language: 'de', locale: 'de-DE', crowdin: 'de' },
  { language: 'en', locale: 'en-GB', crowdin: 'en' },
  { language: 'es', locale: 'es-ES', crowdin: 'es-ES' },
  { language: 'fr', locale: 'fr-FR', crowdin: 'fr' },
  { language: 'it', locale: 'it-IT', crowdin: 'it' },
  { language: 'nl', locale: 'nl-NL', crowdin: 'nl' },
  { language: 'pl', locale: 'pl-PL', crowdin: 'pl' },
  { language: 'uk', locale: 'uk-UA', crowdin: 'uk' },
];

/**
 * @param code Language code in any format ('xx' or 'xx-xx')
 * @returns The first two letters of the given code
 * which represents the country
 */
export function getCountryCode(code: string) {
  const countryCode = code.match(/[A-z]+-/g)
    ? code.match(/[A-z]+-/g)[0].substr(0, 2)
    : code;

  return countryCode;
}

/**
 * @param code Language code in any format ('xx' or 'xx-xx')
 * @returns the crowdin language code or `null` if it's not found
 */
export function getCrowdinCode(code: string) {
  const language = SupportedLanguages.find(l => {
    const countryCode = getCountryCode(code);
    if (code === l.locale) return true;
    else if (countryCode === l.language) return true;
    else return false;
  });

  return language?.crowdin;
}
