import { createSelector } from "@ngrx/store";
import { AppState } from "@store/app.state";
import { allCurrentBuildingModules } from "@store/modules-pro/modules-pro.selectors";

export const getQrCodes = (state: AppState) => state.qrCodes.qrCodes;

export const GetQrCodesEnabled = createSelector(
  getQrCodes,
  qrCodes => {
    return qrCodes && qrCodes.length ? qrCodes.filter(qrCode => qrCode.isEnabled) : [];
  }
)
export const GetQrCodesDisabled = createSelector(
  getQrCodes,
  qrCodes => {
    return qrCodes && qrCodes.length ? qrCodes.filter(qrCode => !qrCode.isEnabled) : [];
  }
)

export const GetModulesWithoutQrCodes = createSelector(
  getQrCodes,
  allCurrentBuildingModules,
  (qrCodes, modules) => {
    // no qrCodes found, return all modules
    if((!qrCodes || !qrCodes.length) && modules && modules.length) {
      return modules;
    }
    // qrCodes present, get the modules not associated with any of them
    if (qrCodes && qrCodes.length && modules && modules.length) {
      const qrCodesIds = qrCodes.map(qrCode => qrCode.moduleId)

      return modules.filter(
        module => !qrCodesIds.includes(module.id))
    }
    return []
  }
)