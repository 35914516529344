import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-no-access-view',
  templateUrl: './no-access-view.component.html',
  styleUrls: ['./no-access-view.component.scss']
})
export class NoAccessViewComponent implements OnInit {

  logoutUrl = environment.logoutUrl + '?message=__DISCONNECTED&next_url=' + environment.appUrl;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  navigateToDownload() {
    // Launch installation flow directly to device summary page to download the installation software
    this.router.navigate(['installation', 'device-summary']);
  }

}
