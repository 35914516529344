<section>
  <app-text class="text-align-center is-family-bold is-size-4">{{'iaq-app.__IAQ_NO_BUILDING' | AsyncLang | async}}</app-text>

  <img src="assets/images/no-building.svg">

  <app-text class="text-align-center is-family-medium">{{'iaq-app.__IAQ_WELCOME' | AsyncLang | async}}</app-text>

  <lay-button class="is-flex-button has-spacing-top-m">
    <button class="is-primary-button" (click)="addBuilding()">
      <app-text>{{'common-app.__CONTINUE_BTN' | AsyncLang | async}}</app-text>
    </button>
  </lay-button>
  <a [href]="logoutUrl">
    <app-text class="is-family-medium"> {{'hc-app.__HC_SETTINGS_LOGOUT' | AsyncLang | async }}</app-text>
  </a>
</section>
