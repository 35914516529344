import { Env } from 'src/typings';

export const environment: Partial<Env> = {
  production: true,
  envDomain: 'beta',
  isProdDomain: false,

  logoutUrl: 'https://authbeta.netatmo.com/logout',
  appUrl: window.location.origin,

  apiUrl: "https://building-apibeta.netatmo.com/api/",
  authUrl: "https://authbeta.netatmo.com/",
  cookiePrefix: "betanetatmocom",
  langsUrl: "/langs/",
  COOKIE_LOCALE_DOMAIN: '.netatmo.com',

  myAccountUrl: 'https://myconnectedaccountbeta.netatmo.com',

  webPusherUrl: "wss://app.netatmo.net/ws/",
};
