<ng-container *ngIf="{
  showMenu: showMenu$ | async
} as vm">

  <div #ngContentRef>
      <ng-content select="[role=anchor]"></ng-content>
  </div>

  <ng-container *ngIf="!ngContentRef.hasChildNodes()">

    <div class="default-anchor-wrapper">

      <app-icon
        class="data-icon"
        name="more-horizontal">
      </app-icon>

    </div>

  </ng-container>

  <div class="relative-parent-container">

    <div class="menu-container" [ngClass]="{'show': vm.showMenu}">
      <div class="menu-list">
        <ng-content></ng-content>
      </div>
    </div>

  </div>

</ng-container>
