import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnDestroy
} from '@angular/core';
import { BehaviorSubject, Subscription, firstValueFrom, fromEvent } from 'rxjs';

@Directive({selector: '[role=anchor]'})
export class ContextMenuAnchorDirective {
  constructor(public ref: ElementRef) {}
}

@Component({
  selector: 'lay-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements AfterViewInit, OnDestroy {

  showMenu$ = new BehaviorSubject(false);

  private sub = new Subscription();

  // Controls which side of anchor the pop-up will render from, renders going the opposite direction from chosen
  // Ex: (Chosen selector designated with [])
  // left <----- [right], [left] --------> right
  @Input() direction = 'right';

  // Controls if the menu should be displayed below the anchor, or above it. Defaults to below
  @Input() displayUp = false;

  @ContentChild(ContextMenuAnchorDirective, {static: true} as any) anchor: ContextMenuAnchorDirective;

  ngAfterViewInit() {
    // Listens for click event on designated menu achnor (Ex: Icon, Text, Image, etc.)
    this.sub.add(fromEvent(
      this.anchor.ref.nativeElement,
      'click'
    ).subscribe(() => {
      this.toggleMenu();
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async toggleMenu() {
    const value = await firstValueFrom(this.showMenu$)
    this.showMenu$.next(!value);
  }

}

/**
 * Used in Settings Weather
 */
