import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigFacade } from '@library/store/config/config.facade';
import { CallbackService } from '@library/utils/services/callback.service';
import { UserAgentService } from '@library/utils/services/user-agent.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private userAgent: UserAgentService,
        private configFacade: ConfigFacade,
        private callback: CallbackService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {

                if (err.error.error !== undefined && err.error.error.status === 403) {
                    return this.handleUnauthorized(err);
                }
                return throwError(err);
            })
        );
    }

    /**
     * Handle error when access token is missing or invalid
     * - NativeApp: send 'tokenExpired' callback
     * - Others: redirect to auth
     */
    private handleUnauthorized = (err: HttpEvent<any>) => {
        return of(err).pipe(
            withLatestFrom(this.configFacade.config$),
            map(([,config]) => {
                if (this.userAgent.isNativeApp()) {
                    this.callback.call(this.callback.list.tokenExpired, undefined);
                } else {
                    window.location.href = `${config.authUrl}access/checklogin?next_url=${encodeURIComponent(window.location.href)}`;
                }
                throw err;
            })
        );
    }
}
