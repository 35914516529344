import { createReducer, on } from '@ngrx/store'
import { GetBuildingsSuccess } from '@store/buildings/buildings.actions'
import { initialUserProState } from './user-pro.state';

export const UserProReducers = createReducer(
  initialUserProState,

  on(GetBuildingsSuccess, (state, { user }) => {
    return {
      ...state,
      ... {
        id: user.id,
        user_id: user.user_id,
        email: user.email,
        app_telemetry: user.app_telemetry
      }
    };
  }),

)

