import { Injectable } from "@angular/core";
import { LoadHomeMeasuresSuccess, LoadMeasuresSuccessProps } from "@library/store/measures/measures.actions";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { ModulesProFacade } from "@store/modules-pro/modules-pro.facade";
import * as moment from "moment-timezone";
import { catchError, map, of, switchMap } from "rxjs";
import {
  DeleteQrCode,
  DeleteQrCodeFailure,
  DeleteQrCodeSuccess,
  GetAllQRCodes,
  GetAllQRCodesFailure,
  GetAllQRCodesSuccess,
  GetQRCodeHistoricalData,
  GetQRCodeHistoricalDataFailure,
  GetQRCodeHistoricalDataSuccess,
  ToggleQrCodeEnabledStatus,
  ToggleQrCodeEnabledStatusFailure,
  ToggleQrCodeEnabledStatusSuccess,
  GenerateNewQrCode,
  GenerateNewQrCodeSuccess,
  GenerateNewQrCodeFailure,
  RegenerateQrCode,
  RegenerateQrCodeSuccess,
  RegenerateQrCodeFailure,
  GeneratePDF,
  GeneratePDFSuccess,
  GeneratePDFFailure,
  GetQRCodeLiveData,
  GetQRCodeLiveDataSuccess,
  GetQRCodeLiveDataFailure
} from "./qr-codes.actions";
import { QRCodesService } from "./qr-codes.service";

@Injectable()
export class QRCodeEffects {
  constructor(
    private actions$: Actions,
    private QRCodeService: QRCodesService,
    public modulesProFacade: ModulesProFacade,
  ) { };

  getQRCodes = createEffect(() => this.actions$.pipe(
    ofType(GetAllQRCodes),
    switchMap(action => {
      return this.QRCodeService.getAllQRCodes(action.buildingId).pipe(
        concatLatestFrom(() => this.modulesProFacade.allCurrentBuildingModules$),
        map(([response, modules]) => {
          return GetAllQRCodesSuccess({ data: response, modules })
        }
        ),
        catchError((err) => of(GetAllQRCodesFailure(err)))
      )
    })
  ))

  getQrCodeLiveData = createEffect(() => this.actions$.pipe(
    ofType(GetQRCodeLiveData),
    switchMap(action => {
      return this.QRCodeService.getQRCodeLiveData(action.buildingId, action.qrCodeUid).pipe(
        switchMap((data) => {
          return [GetQRCodeLiveDataSuccess({ data, qrCodeUid: action.qrCodeUid })]
        }),
        catchError(err => of(GetQRCodeLiveDataFailure({ ...err, ...action })))
      )
    })
  ))
  getQrCodeHistoricalData = createEffect(() => this.actions$.pipe(
    ofType(GetQRCodeHistoricalData),
    switchMap(action => {
      return this.QRCodeService.getQRCodeHistoricalData(action.buildingId, action.qrCodeUid, action.params).pipe(
        switchMap((data) => {
          const formattedData: LoadMeasuresSuccessProps = {
            home: {
              id: data.device_id,
              modules: [{
                id: data.device_id,
                measures: [{
                  beg_time: data.beg_time,
                  step_time: data.step_time,
                  value: data.value
                }],
                measureTypes: data.types
              }]
            },
            timeRange: action.params.timeRange,
            scale: action.params.scale
          }
          return [LoadHomeMeasuresSuccess(formattedData), GetQRCodeHistoricalDataSuccess({ data, qrCodeUid: action.qrCodeUid })]
        }),
        catchError(err => of(GetQRCodeHistoricalDataFailure({ ...err, ...action })))
      )
    })
  ))

  generateNewQrCode = createEffect(() => this.actions$.pipe(
    ofType(GenerateNewQrCode),
    switchMap(action => {
      return this.QRCodeService.createOrRegenerateQrCode(action.device_id, action.isEnabled).pipe(
        switchMap(data => {
          return [GenerateNewQrCodeSuccess(data)]
        }),
        catchError(err => of(GenerateNewQrCodeFailure({ ...err, ...action })))
      )
    })
  ))

  regenerateQrCode = createEffect(() => this.actions$.pipe(
    ofType(RegenerateQrCode),
    switchMap(action => {
      return this.QRCodeService.createOrRegenerateQrCode(action.device_id, action.isEnabled).pipe(
        switchMap(data => {
          return [RegenerateQrCodeSuccess(data)]
        }),
        catchError(err => of(RegenerateQrCodeFailure({ ...err, ...action })))
      )
    })
  ))

  toggleQrCodeEnabledStatus = createEffect(() => this.actions$.pipe(
    ofType(ToggleQrCodeEnabledStatus),
    switchMap(action => {
      return this.QRCodeService.toggleQrCodeEnabledStatus(action.qrCodeId, action.isEnabled).pipe(
        switchMap(data => {
          return [ToggleQrCodeEnabledStatusSuccess(data)]
        }),
        catchError(err => of(ToggleQrCodeEnabledStatusFailure({ ...err, ...action })))
      )
    })
  ))

  deleteQrCode = createEffect(() => this.actions$.pipe(
    ofType(DeleteQrCode),
    switchMap(action => {
      return this.QRCodeService.deleteQrCode(action.qrCodeId).pipe(
        switchMap(data => {
          return [DeleteQrCodeSuccess(data)]
        }),
        catchError(err => of(DeleteQrCodeFailure({ ...err, ...action })))
      )
    })
  ))

  downloadPDF = createEffect(() => this.actions$.pipe(
    ofType(GeneratePDF),
    switchMap(action => {
      return this.QRCodeService.downloadPDF(action.params).pipe(
        switchMap(data => {
          return [GeneratePDFSuccess({ data })]
        }),
        catchError(err => of(GeneratePDFFailure({ ...err, ...action })))
      )
    })
  ))

}
