import { Injectable } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { AppState } from "@store/app.state";
import { take } from "rxjs";
import {
  DeleteQrCode,
  DeleteQrCodeFailure,
  DeleteQrCodeSuccess,
  GetAllQRCodes,
  GetAllQRCodesFailure,
  GetAllQRCodesSuccess,
  GetQRCodeHistoricalData,
  GetQRCodeHistoricalDataFailure,
  GetQRCodeHistoricalDataSuccess,
  ToggleQrCodeEnabledStatus,
  ToggleQrCodeEnabledStatusFailure,
  ToggleQrCodeEnabledStatusSuccess,
  GenerateNewQrCode,
  RegenerateQrCode,
  GeneratePDF,
  GeneratePDFSuccess,
  GeneratePDFFailure,
  GetQRCodeLiveData,
  GetQRCodeLiveDataSuccess,
  GetQRCodeLiveDataFailure,
} from "./qr-codes.actions";
import { QrCodePDFRequest, QrCodeQuery } from "./qr-codes.interface";
import * as qrCodesSelectors from "./qr-codes.selector";

@Injectable()
export class QRCodesFacade {
  constructor(
    private store: Store<AppState>,
    public actions$: Actions,
  ) { }

  qrCodes$ = this.store.pipe(select(qrCodesSelectors.getQrCodes));
  qrCodesEnabled$ = this.store.pipe(select(qrCodesSelectors.GetQrCodesEnabled));
  qrCodesDisabled$ = this.store.pipe(select(qrCodesSelectors.GetQrCodesDisabled));
  modulesWithoutQrCodes$ = this.store.pipe(select(qrCodesSelectors.GetModulesWithoutQrCodes));

  loadAllQRCodes(buildingId: number) {
    this.store.dispatch(GetAllQRCodes({ buildingId }))
    return this.actions$.pipe(
      ofType(GetAllQRCodesSuccess, GetAllQRCodesFailure),
      take(1)
    )
  }

  GetQRCodeLiveData(buildingId: number, qrCodeUid: string) {
    this.store.dispatch(GetQRCodeLiveData({ buildingId, qrCodeUid }));

    return this.actions$.pipe(
      ofType(GetQRCodeLiveDataSuccess, GetQRCodeLiveDataFailure),
      take(1)
    )
  }

  GetQRCodeHistoricalData(buildingId: number, qrCodeUid: string, params: QrCodeQuery) {
    this.store.dispatch(GetQRCodeHistoricalData({ buildingId, qrCodeUid, params }));

    return this.actions$.pipe(
      ofType(GetQRCodeHistoricalDataSuccess, GetQRCodeHistoricalDataFailure),
      take(1)
    )
  }

  generateNewQrCode(device_id: string, isEnabled?: boolean) {
    this.store.dispatch(GenerateNewQrCode({ device_id, isEnabled }));
  }

  regenerateQrCode(device_id: string, isEnabled?: boolean) {
    this.store.dispatch(RegenerateQrCode({ device_id, isEnabled }));
  }

  toggleQrCodeEnabledStatus(qrCodeId: number, isEnabled: boolean) {
    this.store.dispatch(ToggleQrCodeEnabledStatus({ qrCodeId, isEnabled }));
    return this.actions$.pipe(
      ofType(ToggleQrCodeEnabledStatusSuccess, ToggleQrCodeEnabledStatusFailure),
      take(1)
    )
  }

  deleteQrCode(qrCodeId: number) {
    this.store.dispatch(DeleteQrCode({ qrCodeId }));
    return this.actions$.pipe(
      ofType(DeleteQrCodeSuccess, DeleteQrCodeFailure),
      take(1)
    )
  }

  downloadPDF(params: QrCodePDFRequest) {
    this.store.dispatch(GeneratePDF({ params }));
    return this.actions$.pipe(
      ofType(GeneratePDFSuccess, GeneratePDFFailure),
      take(1)
    )
  }

}