import { NgModule } from '@angular/core';
import { DirectivesModule } from '@library/ui/directives/directives.module';
import { UIModule } from '@library/ui/ui.module';
import { QrCodeModuleViewComponent } from './qr-code-module/qr-code-module-view.component';
import { QrCodeLoadingComponent } from './qr-code-loading/qr-code-loading.component';
import { PipesModule } from '@library/utils/pipes/pipes.module';
import { LangModule } from '@library/store/lang/lang.module';
import { QrCodeNotFoundComponent } from './qr-code-not-found/qr-code-not-found.component';
import { QrCodeScrollbarComponent } from './qr-code-scrollbar/qr-code-scrollbar.component';
import { QrCodeMeasureCardComponent } from './qr-code-measure-card/qr-code-measure-card.component';
import { QrCodeTimeCardComponent } from './qr-code-scrollbar/qr-code-time-card/qr-code-time-card.component';
import { BuildingModalModule } from '@organisms/building-modal/building-modal.module';
import { QrCodeUnreachableComponent } from './qr-code-unreachable/qr-code-unreachable.component';
import { RouterModule, Routes } from '@angular/router'
import { QRCodeViewComponent } from './qr-code-view.component'

const routes: Routes = [
  {
    path: '',
    component: QRCodeViewComponent,
    children: [
      {
        path: ':buildingId/:uid',
        component: QrCodeModuleViewComponent
      },
      {
        path: 'not-found',
        component: QrCodeNotFoundComponent
      },
      {
        path: 'unreachable',
        component: QrCodeUnreachableComponent
      },
      {
        path: '',
        redirectTo: 'not-found',
        pathMatch: 'prefix'
      },
    ]
  },


]

@NgModule({
  declarations: [
    QrCodeModuleViewComponent,
    QrCodeLoadingComponent,
    QrCodeNotFoundComponent,
    QrCodeScrollbarComponent,
    QrCodeMeasureCardComponent,
    QrCodeTimeCardComponent,
    QrCodeUnreachableComponent,
    QRCodeViewComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    DirectivesModule,
    UIModule,
    LangModule,
    PipesModule,
    BuildingModalModule,
  ]
})

export class QrCodeViewModule { }
