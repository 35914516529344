import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state'
import { currentBuilding } from '@store/buildings/buildings.selectors'

const userProState = (state: AppState) => state.userPro;

export const getUserEmail = createSelector(
  userProState,
  state => {
    return state.email;
  }
);

export const getUserId = createSelector(
  userProState,
  state => state?.id
);

export const getUser = createSelector(
  userProState,
  state => {
    return state;
  }
);

export const isAdmin = createSelector(
  currentBuilding,
  getUserId,
  (building, userId) => {
    if (building && userId) {
      return typeof building.admins.find(admin => admin.id === userId) !== 'undefined'
    }
    return null;
  }
);

export const getUserAnalytics = createSelector(
  userProState,
  state => {
    return state?.app_telemetry;
  }
);
