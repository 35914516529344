import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { BuildingsFacade } from '@store/buildings/buildings.facade';
import { ModulesProFacade } from '@store/modules-pro/modules-pro.facade'
import { BehaviorSubject, firstValueFrom, interval, mergeMap, Subscription, withLatestFrom } from 'rxjs';
import { UserAgentService } from '@library/utils/services/user-agent.service'
import { isDefined } from '@library/utils/helpers/store/is-defined'

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit {

  subs = new Subscription();

  private displayBannerInformation = new BehaviorSubject<boolean>(false);
  public displayBannerInformation$ = this.displayBannerInformation.asObservable();

  constructor(
    private buildingsFacade: BuildingsFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private moduleProFacade: ModulesProFacade,
    private userAgent: UserAgentService,
  ) { }

  async ngOnInit() {
    // If app is open in mobile browser, always redirect to device-summary installation flow
    if (this.userAgent.isMobile()) {
      this.router.navigate(['/installation/device-summary']);
    }

    // If user has no device in building, redirect to no-device page
    const modules = await firstValueFrom(this.moduleProFacade.allCurrentBuildingModules$.pipe(isDefined()));
    if (modules && modules.length === 0) {
      this.router.navigate(['no-device'], {
        relativeTo: this.activatedRoute.parent,
        skipLocationChange: true}
      )
    }

    this.subs.add(interval(30000).pipe(
      withLatestFrom(this.buildingsFacade.currentBuilding$),
      mergeMap(([, building]) => {
        if (building) {
          return this.buildingsFacade.loadBuilding(building.id)
        }
      }),
    ).subscribe());
  }

  shouldDisplayBannerInformation(display: boolean) {
    this.displayBannerInformation.next(display);
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
