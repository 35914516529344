import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConfigState } from 'library/sources/store/config/config.state'
import { ConfigModule } from '@library/store/config/config.module'
import { NgrxHttpClient } from '@library/utils/services/http-service'
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserProModule } from './user-pro/user-pro.module';
import { BuildingsModule } from './buildings/buildings.module';
import { ModulesProModule } from './modules-pro/modules-pro.module';
import { GroupsModule } from './groups/groups.module';
import { MeasuresModule } from '@library/store/measures/measures.module';
import { UserProState } from './user-pro/user-pro.state';
import { BuildingsState } from './buildings/buildings.interface';
import { GroupsState } from './groups/groups.interface';
import { MeasuresState } from '@library/store/measures/measures.interface';
import { BuildingLangsEffects } from './langs/building-langs.effects'
import { QRCodeModule } from './qr-codes/qr-codes.module';

export interface AppState {
  config: ConfigState;
  user: UserProState,
  buildings: BuildingsState,
  groups: GroupsState,
  measures: MeasuresState,
}

@NgModule({
  imports: [
    StoreModule.forRoot({}, {
      runtimeChecks: {
        // Needed for the graphs, where we have to mutate the state
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    EffectsModule.forFeature([
      BuildingLangsEffects,
    ]),
    EffectsModule.forRoot([]),
    ...(environment.production ? [] : [StoreDevtoolsModule.instrument()]),
    ConfigModule,
    UserProModule,
    BuildingsModule,
    ModulesProModule,
    GroupsModule,
    MeasuresModule,
    QRCodeModule,
  ],
  providers: [
    NgrxHttpClient
  ]
})
export class StateModule {}
