import { Injectable, Optional } from '@angular/core';
import { HomesFacade } from '@library/store/homes/homes.facade';
import { EnumApiScales, MeasureTypeToApiMeasureType, MomentApiScalesDurations } from '@library/store/measures/measures.interface';
import { ModulesFacade } from '@library/store/modules/modules.facade';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ContractsFacade } from '../contracts/contracts.facade';
import { LoadHomeMeasures, LoadHomeMeasuresSuccess } from './measures.actions';
import { MeasuresFacade } from './measures.facade';
import { MeasuresService } from './measures.service';

@Injectable()
export class MeasuresEffects {

  loadHomeMeasures$ = createEffect(() => this.actions$.pipe(
    ofType(LoadHomeMeasures),
    withLatestFrom(this.modulesFacade.currentHomeModules$, this.homesfacade.homes$, this.contractFacade?.isConsumptionContractStatic$ ?? of(false)),
    mergeMap(([{home, timeRange, scale}, homeModules, homes, isConsumptionContractStatic]) => {
      let res: Observable<any>;

      if (homes.find(h => h.id === home.id)?.mode) {
        res = this.measuresService.getMeasures({
          homeId: home.id,
          scale: scale,
          date_begin: Math.floor(timeRange.dateBegin.valueOf() / 1000),
          date_end: Math.floor(timeRange.dateEnd.valueOf() / 1000),
          modules: home.modules.map(m => {
            const module = {...m, type: m.types.map(t => MeasureTypeToApiMeasureType[t]).join(',')};
            delete module.types;
            return module;
          }),
          real_time: true,
        }).pipe(
          map(modules => {
            return {
              home: {
                id: home.id,
                modules: modules.map((measures, i) => {
                  return {
                    id: home.modules[i].id,
                    bridge: home.modules[i].bridge,
                    measures: measures,
                  };
                }),
              }
            };
          })
        );
      }
      else {
        res = this.measuresService.homeMeasures({
          home: {
            ...home,
            modules: (home.modules ?? []).map(m => {
              const module = {...m, type: m.types.map(t => MeasureTypeToApiMeasureType[t]).join(',')};
              delete module.types;
              return module;
            }),
            rooms: (home.rooms ?? []).map(r => {
              const room = {...r, type: r.types.map(t => MeasureTypeToApiMeasureType[t]).join(',')};
              delete room.types;
              return room;
            }),
          },
          real_time: true,
          scale: scale,
          date_begin: Math.floor(timeRange.dateBegin.valueOf() / 1000),
          date_end: Math.floor(timeRange.dateEnd.valueOf() / 1000),
        })
      }

      return res.pipe(
        // Set module consumption type in response
        map(res => {
          res.home.modules = res.home.modules ?? [];
          res.home.modules.forEach(module => {
            module.measureTypes = home.modules.find(m => m.id === module.id).types;
          });
          res.home.rooms = res.home.rooms ?? [];
          res.home.rooms.forEach(module => {
            module.measureTypes = home.rooms.find(m => m.id === module.id).types;
          });
          return res;
        }),
        mergeMap((res) => {
          return [LoadHomeMeasuresSuccess({
            ...res,
            scale,
            timeRange,
            homeModules,
            isConsumptionContractStatic
          })];
        }),
      );
    }),
  ));

  constructor(
    public actions$: Actions,
    public measuresService: MeasuresService,
    public modulesFacade: ModulesFacade,
    public homesfacade: HomesFacade,
    public measuresFacade: MeasuresFacade,
    @Optional() public contractFacade: ContractsFacade,
  ) {}

  /**
   * Requests to the api dont return the value for the dateEnd param
   * This function adds a padding to a date in order to force the api
   * to return the last value we want
   */
  private addPadding(date: moment.Moment, scale: EnumApiScales) {
    switch (scale) {
      case EnumApiScales.FIVE_MINUTES: {
        return date.clone().add(2.5, 'minutes');
      }
      case EnumApiScales.THREE_HOURS: {
        return date.clone().add(1.5, 'hours');
      }
      case EnumApiScales.ONE_DAY:
      case EnumApiScales.ONE_HOUR:
      case EnumApiScales.ONE_WEEK:
      case EnumApiScales.ONE_MONTH: {
        return date.clone().add(0.5, MomentApiScalesDurations.get(scale));
      }
    }
  }
}
