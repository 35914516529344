<ng-container *ngIf="devices$ | async as devices">

  <div class="header">
    <app-text class="text-18 is-family-semiBold" [text]="group.name ?? ('iaq-app.__IAQ_NO_GROUP' | AsyncLang | async)"></app-text>
    <div class="quality-circle" [class]="getMinQuality(devices)"></div>
  </div>

  <div class="content is-flex">

    <div class="max-co2">
      <app-text class="is-family-medium has-spacing-bottom-xs" [text]="'iaq-app.__IAQ_MAX_CO2' | AsyncLang | async"></app-text>
      <app-text class="text-22 is-family-bold" [text]="getMaxC02(devices)"></app-text>
    </div>

    <div class="sensors">
      <app-text class="text-14">{{ group.devices.length }}</app-text>
      <app-text class="text-14" [text]="'iaq-app.__IAQ_SENSORS' | AsyncLang | async"></app-text>
    </div>

  </div>

</ng-container>
