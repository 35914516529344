import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { DefaultLanguage, getCountryCode, getCrowdinCode } from './supportedLangs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { SetHomeUser } from '@library/store/user/user.action';
import { GetBuildingsSuccess } from '@store/buildings/buildings.actions'
import { LangFacade } from '@library/store/lang/lang.facade'

@Injectable()
export class BuildingLangsEffects {

  constructor(
    public actions$: Actions,
    private cookieService: CookieService,
    private langFacade: LangFacade,
  ) {}

  /**
   * Whenever we retrieve the user from the backend w update the 'netatmocomlocale' cookie
   * with the value of the locale found in the user if it's different.
   */
  setLocaleCookie = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBuildingsSuccess),
      tap(() => {
        const localeCookie = this.cookieService.get('netatmocomlocale');
        if (!localeCookie || navigator.language !== localeCookie) {
          this.cookieService.set('netatmocomlocale', navigator.language, 9999, '/', environment.COOKIE_LOCALE_DOMAIN, true, 'None');

          const locale = getCrowdinCode(navigator.language) || DefaultLanguage;

          this.langFacade.loadLangs([
            { name: 'common-app', locale },
            { name: 'common-settings', locale },
            { name: 'station-app', locale },
            { name: 'hc-app', locale },
            { name: 'iaq-app', locale },

          ]);
        }
      }),
    ),
    { dispatch: false },
  );

  /**
   * Sets the moment locale to use when the homesdata responds
   */
  setMomentLocale = createEffect(() =>
    this.actions$.pipe(
      ofType(SetHomeUser),
      tap(({user}) => {
        const countryCode = getCountryCode(user.locale);
        moment.locale(countryCode);
      }),
    ),
    { dispatch: false },
  );
}
