import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import {
  CleanModulesProRemoved, DeleteModule,
  DeleteModuleSuccess, UpdateModule, UpdateModuleFailure, UpdateModuleGroup,
  UpdateModuleGroupFailure, UpdateModuleGroupSuccess, UpdateModuleSuccess
} from './modules-pro.actions';
import { ModulePro, ModuleProDB } from './modules-pro.interface';
import { allCurrentBuildingModules, getModuleFromId, getModuleGroup, getModules } from './modules-pro.selectors';

@Injectable()
export class ModulesProFacade {

  allCurrentBuildingModules$ = this.store.pipe(select(allCurrentBuildingModules));
  modulesFomQRCodePublicPage$ = this.store.pipe(select(getModules));
  getModuleGroup = (id: ModulePro['id']) => this.store.pipe(select(getModuleGroup(id)));
  getModuleFromId$ = (id: ModulePro['device_id']) => this.store.pipe(select(getModuleFromId(id)));

  constructor(
    private store: Store,
    public actions$: Actions,
  ) { }

  cleanStoreFromRemovedDevices() {
    this.store.dispatch(CleanModulesProRemoved());
  }

  updateModule(module: Partial<ModuleProDB>) {
    this.store.dispatch(UpdateModule({module}));
    return this.actions$.pipe(
      ofType(UpdateModuleSuccess, UpdateModuleFailure),
      filter(action => action.module.id === module.id),
      take(1)
    );
  }

  deleteModule(id: number) {
    this.store.dispatch(DeleteModule({id}));
    return this.actions$.pipe(
      ofType(DeleteModuleSuccess),
      filter(action => action.id === id),
      take(1)
    );
  }

  updateModuleGroup(module: Pick<ModuleProDB, 'id' | 'group'>) {
    this.store.dispatch(UpdateModuleGroup({module}));
    return this.actions$.pipe(
      ofType(UpdateModuleGroupSuccess, UpdateModuleGroupFailure),
      filter(action => action.module.id === module.id),
      take(1)
    );
  }
}
