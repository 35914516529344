import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from '@library/utils/services/local-storage.service';

@Injectable({providedIn: 'root'})
export class LastVisitedResolver implements Resolve<any> {

  constructor(private storage: LocalStorageService) {}

  resolve(route: ActivatedRouteSnapshot): boolean {
    this.storage.setItem('last_visited_page', route.data.visited_route);
    return true;
  }
}
