import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BuildingSelectorComponent } from './building-selector/building-selector.component';
import { HoverTextComponent } from './hover-text/hover-text.component';
import { MoleculesModule } from '@molecules/molecules.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AddBuildingComponent } from '@organisms/add-building/add-building.component';
import { UIModule } from '@library/ui/ui.module';
import { SidebarLinkComponent } from './sidebar-link/sidebar-link.component';

@NgModule({
    declarations: [
        SidebarComponent,
        BuildingSelectorComponent,
        HoverTextComponent,
        AddBuildingComponent,
        SidebarLinkComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UIModule,
        PipesModule,
        MoleculesModule,
    ],
    exports: [
        SidebarComponent
    ],
})
export class SidebarModule { }
