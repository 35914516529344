import { Injectable } from '@angular/core';
import { RawModuleMeasures } from '@library/store/measures/measures.interface';
import { of } from 'rxjs';
import { catchError, map, retry, take } from 'rxjs/operators';
import { HttpBuildingService } from 'src/app/services/http-building.service';

export interface HomeMeasuresPayload {
    home: {
        id: string;
        modules: RawModuleMeasures[]
    }
}

@Injectable()
export class NHCMeasuresService {
  constructor(public http: HttpBuildingService) {
  }

  public homeMeasures(params: {[key: string]: any}) {
    params = {
      ...params,
      id: params.home.modules[0].id,
      type: params.home.modules[0].type,
    };
    delete params.home;
    return this.http.get('devices/measures', params).pipe(
      retry(5),
      map((measures) => ({
        home: {
          modules: [{
            id: params.id,
            measures,
          }],
        }
      })),
      take(1),
      catchError(() => of({home: {modules: []}})),
    );
  }
}
