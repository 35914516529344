import { Injectable } from '@angular/core';
import { delay, Observable } from 'rxjs';
import { Building, RawBuilding } from './buildings.interface'
import { UserPro } from '@store/user-pro/user-pro.interface'
import { HttpBuildingService } from 'src/app/services/http-building.service'
import { ModulePro, ModuleProDB } from '@store/modules-pro/modules-pro.interface'

@Injectable()
export class BuildingsService {
  constructor(
    private http: HttpBuildingService) {
  }

  loadBuildings(): Observable<{user: UserPro, buildings: RawBuilding[]} > {
    return this.http.get('buildings');
  }

  loadBuilding(id: number): Observable<{buildings: RawBuilding[]} > {
    return this.http.get(`buildings/${id}`);
  }

  authorize(): Observable<any> {
    return this.http.get('authorize')
  }
  refreshAuthorize(buildingId): Observable<any> {
    return this.http.get(`refresh/${buildingId}`)
  }

  createBuilding(params: {code: string, name: string, tz: string}) {
    return this.http.post('buildings', params)
  }

  getBuildingDevices(buildingId: number):Observable<{buildingId: number, devices: Array<ModulePro>}> {
    return this.http.get(`devices/list/${buildingId}`);
  }

  createBuildingDevice(params:{ buildingId: number, device: ModuleProDB}) {
    return this.http.post('devices', params);
  }

  updateBuildingToken(params: {buildingId: number, code: string}) {
    return this.http.post('building-tokens', params);
  }

  updateBuilding(building: Partial<Building>) {
    return this.http.put(`buildings/${building.id}`, {data: building});
  }

  deleteUser(building: Partial<Building>, user: Partial<UserPro>) {
    return this.http.delete(`buildings/${building.id}/users/${user.id}`);
  }

  deleteBuilding(buildingId: number) {
    return this.http.delete(`buildings/${buildingId}`);
  }

  resetThresholds(buildingId: number) {
    return this.http.post(`reset-thresholds/${buildingId}`);
  }

  getUnplacedDevicesData(buildingId: Building['id']) {
    return this.http.get(`devices/get-unplaced-data/${buildingId}/`)
  }
}
