import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { Store, select, Selector, MemoizedSelector } from '@ngrx/store';
import { map, withLatestFrom, filter, first, switchMap } from 'rxjs/operators';
import { UserAgentService } from './user-agent.service';
import { getUserAnalytics } from '@library/store/user/user.selector';
import { SettingsState } from '../interfaces/settings-state.interface';

// Not all projects (Lux) use User store. Need another method to allow different selection of user analytics preferences
export const ANALYTICS_USER_SELECTOR = new InjectionToken<Selector<any, boolean>>(
    'ANALYTICS_USER_SELECTOR',
    { providedIn: 'root', factory: () => getUserAnalytics }
);

@Injectable()
export class Analytics {
    // This will takes event throw by each component which has Analytics in it's constructor
    public analytics = new Subject<any>();

    // This is an observable which will retrieve the current User. It will contain the user and the event
    // if and only if the user has the app_telemetry at true
    analytics$ = this.analytics.pipe(withLatestFrom(this.store.select(this.appTelemetrySelector)),
                                filter(([event, allowsAnalytics]) => allowsAnalytics),
                                map(([event]) => event));

    // This is an observable which will retrieve the current config.
    config$ = this.store.pipe(select(('config')));

    isNative = this.userAgent.isNativeApp() ? '_Native' : '';

    constructor(
        private store: Store<SettingsState>,
        public userAgent: UserAgentService,
        @Inject(ANALYTICS_USER_SELECTOR) private appTelemetrySelector: MemoizedSelector<SettingsState, boolean>
    ) {}

    public initUserConsent() {
        // It will create the analytics with the config retrieve by config$
        // if and only if the user has app_telemetry at true.
        this.store.select(this.appTelemetrySelector).pipe(
            filter((allowsAnalytics) => allowsAnalytics),
            first(),
        ).subscribe(() => {
            // Update the config with the current user consent
            window.gtag('consent', 'update', {
                analytics_storage: 'granted',
                ad_storage: 'denied',
            })

            // Send event consent-granted to trigger Configuration tag
            window.dataLayer.push({ event: 'consent-granted' })
        });
    }
}
