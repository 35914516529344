import { Injectable } from '@angular/core';
import { endOfApiScale, startOfApiScale } from '@library/store/measures/measures';
import { MeasuresLoaderService } from '@library/store/measures/measures-loader.service';
import { MeasuresFacade } from '@library/store/measures/measures.facade';
import { EnumApiScales, EnumMeasureTypes, EnumScales, MeasuresSet, TimeRange } from '@library/store/measures/measures.interface';
import { Module } from '@library/store/modules/modules.interface';
import { ModulePro } from '@store/modules-pro/modules-pro.interface';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ScalesService } from 'src/app/services/scales.service';

export type reportCategoriesType = 'maxCO2' |'avgCO2' |'minTemperature' |'maxTemperature' |'avgTemperature' |'maxNoise' |'avgNoise' |'minHumidity' |'maxHumidity' |'avgHumidity';


@Injectable()
export class ReportDataService {

  timeRange: TimeRange = {
    dateBegin: startOfApiScale(moment(), this.scalesService.scaleToApiScale(EnumScales.WEEK)),
    dateEnd:endOfApiScale( moment(), this.scalesService.scaleToApiScale(EnumScales.WEEK)),
  };

  scale = EnumScales.WEEK;

  constructor(
    private measuresLoaderService: MeasuresLoaderService,
    private measuresFacade: MeasuresFacade,
    public scalesService: ScalesService,
  ) { }

  isLaunchingData = false // allow to block scale and timerange modification if we have data loading.

  minMeasure(measures: MeasuresSet, timeRange: TimeRange, scale: EnumApiScales) {
    if (measures) {
      const timestampMeasures = measures[scale]?.flat().filter(
        measure => measure.x > timeRange.dateBegin.valueOf() && measure.x < timeRange.dateEnd.valueOf())
      if (timestampMeasures && timestampMeasures.length > 0) {
        return Math.min(...timestampMeasures.map(measure => measure.y))
      }
    }

    return null;
  }

  maxMeasure(measures: MeasuresSet, timeRange: TimeRange, scale: EnumApiScales) {
    if (measures) {
      const timestampMeasures = measures[scale]?.flat().filter(
        measure => measure.x > timeRange.dateBegin.valueOf() && measure.x < timeRange.dateEnd.valueOf())
      if (timestampMeasures && timestampMeasures.length > 0) {
        return Math.max(...timestampMeasures.map(measure => measure.y))
      }
    }

    return null;
  }

  avgMeasure(measures: MeasuresSet, timeRange: TimeRange, scale: EnumApiScales) {
    if (measures) {
      const timestampMeasures = measures[scale]?.flat().filter(
        measure => measure.x > timeRange.dateBegin.valueOf() && measure.x < timeRange.dateEnd.valueOf())
      if (timestampMeasures) {
        const allMeasures = timestampMeasures.map(measure => measure.y)

        const sum = allMeasures.reduce((a,c) => a + c, 0);
        if (allMeasures.length > 0 ) {
          // fix one digit
          return (Math.round(sum / allMeasures.length * 10) / 10)
        }
      }
      return null;
    }
  }

  async loadMeasures(devices: ModulePro[], scale: EnumApiScales, timeRange: TimeRange, types: EnumMeasureTypes[]) {
    this.isLaunchingData = true; // allow to block scale and timerange  modification if we have data loading.

    let promises = []
    for (let device of devices) {
      promises.push(this.measuresLoaderService.loadModulesMeasures(
        [{id: device.device_id} as Module],
        {
          [device.device_id]: types
        },
        scale,
        timeRange,
      ));
    }
    return Promise.all(promises).then(async () => {
      const measures = await firstValueFrom(this.measuresFacade.getModulesMeasures(devices.map(d => ({id: d.device_id}))));

      this.isLaunchingData = false;

      // add min max and avg to each value
      return measures.map(measure => ({
        ...measure,
        maxCO2: this.maxMeasure(measure[EnumMeasureTypes.MAX_CO2], timeRange, scale),
        avgCO2: this.avgMeasure(measure[EnumMeasureTypes.CO2], timeRange, scale),
        minTemperature: this.minMeasure(measure[EnumMeasureTypes.MIN_TEMPERATURE], timeRange, scale),
        maxTemperature: this.maxMeasure(measure[EnumMeasureTypes.MAX_TEMPERATURE], timeRange, scale),
        avgTemperature: this.avgMeasure(measure[EnumMeasureTypes.TEMPERATURE], timeRange, scale),
        maxNoise: this.maxMeasure(measure[EnumMeasureTypes.MAX_NOISE], timeRange, scale),
        avgNoise: this.avgMeasure(measure[EnumMeasureTypes.NOISE], timeRange, scale),
        minHumidity: this.minMeasure(measure[EnumMeasureTypes.MIN_HUMIDITY], timeRange, scale),
        maxHumidity: this.maxMeasure(measure[EnumMeasureTypes.MAX_HUMIDITY], timeRange, scale),
        avgHumidity: this.avgMeasure(measure[EnumMeasureTypes.HUMIDITY], timeRange, scale),
      }))
    });
  }
}
