<section>
  <app-text class="text-align-center is-family-bold is-size-4">{{'iaq-app.__IAQ_NO_DEVICE' | AsyncLang | async}}</app-text>

  <img src="assets/images/no-device.svg">

  <app-text class="text-align-center is-family-medium">{{'iaq-app.__IAQ_NO_DEVICE_SENTENCE' | AsyncLang | async}}</app-text>

  <lay-button class="is-flex-button has-spacing-top-m">
    <button class="is-primary-button" (click)="goToSettings()">
      <app-text>{{'common-app.__CONTINUE_BTN' | AsyncLang | async}}</app-text>
    </button>
  </lay-button>
</section>
