import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, Subject, switchMap, take, takeUntil } from 'rxjs';
import { LangFacade } from '@library/store/lang/lang.facade';
import { Module } from '@library/store/modules/modules.interface';
import { EnumMeasureTypes } from '@library/store/measures/measures.interface';
import { MeasuresFacade } from '@library/store/measures/measures.facade';
import { QRCodesFacade } from '@store/qr-codes/qr-codes.facade';
import { QrCodePublicDataForDisplay, queryParams } from '@store/qr-codes/qr-codes.interface';
import { convertThresholdsToHealthIndex, formatHours, formatLiveDataForDisplay, formatMeasuresOfStoreForDisplay, formatModuleDataInStoreForDisplay, replaceEmptyValuesAndAddName } from '@store/qr-codes/qr-codes.utils';
import { ModulesProFacade } from '@store/modules-pro/modules-pro.facade';
import { QRCodesService } from '@store/qr-codes/qr-codes.service';
import { DashboardService } from '@pages/dashboard-view/dashboard.service';
import { Thresholds } from '@store/buildings/buildings.interface';

export enum ErrorTypes {
  NOT_FOUND = 'iaq-app.__IAQ_SHARE_DISABLED',
  UNREACHABLE = 'iaq-app.__IAQ_SENSOR_UNREACHABLE',
  NO_DATA = 'iaq-app.__IAQ_NO_DATA'
}

@Component({
  selector: 'app-qr-code-module-view',
  templateUrl: './qr-code-module-view.component.html',
  styleUrls: ['./qr-code-module-view.component.scss']
})
export class QrCodeModuleViewComponent implements OnInit {
  errorTypes = ErrorTypes
  EnumMeasureTypes = EnumMeasureTypes;
  regexUid = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/s
  queryParams = queryParams;

  qrCodeUid$ = new BehaviorSubject<string | null>(null);
  hasError$ = new BehaviorSubject<boolean>(null);
  thresholds$ = new BehaviorSubject<Thresholds>(null);
  private _destroySubscriptions$ = new Subject<void>();


  modulesWithLiveData$ = this.modulesProFacade.modulesFomQRCodePublicPage$
  qrCodeMeasuresForDisplay$ = combineLatest([this.modulesWithLiveData$, this.thresholds$]).pipe(
    filter(modules => modules.length > 0),
    map(([modules, thresholds]) => ({ module: modules[0], thresholds })),
    switchMap(({ module, thresholds }) => {
      return this.measuresFacade.getModulesMeasures([{ id: module.device_id } as Module])
        .pipe(
          map(measures => ({ measures, module })),
          filter(data => data.measures.length > 0),
          map(data => formatMeasuresOfStoreForDisplay(data.measures[0], data.module, this.queryParams, thresholds))
        )
    }),
  )

  dataForDisplay$ = new BehaviorSubject<QrCodePublicDataForDisplay>(null);

  constructor(
    public qrCodesFacade: QRCodesFacade,
    public langFacade: LangFacade,
    public measuresFacade: MeasuresFacade,
    public modulesProFacade: ModulesProFacade,
    private activatedRoute: ActivatedRoute,
    public qrCodesService: QRCodesService,
    public dashboardService: DashboardService,
    public router: Router
  ) { }

  async ngOnInit() {
    const { buildingId, uid } = this.activatedRoute.snapshot.params
    // This page being public, the user can modify the url.
    // This regex test ensures the url has a proper uuid to avoid sending a malformed request bound to fail.
    if (this.regexUid.test(uid)) {
      this.qrCodeUid$.next(uid);
      // Fetch data and save it to store

      const liveData = await firstValueFrom(this.qrCodesFacade.GetQRCodeLiveData(buildingId, uid));
      if (!liveData || !liveData.data) {
        this.router.navigate(['not-found'], {
          relativeTo: this.activatedRoute.parent,
          skipLocationChange: true
        });

      }
      else if (!liveData.data.dashboard_data) {
        this.router.navigate(['unreachable'], {
          relativeTo: this.activatedRoute.parent,
          skipLocationChange: true
        });
      }
      else if (liveData && liveData.data && liveData.data.dashboard_data) {
        // saving thresholds separately to convert them into health index when clicking on a measure card
        this.thresholds$.next(liveData.data.thresholds);
        this.dataForDisplay$.next(formatLiveDataForDisplay(liveData.data));
      }
      const historicalData = await firstValueFrom(this.qrCodesFacade.GetQRCodeHistoricalData(buildingId, uid, this.queryParams));
      // Select last card
      if (historicalData && historicalData.data && historicalData.data.value) {
        this.qrCodesService.selectTime(historicalData.data.value.length - 1 ?? 0, formatHours(liveData.data.dashboard_data.time_utc, liveData.data.timezone), liveData.data.dashboard_data.time_utc)
      }
    }

    this.qrCodesService.currentSelectedCard.pipe(
      takeUntil(this._destroySubscriptions$)
    ).subscribe(({ idx, hourAndUnit }) => this.changeDisplayedData(idx, hourAndUnit))
  };

  ngOnDestroy(): void {
    this._destroySubscriptions$.next();
  }

  changeDisplayedData(idx: number, hourAndUnit: string[]) {
    combineLatest([this.modulesWithLiveData$, this.qrCodeMeasuresForDisplay$]).pipe(
      take(1),
      map(([modulesWithLiveData, measuresForDisplay]) => ({ modulesWithLiveData, measuresForDisplay })),
    )
      .subscribe(({ modulesWithLiveData, measuresForDisplay }) => {
        if (modulesWithLiveData && measuresForDisplay) {
          replaceEmptyValuesAndAddName(modulesWithLiveData[0], measuresForDisplay[idx])
          // Display live data if last hour is selected.
          idx === (measuresForDisplay.length - 1)
            ? this.dataForDisplay$.next(formatModuleDataInStoreForDisplay(modulesWithLiveData[0], hourAndUnit))
            : this.dataForDisplay$.next(
              {
                ...measuresForDisplay[idx],
                health_idx: convertThresholdsToHealthIndex(EnumMeasureTypes.CO2, measuresForDisplay[idx].co2, this.thresholds$.value),
              });
        }
      })
  }
}

