import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { BuildingsFacade } from '@store/buildings/buildings.facade'
import { BehaviorSubject, map, startWith } from 'rxjs'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {

  /**
   * Has modules in selected building
   */
  hasModulesInBuilding$ = this.buildingsFacade.currentBuilding$.pipe(
    map(building => building?.devices?.length > 0),
    startWith(false),
  )

  /**
   * Logout url
   */
  logoutUrl = environment.logoutUrl + '?message=__DISCONNECTED&next_url=' + environment.appUrl;

  constructor(
    public buildingsFacade: BuildingsFacade,
  ) {}

}
