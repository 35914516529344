import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment'
import { BuildingsFacade } from '@store/buildings/buildings.facade'
import { filter, skip, Subscription } from 'rxjs'

@Component({
  selector: 'app-no-device-in-building-view',
  templateUrl: './no-device-in-building-view.component.html',
  styleUrls: ['./no-device-in-building-view.component.scss']
})
export class NoDeviceInBuildingViewComponent implements OnInit {

  logoutUrl = environment.logoutUrl + '?message=__DISCONNECTED&next_url=' + environment.appUrl;

  sub = new Subscription();

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private buildingsFacade: BuildingsFacade,
  ) { }

  ngOnInit(): void {
    this.sub.add(this.buildingsFacade.currentBuildingId$.pipe(
      filter(buildingId => buildingId !== null),
      skip(1)
    ).subscribe(() => {
        this.router.navigate(['./'], {relativeTo: this.activatedRoute.parent})
    }))
  }

  goToSettings() {
    this.router.navigate(['settings', 'devices'])
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
