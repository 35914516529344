<ng-container *ngIf="(qrCodeUid$ | async); else notFound">
  <ng-container *ngIf="(dataForDisplay$ | async) as qrCodeData ; else loadingOrError">
    <div class="main-container">

      <div class="title-and-measures">
        <div class="background-gradient" [class]="dashboardService.getQuality(qrCodeData.health_idx)"></div>
        <div class="health-index-container">
          <div class="module-name-container">
            <app-text class="text-18" id="module-name" [text]="qrCodeData.name"></app-text>
          </div>
          <app-text class="health-index" [class]="dashboardService.getQuality(qrCodeData.health_idx)"
            [text]="qrCodeData.health_idx !== null ? (this.dashboardService.getQualityKey(qrCodeData.health_idx)| AsyncLang | async) : (errorTypes.NO_DATA | AsyncLang | async) ">
          </app-text>
        </div>

        <div class="measures">
          <div *ngFor="let measureType of queryParams.type" class="measure-card-list">
            <app-qr-code-measure-card [qrCodeData]="qrCodeData" [measureType]="measureType"
              [thresholds]="thresholds$ |async"></app-qr-code-measure-card>
          </div>
        </div>
      </div>

      <app-qr-code-scrollbar class="scrollbar-container" *ngIf="(qrCodeMeasuresForDisplay$ | async)"
        [qrCodeMeasuresForDisplay]="qrCodeMeasuresForDisplay$ | async">
      </app-qr-code-scrollbar>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingOrError>
  <app-qr-code-loading [hasError]="hasError$ | async"></app-qr-code-loading>
</ng-template>

<ng-template #notFound>
  <app-qr-code-not-found></app-qr-code-not-found>
</ng-template>
