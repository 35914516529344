<div class="unreachable-container is-flex">
  <div class="title-icon">
    <app-icon name="error-circle" class="has-text-danger is-size-3 is-large has-spacing-bottom-xxs">
  </app-icon>
  <app-text class="text-align-center is-size-2 not-found-title">{{errorText | AsyncLang | async}}</app-text>
</div>

  <div class="is-fullwidth">
    <div *ngFor="let measureType of emptyDataTypes" class="measure-card-list">
      <app-qr-code-measure-card [qrCodeData]="emptyData" [measureType]="measureType"></app-qr-code-measure-card>
    </div>
  </div>
</div>
