import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service'

import { Observable } from 'rxjs';
import { HttpBuildingService } from 'src/app/services/http-building.service'
import { UserPro } from './user-pro.interface';

@Injectable()
export class UserProService {
  constructor(
    private http: HttpBuildingService,
    protected cookie: CookieService
  ) {}

  // TODO : check where is called and check backend to add app_telemetry
  getUser(): Observable<UserPro> {
    return this.http.get('getuser')
  }

  invite(buildingId: string): Observable<{url: string}> {
    return this.http.post(`invite/${buildingId}`)
  }

  acceptInvite(token) {
    return this.http.get(`users/invitation`, {token})
  }

}
