import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@library/utils/services/local-storage.service';
import { RefreshAuthorizeSuccess } from '@store/buildings/buildings.actions'
import { BuildingsFacade } from '@store/buildings/buildings.facade'
import { UserProFacade } from '@store/user-pro/user-pro.facade'
import { BehaviorSubject, firstValueFrom } from 'rxjs'

@Component({
  selector: 'app-refresh-authorize-view',
  templateUrl: './refresh-authorize-view.component.html',
  styleUrls: ['./refresh-authorize-view.component.scss']
})
export class RefreshAuthorizeViewComponent implements OnInit {

  displayError$ = new BehaviorSubject<boolean>(false);

  constructor(
    private buildingsFacade: BuildingsFacade,
    private localStorage: LocalStorageService,
    private userPro: UserProFacade,
  ) { }

  async ngOnInit() {
    // if we are admin refresh to authorize, otherwize display an error message.
    const userId = await firstValueFrom(this.userPro.userId$);
    const adminId = await firstValueFrom(this.buildingsFacade.currentBuildingAdminId$)

    if (userId === adminId) {
      const building = await firstValueFrom(this.buildingsFacade.currentBuilding$);
      const action = await firstValueFrom(this.buildingsFacade.refreshAuthorize(building.id));
      if (action.type === RefreshAuthorizeSuccess.type) {
        this.localStorage.setItem('oauth2_state', action.state);
        window.location.href = action.url;
      }
       else {
        this.displayError$.next(true);
      }
    }
  }

}
